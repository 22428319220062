import React from 'react';
import { Container } from 'react-bootstrap';
import { PartnerDetailsBreadcrumb } from './components/partner-details-breadcrumb';
import { PartnerDetailsHeader } from './components/partner-details-header/partner-details-header';
import { PermissionContainer } from '../../permissions/permission-container';
import { Permission } from '../../../shared/models/permission';
import { SuspenseContainer } from '../../suspense-container/suspense-container';
import { PartnerDetailsCard } from './components/partner-details-card/partner-details-card';
import styles from './partner-details.module.scss';
import { PartnerLearnersTable } from './components/partner-learners-table/partner-learners-table';

export const PartnerDetails = () => (
  <Container>
    <PermissionContainer permissions={[Permission.PartnersViewer]}>
      <SuspenseContainer
        loadingText="Loading Partner..."
        errorText="Failed to load partner information"
      >
        <PartnerDetailsBreadcrumb />

        <div className={styles.container}>
          <PartnerDetailsHeader />
          <PartnerDetailsCard />
          <PartnerLearnersTable />
        </div>
      </SuspenseContainer>
    </PermissionContainer>
  </Container>
);
