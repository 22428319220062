const validateEmail = (
  fieldValue: string,
) => {
  // eslint-disable-next-line
    const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!fieldValue) {
    return 'Please, provide value';
  }
  if (!emailRegExp.test(String(fieldValue).toLowerCase())) {
    return 'Please, provide valid email';
  }
  return null;
};


const handleValidation = (
  fieldValue: string,
  fieldType: string,
) => {
  switch (fieldType) {
    case 'email':
      return validateEmail(fieldValue);
    default:
      return null;
  }
};
export default handleValidation;
