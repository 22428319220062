import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const SeminarHeader = styled(Container)`
    margin: 24px 10%;
`;

export const SeminarActionsRow = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 10% 24px;
  
    > div {
      display: flex;
      align-items: center;
    }
  
    button {
      margin-left: 12px;
    }
  
    span {
      margin: 0 12px 0 0;
    }
  
    #year-select { 
      width: 250px;
    } 
`;

export const SeminarTableWrapper = styled(Container)`
    margin: 0 10%;
  
    th, td {
      text-align: center;
    }
`;
