import React, { useEffect, useState } from 'react';
import {
  Field,
  FieldProps as FormFieldProps,
  FieldRenderProps,
  useField,
} from 'react-final-form';
import cn from 'classnames';
import { FaAsterisk } from 'react-icons/fa';
import styles from './form-field.module.scss';

type BaseFieldProps<T> = FormFieldProps<
T,
FieldRenderProps<T, HTMLElement>,
HTMLElement
>;

export type FieldProps<T> = {
  label?: string;
  helperText?: string;
  className?: string;
  required?: boolean;
  reverse?: boolean;
} & BaseFieldProps<T>;

export const FormField = <T extends any = string>({
  name,
  label,
  helperText,
  className,
  required,
  reverse,
  ...rest
}: FieldProps<T>) => {
  const { meta } = useField(name);

  const hasError = meta.error && meta.invalid && (meta.submitFailed || meta.submitSucceeded);

  const conditionalProps: Omit<BaseFieldProps<T>, 'name'> = rest.render
    ? {}
    : {
      component: 'input',
      type: 'text',
    };

  return (
    <div className={cn(styles.field, reverse && styles.reverse, className)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {required ? (
            <FaAsterisk className={styles.asterisk} size={5} />
          ) : (
            <span />
          )}
          <span>{label}</span>
        </label>
      )}
      <div className={styles.content}>
        <Field name={name} {...conditionalProps} {...rest} />
        {helperText && <span className={styles.description}>{helperText}</span>}

        <span className={cn(styles.description, { [styles.error]: hasError })}>
          {hasError ? meta.error : ''}
        </span>
      </div>
    </div>
  );
};
