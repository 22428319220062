import { Table } from 'react-bootstrap';
import styled from 'styled-components';

export const StripedTable = styled(Table)`
  margin-top: 20px;
  border: 1px solid rgb(222, 226, 230);

  th {
    background-color: #d3d3d3;
  }

  tr:nth-of-type(even) {
    background-color: #d3d3d3;
  }

  td.emptyRow {
    text-align: center;
  }
`;

export const NamedContainer = styled.div`
  border: 2px solid rgb(222, 226, 230);
  border-radius: 8px;
  margin-top: 15px;

  .block-name {
    position: relative;
    top: -14px;
    left: 14px;
    background-color: white;
    padding: 0 5px;
  }

  .child {
    margin: -10px 20px 10px 20px;
  }
`;
