import {
  Navbar,
  Container,
} from 'react-bootstrap';
import styled, {
  createGlobalStyle,
} from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    @import url('https://fonts.googleapis.com/css?family=Open+Sans');
    font-family: "Open Sans";
  }
`;

export const AdminNavBar = styled(Navbar)`
  background-color: yellow;
  padding: 0 1rem;
  @media (min-width: 700px) {
    padding: 0 60px;
  }
`;

export const AdminNavBarBrand = styled(Navbar.Brand)`
  padding: 0;
  @media (max-width: 625px) {
    margin-left: 30px;
  }
`;

export const InstructionsDiv = styled.div`
  margin: 20px 0;
`;

export const AdminStyledContainer = styled(Container)`
  font-family: "Open Sans";
  text-align: left;
  padding: 0;
`;

export const MainContentContainer = styled.div`
  font-family: "Open Sans";
  text-align: left;
`;
