import React from 'react';
import IError from '../../../models/Error';
import { WithLoading } from '../../helper-components/loading.component';
import { WithErrorHandling } from '../../helper-components/error-handling.component';
import { LearnerRegistrationApplicantBlock } from '../styled.components';
import { usePartner } from '../../manage-partners/hooks/use-partner';

export interface PartnerDetailsProps {
  partnerId?: string | null;
}

const PartnerDetailsComponent = ({ partnerId }: PartnerDetailsProps) => {
  const {
    data: partnerDetails,
    error,
    isLoading,
  } = usePartner(partnerId || '', {
    enabled: !!partnerId,
  });
  return (
    <LearnerRegistrationApplicantBlock>
      <h3>Partner Details</h3>
      <WithLoading
        loading={!!partnerId && isLoading}
        loadingText="Loading partner details..."
      >
        <WithErrorHandling small error={error as IError}>
          {!partnerId && <p>No partner associated with the applicant</p>}
          {partnerId && (
            <p>
              <a href={`/partners/${partnerId}`}>
                {partnerDetails?.accountName}
              </a>
            </p>
          )}
        </WithErrorHandling>
      </WithLoading>
    </LearnerRegistrationApplicantBlock>
  );
};

export default PartnerDetailsComponent;
