import React from 'react';
import { connect } from 'react-redux';
import { LearnerName, LearnerId } from '../styled-components';
import { IRootState } from '../../../shared/reducers';
import { getLearnerName } from '../../../shared/selectors/learner-name.selector';
import AuthContext from '../../../app/authProvider';

interface LearnerNameIdProps extends StateProps {
  learnerName?: string;
}

function LearnerNameId(props: LearnerNameIdProps) {
  const { learnerName } = props;

  return (
    <AuthContext.Consumer>
      {(context) => context && (
        <>
          <LearnerName><a href={`/learners/${context.learnerId}/transcript`} target="blank">{learnerName}</a></LearnerName>
          <LearnerId>{context.learnerId}</LearnerId>
        </>
      )}
    </AuthContext.Consumer>
  );
}

const mapStateToProps = (learnerProfileState: IRootState): StateProps => ({
  learnerName: getLearnerName(learnerProfileState),
});

interface StateProps {
  learnerName?: string;
}

export default connect(mapStateToProps)(LearnerNameId);
