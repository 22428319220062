/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Button,
  Modal,
  Col,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  Form, FormRenderProps, FormSpy, Field,
} from 'react-final-form';
import moment from 'moment';
import { AnyObject, FormState, FormApi } from 'final-form';
import { IRootState } from '../../../../shared/reducers';
import {
  setStartDate,
  setDeferredStatusAction,
} from '../../../../shared/actions/start-date.action';
import {
  IStartDateState,
  IDispacthProps,
  ICommonStartDateProps,
  ISelectedStartDate,
  IFormValues,
} from './start-date-modal.model';
import { START_DATE, COMMENT } from './start-date-modal.constants';
import {
  MainRow, PaddingRow,
} from './styled-components';
import {
  renderDataPicker,
  renderTextField,
} from '../../../helper-components/form-components/form-filed-components/form-filed.components';
import { composeValidators, minLength, commentRequiredStartDate } from '../../../../utils/validate.utils';
import 'react-datepicker/dist/react-datepicker.css';
import calculateStartDateRange from './start-date-calculator';
import SaveChangesDialog from '../../../helper-components/form-components/save-changes-dialog';
import { SAVE, CLOSE } from '../../../constants/common-constants';

class StartDateEditingModal extends React.Component<
ICommonStartDateProps,
IStartDateState> {
  constructor(props: ICommonStartDateProps) {
    super(props);
    this.state = {
      endDay: null,
      startDay: null,
      isChangedForm: false,
      saveChangesDialogVisible: false,
      nextPossibleStartDate: '',
      deferred: false,
    };
  }

  public componentDidMount= () : void => {
    this.updateEditorData();
  };

  public componentDidUpdate(prevProps: ICommonStartDateProps): void {
    const { enrolledDateTime, daysBeforeNextPeriod, maxDeferralMonths } = this.props;
    if (enrolledDateTime !== prevProps.enrolledDateTime
      || daysBeforeNextPeriod !== prevProps.daysBeforeNextPeriod
      || maxDeferralMonths !== prevProps.maxDeferralMonths
    ) {
      this.updateEditorData();
    }
  }

  private handleDetectChanges = (isChanged: boolean): void => {
    this.setState({ isChangedForm: isChanged });
  };

  private handleSave = (values: AnyObject, form: FormApi<IFormValues>) => {
    const { deferred } = this.state;
    const { startDate, comment } = values;
    const {
      getBearerToken, learnerId, setSelectedStartDate, setDeferredStatus,
      programEnrollmentId,
    } = this.props;
    const optionObject = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      comment,
    };
    setSelectedStartDate(getBearerToken, learnerId, programEnrollmentId, optionObject);
    setDeferredStatus(deferred);
    this.closeAllModals(form, false);
  };


  private openSaveChangesDialog = (form: FormApi<any>) => {
    const { isChangedForm } = this.state;
    if (isChangedForm) {
      this.handleSaveChangesModal(true);
    } else {
      this.closeFrom(form, true);
    }
  };

  private closeFrom = (form: FormApi<any>, formResetNeeded: boolean) => {
    const { handleClose } = this.props;
    handleClose();
    if (formResetNeeded) {
      form.reset();
    }
  };

  private closeAllModals = (form: FormApi<IFormValues>, formResetNeeded: boolean) => {
    this.closeFrom(form, formResetNeeded);
    this.handleSaveChangesModal(false);
  };

  private handleSaveChangesModal(saveChangesDialogVisible: boolean) {
    this.setState({ saveChangesDialogVisible });
  }

  private updateEditorData() {
    const { enrolledDateTime, daysBeforeNextPeriod, maxDeferralMonths } = this.props;
    const range = calculateStartDateRange(
      enrolledDateTime,
      daysBeforeNextPeriod,
      maxDeferralMonths,
    );
    this.setState({
      endDay: range.latestAllowed,
      startDay: range.nextAvailable,
      nextPossibleStartDate: range.earliestAllowed,
    });
  }

  private checkIfDeferred(
    updateDefferedStatusForInput: boolean,
    selectedStartDate?: string | null,
  ) {
    const { nextPossibleStartDate } = this.state;
    const { startDate, setDeferredStatus } = this.props;
    const currentStartDate = selectedStartDate || startDate;
    if (currentStartDate) {
      const currentStartDateUTC = moment(currentStartDate).format('YYYY-MM-DD');
      const deferred = !moment(moment(nextPossibleStartDate).format('YYYY-MM-DD')).isSame(currentStartDateUTC);
      this.setState({ deferred });
      if (updateDefferedStatusForInput) {
        setDeferredStatus(deferred);
      }
    }
  }

  private isSelectOptionsChanged(values: AnyObject): boolean {
    const { startDate, comment } = this.props;
    const currentInfo = {
      [START_DATE]: values.startDate,
      [COMMENT]: values.comment,
    };
    const initialInfo = {
      [START_DATE]: startDate,
      [COMMENT]: comment,
    };
    this.checkIfDeferred(false, values.startDate);
    return JSON.stringify(currentInfo) !== JSON.stringify(initialInfo);
  }

  public render(): JSX.Element {
    const {
      deferred,
      saveChangesDialogVisible,
      startDay,
      endDay,
    } = this.state;

    const {
      show,
      handleClose,
      startDate,
      comment,
      disableStartDateAfterXDays,
      daysBeforeNextPeriod,
    } = this.props;

    const initialValues = {
      [START_DATE]: startDate,
      [COMMENT]: comment,
    };
    const minLength20Validation = minLength(20);
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" animation={false}>
        <Modal.Header>
          <Modal.Title>Editing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={this.handleSave}
            initialValues={initialValues}
            render={<T extends object>({
              handleSubmit,
              form,
              pristine,
              submitting,
              valid,
            }: FormRenderProps<IFormValues> & FormState<T>): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <Col>
                  <MainRow>
                    <Col>Start Date</Col>
                    <Col>
                      <Field
                        render={renderDataPicker}
                        showMonthYearPicker
                        maxDate={endDay}
                        minDate={startDay}
                        dateFormat="dd MMM yyyy"
                        deferred={deferred}
                        name={START_DATE}
                      />
                    </Col>
                  </MainRow>
                </Col>
                <Col>
                  <PaddingRow>
                    <Col>Reason of the change</Col>
                    <Col>
                      <Field
                        name={COMMENT}
                        validate={composeValidators(
                          commentRequiredStartDate(
                            daysBeforeNextPeriod,
                            disableStartDateAfterXDays,
                            startDate,
                          ),
                          minLength20Validation,
                        )}
                        initialValue={comment}
                        displayError
                        maxLength={250}
                        render={renderTextField}
                      />
                    </Col>
                  </PaddingRow>
                </Col>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                    disabled={pristine || submitting}
                  >
                    {SAVE}
                  </Button>
                  <Button variant="secondary" onClick={() => this.openSaveChangesDialog(form)}>
                    {CLOSE}
                  </Button>
                </Modal.Footer>
                <SaveChangesDialog
                  formValid={valid}
                  show={saveChangesDialogVisible}
                  text="Your changes have not been saved"
                  closeModals={this.closeAllModals}
                  form={form}
                  formResetNeeded
                  handleSubmit={handleSubmit}
                />
                <FormSpy
                  subscription={{ values: true, dirty: true, pristine: true }}
                  onChange={<K extends object>({ values }: FormState<K>): void => {
                    this.handleDetectChanges(this.isSelectOptionsChanged(values));
                  }}
                />
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    );
  }
}
const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispacthProps, AnyAction>,
): IDispacthProps => (
  {
    setSelectedStartDate: (token: () => Promise<string>, learnerId: string,
      programEnerollmentId: string,
      optionObject: ISelectedStartDate): void => {
      dispatch(setStartDate(token, learnerId, programEnerollmentId, optionObject));
    },
    setDeferredStatus: (deferred: boolean): void => {
      dispatch(setDeferredStatusAction(deferred));
    },
  });
export default connect(
  null,
  mapDispatchToProps,
)(StartDateEditingModal);
