/* eslint-disable max-len */
import React, { useState } from 'react';
import moment from 'moment';
import {
  Card,
  Accordion,
  Col,
  Dropdown,
  Modal,
  Row,
  Button,
  Spinner,
} from 'react-bootstrap';
import { GiBurningSkull } from 'react-icons/gi';
import { Form, Field } from 'react-final-form';
import api from '../../../../shared/api/adminUI.api';
import { WithPermissions } from '../../../helper-components/view-permission.component';
import errorHandling from '../../../helper-components/alert-component.component';
import {
  renderTextField,
  Error,
  capitalize,
  removeWhiteSpaces,
  renderCheckbox,
} from '../../../helper-components/form-components/form-filed-components/form-filed.components';
import { CenteredRow, GPACalcIcon, TransferredIcon } from '../../styled-components';
import CourseCohortGrades from '../../../course-cohort-grades';
import LearnerCourseStatus from '../learner-course-status';
import GradePercentage from '../grade-percentage';
import { DropRequestRow, DropRequestStatusRow, StyledAccordion } from '../styled-components';

import {
  commentRequired,
  minLength,
  composeValidators,
} from '../../../../utils/validate.utils';

import {
  CohortProps,
  IActiveCourseStatusEditOption,
  IStatusObject,
  ICourseStatusEdit,
  ICourseStatusEditDialog,
  IDeleteDialog,
  IDeleteOption,
  IDropRequest,
  IDropRequestDecision,
} from './cohort-grade.model';
import { DropRequestInfo, ICourseStatusEditOptions, IStatusOption } from '../../learner-grades.model';
import {
  COMMENT, YES, CANCEL, WARNING, CHECKBOX,
} from '../../../constants/common-constants';
import UniversityDate from '../../../university-date';
import { getCurrentUniversityDate } from '../../../../utils/date.utils';
import { enrollment } from '../../../../config.json';

const ENROLLED = 'enrolled';
const PASSED = 'passed';
const PREVIEWING = 'previewing';
const WITHDRAWN = 'withdrawn';
const DISMISSED = 'dismissed';
const FAILED = 'failed';

const DROP_REQUESTED = 'dropRequested';

const ACCEPTED = 'accepted';
const REJECTED = 'rejected';
const REQUESTED = 'requested';

const INTERNAL_TRANSFER = 'internal-transfer';
const EXTERNAL_TRANSFER = 'external-transfer';

const defaultActiveOption = {
  statusId: '',
  subStatusId: '',
  sectionCode: '',
  statusObject: {
    status: '',
    subStatus: '',
    courseCode: '',
    startDate: '',
    learnerId: '',
    dropRequestInfo: {
      status: '',
      requestedBy: '',
      updatedDate: '',
      approvedBy: '',
    },
  },
};

const defaultDeleteOption: IDeleteOption = {
  courseCode: '',
  startDate: '',
};

const displayCourseStatus = (status: string, subStatus: string | null): string => (
  subStatus
    ? `${capitalize(status)} (${capitalize(subStatus)})`
    : capitalize(status)
);

const createCourseStatusEditSaveData = (statusId: string, subStatus: string | null,
  comment: string, dropRequested: boolean) => ({
  status: statusId,
  subStatus,
  comment,
  dropRequested,
});

const onCourseStatusEditSubmit = async (
  learnerId: string,
  programEnrollmentId: string,
  getBearerToken: () => Promise<string>,
  comment: string,
  dropRequested: boolean,
  activeOption: IActiveCourseStatusEditOption,
  setCourseStatusEditModalWindowVisible: (visible: boolean) => void,
  deleteCourse: (courseCode: string, startDate: string) => void,
  deactivateCourse: (courseCode: string, sectionCode: string, status: string,
    subStatus: string | null, dropRequestInfo: DropRequestInfo) => void,
  setPending: (pending: boolean) => void,
) => {
  const { statusObject, statusId, subStatusId } = activeOption;
  const { courseCode, startDate } = statusObject;
  const courseCodeWithoutWhitespaces = removeWhiteSpaces(courseCode);
  const subStatus = subStatusId || null;
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');

  try {
    setPending(true);
    const courseStatusEditDataObject = createCourseStatusEditSaveData(statusId, subStatus, comment, dropRequested);

    const unenrollLearner = await api.academicPerformance.unenrollLearnerFromCourse(
      learnerId,
      programEnrollmentId,
      getBearerToken,
      courseCodeWithoutWhitespaces,
      formattedStartDate,
      courseStatusEditDataObject,
    );
    if (!unenrollLearner.ok) throw await unenrollLearner;
    setPending(false);

    const dropRequestInfo = (await unenrollLearner.json()) as DropRequestInfo;
    if (dropRequestInfo) {
      if (dropRequestInfo.status === ACCEPTED) {
        deleteCourse(statusObject.courseCode, formattedStartDate);
      }
      if (dropRequestInfo.status === REJECTED) {
        deactivateCourse(statusObject.courseCode, formattedStartDate,
          statusObject.status, statusObject.subStatus, dropRequestInfo);
      }
    }
    deactivateCourse(courseCode, formattedStartDate, statusId, subStatus, dropRequestInfo);
    setCourseStatusEditModalWindowVisible(false);
  } catch (error) {
    errorHandling({ error });
    setPending(false);
    setCourseStatusEditModalWindowVisible(false);
  }
};

const onDeleteSubmit = async (
  learnerId: string,
  programEnrollmentId: string,
  getBearerToken: () => Promise<string>,
  comment: string,
  deleteOption: IDeleteOption,
  setDeleteModalWindowVisible: (visible: boolean) => void,
  deleteCourse: (courseCode: string, startDate: string) => void,
  setPending: (pending: boolean) => void,
) => {
  const courseCodeWithoutWhitespaces = removeWhiteSpaces(deleteOption.courseCode);
  const formattedStartDate = moment(deleteOption.startDate).format('YYYY-MM-DD');

  try {
    setPending(true);

    const deleteLearner = await api.academicPerformance.deleteLearnerCourse(
      learnerId,
      programEnrollmentId,
      getBearerToken,
      courseCodeWithoutWhitespaces,
      formattedStartDate,
      comment,
    );
    if (!deleteLearner.ok) throw await deleteLearner;
    setPending(false);
    deleteCourse(deleteOption.courseCode, formattedStartDate);
    setDeleteModalWindowVisible(false);
  } catch (error) {
    errorHandling({ error });
    setPending(false);
    setDeleteModalWindowVisible(false);
  }
};

const onDropRequestDecisionSubmit = async (
  learnerId: string,
  programEnrollmentId: string,
  getBearerToken: () => Promise<string>,
  statusObject: IStatusObject,
  status: string,
  deleteCourse: (courseCode: string, startDate: string) => void,
  deactivateCourse: (courseCode: string, sectionCode: string, status: string,
    subStatus: string | null, dropRequestInfo: DropRequestInfo) => void,
) => {
  try {
    const formattedStartDate = moment(statusObject.startDate).format('YYYY-MM-DD');

    const updateDropRequestStatus = await api.academicPerformance.updateDropRequestStatus(
      learnerId,
      programEnrollmentId,
      getBearerToken,
      statusObject.courseCode,
      statusObject.startDate,
      status,
    );
    if (!updateDropRequestStatus.ok) throw await updateDropRequestStatus;
    const dropRequestInfo = (await updateDropRequestStatus.json()) as DropRequestInfo;

    if (dropRequestInfo) {
      if (dropRequestInfo.status === ACCEPTED) {
        deleteCourse(statusObject.courseCode, formattedStartDate);
      }
      if (dropRequestInfo.status === REJECTED) {
        deactivateCourse(statusObject.courseCode, formattedStartDate,
          statusObject.status, statusObject.subStatus, dropRequestInfo);
      }
    }
  } catch (error) {
    errorHandling({ error });
  }
};


const DeleteDialog: React.FunctionComponent<IDeleteDialog> = (
  props: IDeleteDialog,
) => {
  const [pending, setPending] = useState(false);
  const minLength20Validation = minLength(20);
  const commentRequiredDelete = commentRequired(
    'Please provide reason for delete',
  );
  const {
    isDeleteModalWindowVisible,
    setDeleteModalWindowVisible,
    selectedDeleteOption,
    learnerId,
    programEnrollmentId,
    deleteCourse,
    getBearerToken,
  } = props;

  return (
    <Modal show={isDeleteModalWindowVisible}>
      <Modal.Header>
        <h5>{WARNING}</h5>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete learner course?</p>
        <Form
          onSubmit={() => { }}
          initialValues={{ [COMMENT]: '' }}
          render={({ invalid, values }) => (
            <form>
              <Row>
                <Col xs={4}>
                  <span>{COMMENT}</span>
                </Col>
                <Col xs={8}>
                  <Field
                    name={COMMENT}
                    validate={composeValidators(
                      minLength20Validation,
                      commentRequiredDelete,
                    )}
                    render={renderTextField}
                    maxLength={500}
                  />
                  <p>
                    <Error name={COMMENT} />
                  </p>
                </Col>
              </Row>
              <Modal.Footer>
                <Button
                  disabled={invalid}
                  variant="danger"
                  onClick={() => onDeleteSubmit(
                    learnerId,
                    programEnrollmentId,
                    getBearerToken,
                    values[COMMENT],
                    selectedDeleteOption,
                    setDeleteModalWindowVisible,
                    deleteCourse,
                    setPending,
                  )}
                >
                  <span>
                    {`${YES}`}
                  </span>
                  {pending && <Spinner size="sm" animation="border" />}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setDeleteModalWindowVisible(false)}
                >
                  {CANCEL}
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};
const CourseStatusEditDialog: React.FunctionComponent<ICourseStatusEditDialog> = (
  props: ICourseStatusEditDialog,
) => {
  const [pending, setPending] = useState(false);
  const minLength20Validation = minLength(20);
  const commentRequiredUnenroll = commentRequired(
    'Please provide reason for unenrollment',
  );
  const { startDate } = props;

  const dropRequestRequired = getCurrentUniversityDate().isBefore(moment(startDate)
    .add(Number(enrollment.dropRequiredBeforeXDays) + 1, 'days').add(-1, 'seconds'));
  const {
    isCourseStatusEditModalWindowVisible,
    setCourseStatusEditModalWindowVisible,
    selectedCourseStatusEditOption,
    learnerId,
    programEnrollmentId,
    deleteCourse,
    deactivateCourse,
    getBearerToken,
  } = props;
  const { statusId, subStatusId } = selectedCourseStatusEditOption;

  const UNENROLL_WARNING = `This learner will be ${capitalize(statusId)} ${subStatusId ? `(${capitalize(subStatusId)})` : ''
  } from the course.`;

  return (
    <Modal show={isCourseStatusEditModalWindowVisible}>
      <Modal.Header>
        <h5>{WARNING}</h5>
      </Modal.Header>
      <Modal.Body>
        <p>{UNENROLL_WARNING}</p>
        <Form
          onSubmit={() => { }}
          initialValues={{ [COMMENT]: '', [DROP_REQUESTED]: dropRequestRequired }}
          render={({ invalid, values }) => (
            <form>
              <Row>
                <Col xs={4}>
                  <span>{COMMENT}:</span>
                </Col>
                <Col xs={8}>
                  <Field
                    name={COMMENT}
                    validate={composeValidators(
                      minLength20Validation,
                      commentRequiredUnenroll,
                    )}
                    render={renderTextField}
                    maxLength={250}
                  />
                  <p>
                    <Error name={COMMENT} />
                  </p>
                </Col>
              </Row>
              <DropRequestRow>
                <Field
                  disabled={dropRequestRequired}
                  type={CHECKBOX}
                  name={DROP_REQUESTED}
                  label="Initiate a drop request"
                  render={renderCheckbox}
                />
              </DropRequestRow>
              <Modal.Footer>
                <Button
                  disabled={invalid || pending}
                  variant="primary"
                  onClick={() => onCourseStatusEditSubmit(
                    learnerId,
                    programEnrollmentId,
                    getBearerToken,
                    values[COMMENT],
                    values[DROP_REQUESTED],
                    selectedCourseStatusEditOption,
                    setCourseStatusEditModalWindowVisible,
                    deleteCourse,
                    deactivateCourse,
                    setPending,
                  )}
                >
                  <span>
                    {`${capitalize(statusId)}`}
                  </span>
                  {pending && <Spinner size="sm" animation="border" />}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setCourseStatusEditModalWindowVisible(false)}
                >
                  {CANCEL}
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Modal.Body>
    </Modal>
  );
};


const filterCourseEditOptions = (courseStatusEditOptions: ICourseStatusEditOptions[],
  excludedOptions: { status: string, subStatus: string }[]): ICourseStatusEditOptions[] => {
  let courseStatusEditOptionsCopy = courseStatusEditOptions.map((cseo) => ({ ...cseo }));

  excludedOptions.forEach((excludedOption) => {
    const targetEditOption = courseStatusEditOptionsCopy.find((cseo) => cseo.status.name.toLowerCase() === excludedOption.status.toLowerCase());

    if (!targetEditOption) return;

    if (!excludedOption.subStatus) courseStatusEditOptionsCopy = courseStatusEditOptionsCopy.filter((cseo) => cseo !== targetEditOption);
    else {
      targetEditOption.subStatuses = targetEditOption.subStatuses.filter((ss) => ss.name.toLowerCase() !== excludedOption.subStatus.toLowerCase());
    }
  });

  return courseStatusEditOptionsCopy;
};


const createCourseStatusEditOptions = (
  courseStatusEditOptions: ICourseStatusEditOptions[],
  statusObject: IStatusObject,
  setCourseStatusEditModalWindowVisible: (visible: boolean) => void,
  setSelectedCourseStatusEditOption: (activeOption: IActiveCourseStatusEditOption) => void,
  setSelectedCourseStartDate: (startDate: string) => void,
) => {
  const onClick = (e: any, activeOption: IActiveCourseStatusEditOption) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedCourseStartDate(statusObject.startDate);
    setCourseStatusEditModalWindowVisible(true);
    setSelectedCourseStatusEditOption(activeOption);
  };

  const disableOption = (option: ICourseStatusEditOptions, subStatus: IStatusOption | null): boolean => statusObject.status!.toUpperCase() === option.status.name.toUpperCase()
    && (!subStatus || !statusObject.subStatus || subStatus.name!.toUpperCase() === statusObject.subStatus!.toUpperCase());

  return courseStatusEditOptions.map((option) => {
    if (option.subStatuses.length > 0) {
      const hasSingleSubstatus = option.subStatuses.length === 1;
      return option.subStatuses.map((subStatusOption) => (
        <Dropdown.Item
          disabled={disableOption(option, subStatusOption)}
          key={subStatusOption.id}
          onClick={(e: any) => onClick(e, {
            statusId: option.status.id,
            subStatusId: subStatusOption.id,
            statusObject,
          })}
        >
          {`${option.status.name} ${hasSingleSubstatus ? '' : `(${subStatusOption.name})`}`}
        </Dropdown.Item>
      ));
    }
    return (
      <Dropdown.Item
        disabled={disableOption(option, null)}
        key={option.status.id}
        onClick={(e: any) => onClick(e, { statusId: option.status.id, statusObject })}
      >
        {option.status.name}
      </Dropdown.Item>
    );
  });
};

const createDropRequestDecisionOptions = (
  learnerId: string,
  programEnrollmentId: string,
  getBearerToken: () => Promise<string>,
  statusObject: IStatusObject,
  deleteCourse: (courseCode: string, startDate: string) => void,
  deactivateCourse: (courseCode: string, sectionCode: string, status: string,
    subStatus: string | null, dropRequestInfo: DropRequestInfo) => void,
) => {
  const onClick = (e: any, activeOption: IDropRequestDecision) => {
    e.preventDefault();
    e.stopPropagation();
    onDropRequestDecisionSubmit(learnerId, programEnrollmentId, getBearerToken, statusObject,
      activeOption.statusId, deleteCourse, deactivateCourse);
  };

  return (
    <>
      <Dropdown.Item
        onClick={(e: any) => onClick(e, { statusId: ACCEPTED, statusObject })}
      >
        Accept Drop
      </Dropdown.Item>
      <Dropdown.Item
        onClick={(e: any) => onClick(e, { statusId: REJECTED, statusObject })}
      >
        Reject Drop
      </Dropdown.Item>
    </>
  );
};

const CustomToggle = React.forwardRef(
  ({ children, onClick }: any,
    ref: (
      (instance: HTMLAnchorElement | null) => void) |
    React.RefObject<HTMLAnchorElement> | null) => (
      <a
        href="/"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
          // needed to stop collapsible panel collapse on link click
          e.stopPropagation();
        }}
      >
        {children}
      &#x25bc;
      </a>
  ),
);


const CourseStatusEdit: React.FC<ICourseStatusEdit> = (
  props: ICourseStatusEdit,
): JSX.Element => {
  const {
    statusObject,
    courseStatusEditOptions,
    canUpdateAcademicPerformance,
    canEditCompletedCourse,
    setCourseStatusEditModalWindowVisible,
    setSelectedCourseStatusEditOption,
    setSelectedCourseStartDate,
  } = props;

  if ([PREVIEWING, ENROLLED].includes(statusObject.status) && canUpdateAcademicPerformance) {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          Unenroll
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {createCourseStatusEditOptions(
            courseStatusEditOptions,
            statusObject,
            setCourseStatusEditModalWindowVisible,
            setSelectedCourseStatusEditOption,
            setSelectedCourseStartDate,
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  if (([WITHDRAWN, DISMISSED, FAILED].includes(statusObject.status)) && canEditCompletedCourse) {
    const filteredCourseStatusEditOptions = filterCourseEditOptions(courseStatusEditOptions,
      [{ status: 'withdrawn', subStatus: 'administrative' }]);

    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          Change
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {createCourseStatusEditOptions(
            filteredCourseStatusEditOptions,
            statusObject,
            setCourseStatusEditModalWindowVisible,
            setSelectedCourseStatusEditOption,
            setSelectedCourseStartDate,
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
  return <></>;
};

const DropRequestOptions: React.FC<IDropRequest> = (
  props: IDropRequest,
): JSX.Element => {
  const {
    learnerId,
    programEnrollmentId,
    getBearerToken,
    statusObject,
    deleteCourse,
    deactivateCourse,
  } = props;
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
        Pending
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {createDropRequestDecisionOptions(
          learnerId,
          programEnrollmentId,
          getBearerToken,
          statusObject,
          deleteCourse,
          deactivateCourse,
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default function CohortGradesAccordion(props: CohortProps): JSX.Element {
  const [isCourseStatusEditModalWindowVisible, setCourseStatusEditModalWindowVisible] = useState(false);
  const [isDeleteModalWindowVisible, setDeleteModalWindowVisible] = useState(false);
  const [selectedCourseStartDate, setSelectedCourseStartDate] = useState('');
  const [
    selectedCourseStatusEditOption,
    setCourseStatusEditOption,
  ] = useState<IActiveCourseStatusEditOption>(defaultActiveOption);

  const [selectedDeleteOption, setDeleteOption] = useState<IDeleteOption>(defaultDeleteOption);

  const {
    performance,
    coursesData,
    courseStatusEditOptions,
    getBearerToken,
    learnerId,
    programEnrollmentId,
    deactivateCourse,
    deleteCourse,
    gradesViewPermission,
    canUpdateAcademicPerformance,
    canEditCompletedCourse,
    canDeleteLearnerCourses,
    canApproveDropRequest,
  } = props;
  let increment = -1;

  return (
    <StyledAccordion defaultActiveKey="0">
      {performance!.courses.map((cohort) => {
        const cohortSingle = coursesData.find((item) => (item.CourseCode === cohort.courseCode) && (item.StartDate === cohort.startDate));
        const isSubStatusNull = cohort.subStatus == null;
        // @ts-ignore
        const showSubStatus = isSubStatusNull || (!isSubStatusNull && !['passed'].includes(cohort.subStatus.toString()));
        const isTransferredCourse = cohort.creditType === INTERNAL_TRANSFER || cohort.creditType === EXTERNAL_TRANSFER;
        const isExternalTransfered = cohort.creditType === EXTERNAL_TRANSFER;
        increment += 1;
        return (
          <Card key={increment}>
            <Accordion.Toggle
              as={Card.Header}
              variant="link"
              eventKey={increment.toString()}
            >
              <CenteredRow>
                <Col xs={4}>
                  <h4>{`${cohort.courseCode} ${cohort.courseName}`}</h4>
                  <h6>{cohort.startDate && moment(cohort.startDate).format('MMM YYYY')}</h6>
                </Col>
                <Col xs={2}>
                  <Row>
                    <LearnerCourseStatus
                      status={displayCourseStatus(cohort.status, cohort.subStatus)}
                    />
                    {cohort.usedInGPA && <GPACalcIcon />}
                    {isTransferredCourse && <TransferredIcon />}
                  </Row>
                  {cohort.dropRequestInfo
                    ? (
                      <Row>
                        {cohort.dropRequestInfo.status === REQUESTED
                          && canApproveDropRequest && (
                            <DropRequestOptions
                              learnerId={learnerId}
                              programEnrollmentId={programEnrollmentId}
                              getBearerToken={getBearerToken}
                              key={cohort.startDate}
                              statusObject={{
                                subStatus: cohort.subStatus,
                                status: cohort.status,
                                courseCode: cohort.courseCode,
                                startDate: cohort.startDate,
                                dropRequestInfo: cohort.dropRequestInfo,
                              }}
                              deactivateCourse={deactivateCourse}
                              deleteCourse={deleteCourse}
                            />
                        )}
                        <DropRequestStatusRow>
                          Drop {cohort.dropRequestInfo.status}&nbsp;
                          by {cohort.dropRequestInfo.status === REQUESTED
                          ? cohort.dropRequestInfo.requestedBy
                          : cohort.dropRequestInfo.approvedBy}&nbsp;
                          <UniversityDate value={cohort.dropRequestInfo.updatedDate} />
                        </DropRequestStatusRow>
                      </Row>
                    )
                    : (
                      <Row>
                        <CourseStatusEdit
                          key={cohort.startDate}
                          setCourseStatusEditModalWindowVisible={setCourseStatusEditModalWindowVisible}
                          statusObject={{
                            subStatus: cohort.subStatus,
                            status: cohort.status,
                            courseCode: cohort.courseCode,
                            startDate: cohort.startDate,
                            dropRequestInfo: cohort.dropRequestInfo,
                          }}
                          courseStatusEditOptions={courseStatusEditOptions}
                          canUpdateAcademicPerformance={canUpdateAcademicPerformance}
                          canEditCompletedCourse={canEditCompletedCourse}
                          setSelectedCourseStatusEditOption={setCourseStatusEditOption}
                          setSelectedCourseStartDate={setSelectedCourseStartDate}
                        />
                      </Row>
                    )}
                </Col>
                <Col xs={2}>
                  {(cohort.status !== WITHDRAWN && !isExternalTransfered) && (
                    <h5>
                      <>Grade </>
                      <GradePercentage
                        percentage={cohortSingle ? cohortSingle.CourseGradePercentage : null}
                      />
                    </h5>
                  )}
                </Col>
                <Col xs={1}>
                  <h5>{cohort.status === WITHDRAWN ? 'W' : cohort.grade}</h5>
                </Col>
                <Col xs={2}>
                  <CenteredRow>
                    <Col xs={10}>
                      {!isExternalTransfered && (
                        <h5>{`Attempt: ${cohort.attempt}`}</h5>
                      )}
                    </Col>
                    <Col xs={2}>
                      {![PREVIEWING, ENROLLED, PASSED].includes(cohort.status)
                        && showSubStatus
                        && canDeleteLearnerCourses
                        && !isExternalTransfered
                        && (
                          <GiBurningSkull
                            size={32}
                            style={{ cursor: 'pointer', marginBottom: 8, color: 'red' }}
                            onClick={(event: React.SyntheticEvent) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setDeleteModalWindowVisible(true);
                              const deleteOption: IDeleteOption = {
                                courseCode: cohort.courseCode,
                                startDate: cohort.startDate,
                              };
                              setDeleteOption(deleteOption);
                            }}
                          />
                        )}
                    </Col>
                  </CenteredRow>
                </Col>
              </CenteredRow>
            </Accordion.Toggle>
            {!isExternalTransfered
            && (
            <Accordion.Collapse eventKey={increment.toString()}>
              <Card.Body className="cohort-collapse">
                <WithPermissions
                  permission={gradesViewPermission}
                  noAccesText="You do not have permission to access Learner Performance Data"
                >
                  <CourseCohortGrades
                    // @ts-ignore
                    courseCohortGrade={cohortSingle}
                  />
                </WithPermissions>
              </Card.Body>
            </Accordion.Collapse>
            )}
          </Card>
        );
      })}
      <DeleteDialog
        deleteCourse={deleteCourse}
        isDeleteModalWindowVisible={isDeleteModalWindowVisible}
        setDeleteModalWindowVisible={setDeleteModalWindowVisible}
        selectedDeleteOption={selectedDeleteOption}
        getBearerToken={getBearerToken}
        learnerId={learnerId}
        programEnrollmentId={programEnrollmentId}
      />
      <CourseStatusEditDialog
        isCourseStatusEditModalWindowVisible={isCourseStatusEditModalWindowVisible}
        setCourseStatusEditModalWindowVisible={setCourseStatusEditModalWindowVisible}
        selectedCourseStatusEditOption={selectedCourseStatusEditOption}
        getBearerToken={getBearerToken}
        learnerId={learnerId}
        programEnrollmentId={programEnrollmentId}
        startDate={selectedCourseStartDate}
        deleteCourse={deleteCourse}
        deactivateCourse={deactivateCourse}
      />
    </StyledAccordion>
  );
}
