import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, Form, // Spinner,
} from 'react-bootstrap';
import { FiSave, FiEdit } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';

import {
  EditingCol,
  EditingButtonsRow,
  FieldRow,
  EditButton,
} from './styled-component';
import TextField from './text-edit-component';
import {
  INewFieldValue,
} from '../admissions.model';

interface IProps {
  oldValue: any;
  canEdit: boolean;
  propertyName: string;
  changedFieldUrl: string;
  handleNewValue: (newValue: INewFieldValue, changedFieldUrl: string) => Promise<void>;
  fieldType: string;
}

const returnFieldBasedOnType = (
  filedType: string,
  fieldValue: string,
  propertyName: string,
  validationError: string | null,
  setFormError: (error: string | null) => void,
  setNewFieldValue: (newValue: string) => void,
): JSX.Element => {
  switch (filedType) {
    case 'email':
    case 'input':
    case 'number':
    default:
      return (
        <TextField
          fieldValue={fieldValue}
          fieldType={filedType}
          setFormError={setFormError}
          setNewFieldValue={setNewFieldValue}
          validationError={validationError}
        />
      );
  }
};

const FieldEditor: React.FunctionComponent<IProps> = ({
  oldValue,
  canEdit,
  propertyName,
  changedFieldUrl,
  fieldType,
  handleNewValue,
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setNewFieldValue] = useState(oldValue);
  const [validationError, setFormError] = useState<null | string>(null);
  // const [pending, setPending] = useState(false);
  useEffect(() => { setNewFieldValue(oldValue); }, [oldValue]);

  const edit = () => {
    setEditing(true);
    setNewFieldValue(oldValue);
  };

  const cancel = () => {
    setEditing(false);
    setNewFieldValue(oldValue);
    setFormError(null);
  };

  const save = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const value = fieldValue === '' ? null : fieldValue;
    const payload = { CouponCode: value };
    await handleNewValue(payload, changedFieldUrl);
    setEditing(false);
    // setPending(true);
  };

  if (editing) {
    return (
      <>
        <Form onSubmit={save}>
          <Row>
            <EditingCol xs={9}>
              {
               returnFieldBasedOnType(
                 fieldType,
                 fieldValue,
                 propertyName,
                 validationError,
                 setFormError,
                 setNewFieldValue,
               )
              }
            </EditingCol>
            <Col xs={3}>
              <EditingButtonsRow>
                <Button variant="outline-secondary" size="sm" disabled={oldValue === fieldValue} type="submit">
                  <FiSave />
                </Button>
                <Button variant="outline-secondary" size="sm" onClick={cancel}>
                  <GiCancel />
                </Button>
              </EditingButtonsRow>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col id={propertyName} xs={9}>

          {/* { pending
            ? <Spinner animation="border" size="sm" />
            : (<span> {oldValue === null ? 'N/A' : oldValue}</span>)} */}
          <span> {oldValue === null ? 'N/A' : oldValue} </span>
        </Col>
        <Col xs={3}>
          <FieldRow>
            <EditButton variant="outline-secondary" size="sm" disabled={!canEdit} onClick={edit}>
              <FiEdit />
            </EditButton>
          </FieldRow>
        </Col>
      </Row>
    </>
  );
};

export default FieldEditor;
