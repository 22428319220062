import Learner, { LearnerFlags } from '../../models/Learner';
import ProgramEnrollment from '../../models/ProgramEnrollment';
import IError from '../../models/Error';
import {
  LearnerAcademicStanding,
} from '../../shared/reducers/academic-standing/academic-standing.model';
import { ILearnerAEPDocumentsState } from './aep-status/aep-status.model';
import { IConfiguration } from '../../shared/reducers/configuration/configuration.model';
import { IReadmissionPermissionData } from '../../shared/reducers/readmission/readmission.model';

export interface ISAOption {
  Email: string;
  FirstName: string;
  LastName: string;
}

export interface INewFieldValue {
  [name: string]: string | boolean;
}

/* eslint-disable import/prefer-default-export */
export enum IPaymentStatusOptions {
  PAID = 'paid',
  PARTIAL = 'partial',
  UNPAID = 'unpaid',
}

export type IPaymentStatus = typeof IPaymentStatusOptions.PAID | typeof IPaymentStatusOptions.PARTIAL | typeof IPaymentStatusOptions.UNPAID;

export interface LearnerInfoProps {
  learnerInfo: Learner;
  learnerFlags: LearnerFlags;
  learnerFlagsPending: boolean;
  learnerProgramEnrollments: ProgramEnrollment[];
  error: IError | null;
  learnerInfoPermissionsObject: IlearnerInfoEditPermissionObject;
  academicStanding: LearnerAcademicStanding;
  learnerInfoPending: boolean;
  learnerInfoPermissionsObjectPending: boolean;
  configuration: IConfiguration;
  learnerPaymentStatus: IPaymentStatus;
  learnerPaymentStatusPending: boolean;
  learnerPaymentStatusError: IError | null;
  pending: boolean;
  aepDocumentsData: ILearnerAEPDocumentsState | null;
  programStatus: string;
  activeProgramEnrollmentId: string;
  readmissionPermissionsData: IReadmissionPermissionData;
}

export interface IDispatchProps {
  getConfiguration: (token:() => Promise<string>) => void;
  getAcademicStanding: (token:() => Promise<string>, learnerId: string) => void;
  getLearnerInfoData: (token:() => Promise<string>, learnerId: string) => void;
  getLearnerFlagsData: (token:() => Promise<string>, learnerId: string) => void;
  updateLearnerFlags: (
    learnerId: string,
    token:() => Promise<string>,
    newValue: { Value: boolean },
  ) => void;
  getLearnerProgramEnrollmentsList: (token:() => Promise<string>, learnerId: string) => void;
  getLearnerPaymentStatus: (token:() => Promise<string>, learnerId: string) => void;
  setNewLearnerProfileFieldValue: (
    learnerId: string,
    token:() => Promise<string>,
    newValue: INewFieldValue | ISAOption,
    changedFieldName: string,
  ) => void;
  setActiveProgramEnrollment: (programEnrollmentId: string) => void;
  getLoaSelectOptionsList: (token:() => Promise<string>) => void;
  getProgramStatusDropDownOptionsList: (token:() => Promise<string>) => void;
  getReadmissionPermissionsData: (token:() => Promise<string>) => void;
}

export interface IPersonalInfoComponent {
  verified: boolean;
  verifiedPending: boolean;
  learnerInfo: Learner;
  learnerFlags: LearnerFlags;
  learnerFlagsPending: boolean;
  learnerProgramEnrollments: ProgramEnrollment[];
  error: IError | null;
  learnerPaymentStatus: IPaymentStatus;
  permissionPending: boolean;
  learnerPaymentStatusPending: boolean;
  learnerInfoPermissionsObject: IlearnerInfoEditPermissionObject;
  learnerPaymentStatusError: IError | null;
  handleFieldValueChange: (
    newValue: INewFieldValue | ISAOption,
    changedFieldName: string,
  ) => Promise<void>;
  academicStanding: LearnerAcademicStanding;
  aepDocumentsData: ILearnerAEPDocumentsState | null;
  programStatus: string;
  officialName: string;
  learnerId: string;
  getBearerToken: () => Promise<string>;
}

export interface IlearnerInfoEditPermissionObject {
  canEditProgramStatus: boolean;
  canViewEnrollmentData: boolean;
  canUpdateAcademicStanding: boolean;
  canEditStartDate: boolean;
  canEditLearnerInfo: boolean;
  canEditAdmissionParameters: boolean;
  canEditSuccessAdvisor: boolean;
  canEditAepStatus: boolean;
  canViewAepStatus: boolean;
}

interface ProvidersListObject {
  createdAt: string;
  providerName: string;
}

interface TransferableProductsObject {
  approved: boolean;
  productCode: string;
  productName: string;
  productType: string;
}

interface ExternalCourseObject {
  externalCourseCode: string;
  externalCourseName: string;
  provider: string;
}

export interface ExternalTransferObject {
  catalogVersion: string;
  courseCode: string;
  courseName: string;
  editor: string;
  externalCourses: ExternalCourseObject[];
  productCode: string;
  productType: string;
  status: string;
  timestamp: string
}

export interface TransferableCoursesObject {
  isTransferable: boolean;
  courseCode: string;
  courseName: string;
  productCode: string;
  productType: string;
  externalCourses: ExternalCourseObject[];
}

export interface TransferableCoursesFormValues {
  courseTransfers: TransferableCoursesObject[]
}

export interface IlearnerStateObject {
  activeIndex: null | number;
  identityVerified: boolean;
  identityVerifiedPending: boolean;
  approvedCredits: number;
  pendingCredits: number;
  externalTransfers: ExternalTransferObject[];
  externalTransfersPending: boolean;
  isInitialLoad: boolean;
  canViewExternalTransfer: boolean;
  canEditExternalTransfer: boolean;
  canApproveExternalTransfer: boolean;
  officialName: string;
}

export interface ITransferCreditsState {
  transferableProductsPending: boolean;
  transferableProducts: TransferableProductsObject[];
  transferableCoursesPending: boolean;
  transferableCourses: TransferableCoursesObject[];
  pendingCourses: TransferableCoursesObject[];
  formValues: TransferableCoursesFormValues;
  activeProductCode: string;
  activeProductType: string;
  savePending: boolean;
  approvePending: boolean;
  productListDefault: string;
}

export interface ITransferCreditsObject {
  getBearerToken: () => Promise<string>;
  learnerId: string;
  externalTransfers: ExternalTransferObject[];
  onSubmit: ()=> void;
  canEditExternalTransfer: boolean;
  canApproveExternalTransfer: boolean;
  learnerProgramEnrollments: ProgramEnrollment[];
}

export interface IApprovedTransferObject {
  externalTransfers: ExternalTransferObject[];
}

export interface IProductSelectObject {
  transferableProductsPending: boolean;
  transferableProductsOptions: [];
  handleSelectTransferableCourse: (option: any) => void;
  productListDefault: string;
}

export interface ICourseSelectObject {
  fields: any;
  transferableCourses: TransferableCoursesObject[];
  transferableCoursesPending: boolean;
  handleAddTransferredCourse: (fieds: any, course: any) => void;
}

export interface ISingleCourseObject {
  pendingCourses: TransferableCoursesObject[];
}

export interface ICourseSelectState {
  coursesSearchValue: string;
  isCollapsed: boolean;
}

export interface ITransferCreditPermissionObject {
  canViewExternalTransfer: boolean;
  canEditExternalTransfer: boolean;
  canApproveExternalTransfer: boolean;
}
export type ICommonLearnerInfo = IDispatchProps & LearnerInfoProps;
