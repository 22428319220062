import React from 'react';
import {
  CourseStatusNormal,
  CourseStatusFailed,
  FailedIcon,
  PassedIcon,
  CourseStatusSuccess,
} from '../styled-components';

interface StatusProps {
  readonly status: string;
}

export default function LearnerCourseStatus(props: StatusProps) {
  switch (props.status) {
    case 'InProgress':
      return (
        <CourseStatusNormal> In Progress</CourseStatusNormal>
      );
    case 'Previewing':
      return (
        <CourseStatusNormal> Previewing </CourseStatusNormal>
      );
    case 'Failed':
      return (
        <>
          <CourseStatusFailed>Failed</CourseStatusFailed>
          <FailedIcon />
        </>
      );
    case 'Passed':
      return (
        <>
          <CourseStatusSuccess>Passed</CourseStatusSuccess>
          <PassedIcon />
        </>
      );
    default:
      return (
        <CourseStatusNormal>{props.status}</CourseStatusNormal>
      );
  }
}
