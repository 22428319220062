/* eslint-disable max-len */
import * as React from 'react';
import {
  StepperAction,
  StepperContent,
} from 'react-material-stepper';
import {
  Button,
  Spinner,
} from 'react-bootstrap';
import { WithLoading } from '../../../helper-components/loading.component';
import { WithErrorHandling } from '../../../helper-components/error-handling.component';
import CourseRetakeWarning from './retake-modal';
import errorHandling from '../../../helper-components/alert-component.component';
import api from '../../../../shared/api/adminUI.api';
import AuthContext from '../../../../app/authProvider';
import {
  getElementsWithPrerequisitesPositions,
  returnElementsIdsWithWrongPositions,
  groupCourses,
  updateCourse,
  proccessCoursesDataForSave,
  isSaveDisabled,
} from './course-proccessing-functions';
import { LearningPathContainer } from '../../styled-components';
import {
  ICourse,
  ICoursesSummaryState,
  IProps,
} from './courses-summary.model';
import { ISelectOption } from '../../../../models/SelectOptions';
import CourseList from '../../course-list-drag-n-drop/course-list-drag-n-drop';
import 'react-material-stepper/dist/react-stepper.css';
import { STEP2 } from '../elective-courses/elective-courses';
import { STEP1 } from '../specializations-courses/specialization-course';
import { SAVE } from '../../../constants/common-constants';

export const STEP3 = 'step-three';

const getRetakenCoursesIds = (
  courses: ICourse[],
): string[] => courses.reduce(
  (acc: string[], course) => (
    // eslint-disable-next-line
    course.retaken && acc.push(course.id), acc),
  [],
);

class CoursesSummary extends React.PureComponent<IProps, ICoursesSummaryState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      summaryCoursesDataPending: true,
      isRetakeWindowVisible: false,
      courseForRetake: null,
      error: null,
      summaryCoursesData: [],
      elementIdsWithWrongPositions: [],
      pendingSave: false,
    };
  }

  public componentDidMount(): void {
    const { getBearerToken } = this.context;
    const { learnerId, programEnrollmentId } = this.props;
    this.loadCoursesData(learnerId, programEnrollmentId, getBearerToken);
  }

  private loadCoursesData = async (
    learnerId: string,
    programEnrollmentId: string,
    getBearerToken: () => Promise<string>,
  ): Promise<void> => {
    try {
      const response = await api.learningPathPlanner.coursesSummary.getCoursesSummaryData(
        learnerId,
        programEnrollmentId,
        getBearerToken,
      );
      if (!response.ok) throw await response;

      const summaryCoursesData = (await response.json()) as ICourse[];
      this.setState({
        summaryCoursesData: groupCourses(summaryCoursesData),
        summaryCoursesDataPending: false,
      });
    } catch (error) {
      this.setState({
        summaryCoursesDataPending: false,
        error,
      });
      errorHandling({ error });
    }
  };

  private setRetakeCourse = (courseForRetake: ICourse) => {
    this.setState({ courseForRetake, isRetakeWindowVisible: true });
  };

  private handleRetakeWarningModal = (isRetakeWindowVisible: boolean) => {
    this.setState({ isRetakeWindowVisible });
  };

  private handleSelectChange = (
    newValue: ISelectOption,
    course: ICourse,
    courseId: string,
  ): ISelectOption => {
    this.setState((prevState) => ({
      summaryCoursesData: prevState.summaryCoursesData.map(
        (courseObject: ICourse) => {
          if (courseObject.id === courseId) {
            return {
              ...updateCourse(newValue, course),
            };
          }
          return courseObject;
        },
      ),
    }));
    return newValue;
  };


  private setList = (courses: ICourse[]) => {
    const elementsWithPrerequisitesPositions = getElementsWithPrerequisitesPositions(courses);
    const orderedCourses = courses.map((course, index: number) => ({
      ...course,
      order: index + 1,
    }));

    this.setState({
      summaryCoursesData: [...orderedCourses],
      elementIdsWithWrongPositions: [
        ...returnElementsIdsWithWrongPositions(elementsWithPrerequisitesPositions, courses),
      ],
    });
  };

  private retakeCourse = (course: ICourse | null) => {
    if (course) {
      const courseRetakeCopy: ICourse = {
        ...course,
        fixed: course.defaultFixed,
        statusId: null,
        subStatusId: null,
        retaken: course.retaken + 1,
      };
      const { summaryCoursesData } = this.state;
      summaryCoursesData.splice(courseRetakeCopy.order, 0, courseRetakeCopy);
      const orderedSummaryCoursesData = summaryCoursesData.map(
        (unorderedCourse, index: number) => ({ ...unorderedCourse, order: index + 1 }),
      );
      this.setState({
        summaryCoursesData: [...orderedSummaryCoursesData],
        isRetakeWindowVisible: false,
      });
    }
  };

  private deleteCourse = (course: ICourse) => {
    const { summaryCoursesData } = this.state;
    const summaryCoursesWithoutDeletedRetake: ICourse[] = summaryCoursesData.filter((
      courseObject,
    ): boolean => !(courseObject.id === course.id && courseObject.retaken === course.retaken));
    const orderedSummaryCoursesData = summaryCoursesWithoutDeletedRetake.map(
      (unorderedCourse, index: number) => ({ ...unorderedCourse, order: index + 1 }),
    );
    this.setState({
      summaryCoursesData: [...orderedSummaryCoursesData],
    });
  };


  private handleSave = async (
    courses: ICourse[],
    learnerId: string,
    programEnrollmentId: string,
    getBearerToken:() => Promise<string>,
  ): Promise<void> => {
    try {
      this.setState({ pendingSave: true });
      const courseOrder = proccessCoursesDataForSave(courses);
      const response = await api.learningPathPlanner.coursesSummary.setSelectedCoursesSummaryData(
        learnerId,
        programEnrollmentId,
        getBearerToken,
        courseOrder,
      );
      this.setState({ pendingSave: false });
      if (!response.ok) throw await response;
    } catch (error) {
      errorHandling({ error });
      this.setState({ pendingSave: false });
    }
  };

  render(): JSX.Element {
    const {
      stepperContext,
      submitButtonPermission,
      isIndividualCourseProgram,
      learnerId,
      programEnrollmentId,
    } = this.props;

    const { getBearerToken } = this.context;
    const {
      summaryCoursesData,
      summaryCoursesDataPending,
      error,
      elementIdsWithWrongPositions,
      pendingSave,
      courseForRetake,
      isRetakeWindowVisible,
    } = this.state;

    const retakenCoursesIds = getRetakenCoursesIds(summaryCoursesData);

    if (!stepperContext) {
      return (
        <WithLoading
          loading={summaryCoursesDataPending}
          loadingText="Forming course list..."
          spinner
        >
          <WithErrorHandling error={error}>
            <LearningPathContainer>
              <CourseList
                setList={this.setList}
                deleteRetakenCourse={this.deleteCourse}
                setRetakeCourse={this.setRetakeCourse}
                handleSelectChange={this.handleSelectChange}
                elementIdsWithWrongPositions={elementIdsWithWrongPositions}
                summaryCoursesData={summaryCoursesData}
                retakenCoursesIds={retakenCoursesIds}
                isIndividualCourseProgram={isIndividualCourseProgram}
              />
              {submitButtonPermission && !stepperContext && (
                <Button
                  variant="primary"
                  onClick={() => this.handleSave(summaryCoursesData, learnerId, programEnrollmentId, getBearerToken)}
                  disabled={isSaveDisabled(
                    summaryCoursesData,
                    elementIdsWithWrongPositions,
                    pendingSave,
                  )}
                >
                  <span>{SAVE}</span>
                  {pendingSave && <Spinner animation="border" size="sm" />}
                </Button>
              )}
              <CourseRetakeWarning
                course={courseForRetake}
                show={isRetakeWindowVisible}
                retakeCourse={this.retakeCourse}
                closeDialog={this.handleRetakeWarningModal}
              />
            </LearningPathContainer>
          </WithErrorHandling>
        </WithLoading>
      );
    }
    const { goAt } = stepperContext;
    const back = () => goAt(isIndividualCourseProgram ? STEP1 : STEP2);

    const onSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      this.handleSave(summaryCoursesData, learnerId, programEnrollmentId, getBearerToken);
    };
    return (
      <WithLoading
        loading={summaryCoursesDataPending}
        loadingText="Forming course list..."
        spinner
      >
        <WithErrorHandling error={error}>
          <StepperContent
            onSubmit={onSubmit}
            actions={(
              <>
                {submitButtonPermission && (
                  <>
                    <StepperAction onClick={back}>Back</StepperAction>
                    <StepperAction
                      align="right"
                      type="submit"
                      disabled={isSaveDisabled(
                        summaryCoursesData,
                        elementIdsWithWrongPositions,
                        pendingSave,
                      )}
                    >
                      <span>{SAVE}</span>
                      {pendingSave && <Spinner animation="border" size="sm" />}
                    </StepperAction>
                  </>
                )}
              </>
          )}
          >
            <LearningPathContainer>
              <>
                <CourseList
                  setList={this.setList}
                  setRetakeCourse={this.setRetakeCourse}
                  deleteRetakenCourse={this.deleteCourse}
                  handleSelectChange={this.handleSelectChange}
                  elementIdsWithWrongPositions={elementIdsWithWrongPositions}
                  summaryCoursesData={summaryCoursesData}
                  retakenCoursesIds={retakenCoursesIds}
                  stepperContext={stepperContext}
                  isIndividualCourseProgram={isIndividualCourseProgram}
                />
                <CourseRetakeWarning
                  course={courseForRetake}
                  show={isRetakeWindowVisible}
                  retakeCourse={this.retakeCourse}
                  closeDialog={this.handleRetakeWarningModal}
                />
              </>
            </LearningPathContainer>
          </StepperContent>
        </WithErrorHandling>
      </WithLoading>
    );
  }
}

CoursesSummary.contextType = AuthContext;

export default CoursesSummary;
