import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../../auth/auth-context';
import { usePartnerAgreementsParams } from './use-params';
import api from '../../../../shared/api/adminUI.api';

export const usePropertyValues = () => {
  const { partnerId } = usePartnerAgreementsParams();
  const { getBearerToken } = useAuthContext();

  return useQuery({
    queryKey: ['partner', partnerId, 'product-groups'],
    queryFn: () => api.partners.getPropertyValues(getBearerToken),
    suspense: true,
  });
};
