import React, { useState } from 'react';
import {
  Modal,
  Button,
  Spinner,
} from 'react-bootstrap';

export interface IDeleteDialog {
  deleteFile: () => void;
  setDeleteModalWindowVisible: (visible: boolean) => void;
  fileName: string;
}

const DeleteDialog: React.FunctionComponent<IDeleteDialog> = (props: IDeleteDialog) => {
  const [pending, setPending] = useState(false);
  const {
    setDeleteModalWindowVisible,
    fileName,
    deleteFile,
  } = props;

  return (
    <Modal
      show
      backdrop="static"
    >
      <Modal.Header>
        <h5> WARNING </h5>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the file?</p>
        {fileName}
        <Modal.Footer>
          <Button
            disabled={pending}
            variant="danger"
            onClick={() => {
              setPending(true);
              deleteFile();
            }}
          >
            <span>YES</span>
            {pending && <Spinner size="sm" animation="border" />}
          </Button>
          <Button
            disabled={pending}
            variant="secondary"
            onClick={() => setDeleteModalWindowVisible(false)}
          >
            CANCEL
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteDialog;
