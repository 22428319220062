import { toast } from 'react-toastify';
import IError from '../../models/Error';

interface errorAlertProps {
  error: IError;
}

const errorAlert = (props: errorAlertProps): void => {
  const { error } = props;

  if (error) {
    toast.error(`${error.statusCode || error.status}: ${error.message || error.statusText || error.Message}`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        closeButton: false,
        autoClose: 5000,
        hideProgressBar: true,
      });
  }
};
export default errorAlert;
