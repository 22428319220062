import { AnyAction } from 'redux';
import {
  IVoucherCodeStateTypes,
} from './voucher-code.model';
import {
  GET_VOUCHER_DATA_PENDING,
  GET_VOUCHER_DATA_SUCCESS,
  GET_VOUCHER_DATA_ERROR,
  UPDATE_APPLICATION_FIELD_VALUE,
  UPDATE_TUITION_FIELD_VALUE,
} from './voucher-code.constants';


const initialState: IVoucherCodeStateTypes = {
  voucherData: {
    TuitionFeeVoucherCode: {
      Value: 'N/A',
      IsEditable: false,
    },
    ApplicationFeeVoucherCode: {
      Value: 'N/A',
      IsEditable: false,
    },
  },
  optionsPending: false,
  error: null,
};


const voucherCodeState = (
  state: IVoucherCodeStateTypes = initialState,
  { type, payload }: AnyAction,
):
IVoucherCodeStateTypes => {
  switch (type) {
    case GET_VOUCHER_DATA_PENDING:
      return {
        ...state,
        optionsPending: true,
      };
    case GET_VOUCHER_DATA_SUCCESS:
      return {
        ...state,
        voucherData: payload,
        optionsPending: false,
      };
    case GET_VOUCHER_DATA_ERROR:
      return {
        ...state,
        error: payload,
        voucherData: {
          TuitionFeeVoucherCode: {
            Value: ' ',
            IsEditable: true,
          },
          ApplicationFeeVoucherCode: {
            Value: ' ',
            IsEditable: true,
          },
        },
        optionsPending: false,
      };
    case UPDATE_APPLICATION_FIELD_VALUE:
      return {
        ...state,
        voucherData: {
          ...state.voucherData,
          ApplicationFeeVoucherCode: {
            ...state.voucherData.ApplicationFeeVoucherCode,
            Value: payload.CouponCode,
          },
        },
      };
    case UPDATE_TUITION_FIELD_VALUE:
      return {
        ...state,
        voucherData: {
          ...state.voucherData,
          TuitionFeeVoucherCode: {
            ...state.voucherData.TuitionFeeVoucherCode,
            Value: payload.CouponCode,
          },
        },
      };
    default:
      return state;
  }
};

export default voucherCodeState;
