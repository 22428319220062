import React from 'react';
import moment from 'moment';
import {
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { isNull } from 'lodash';
import AuthContext from '../../../app/authProvider';
import LoaInput from './styled-components';
import LoaSchedulerModal from './loa-scheduler-modal/loa-scheduler-modal';
import {
  ILOASchedulerState,
  ICommonProps,
  ILOASchedulerStateProps,
  IDispatchProps,
} from './loa-scheduler.model';
import {
  getLoaStatusData,
} from '../../../shared/actions/loa-scheduler.action';
import withPermission from '../../helper-components/view-permission.component';
import withLoading, {
  WithLoading,
} from '../../helper-components/loading.component';
import { IRootState } from '../../../shared/reducers';
import {
  getLoaSchedulerData,
  getLoaSchedulerDataPending,
  getLoaSchedulerDataError,
  getLoaSchedulerOptions,
  getLoaSchedulerUpdatePending,
  getLoaSchedulerOptionsPending,
} from '../../../shared/selectors/loa-scheduler.selector';
import {
  getDisableScheduleLoaAfterXDays,
} from '../../../shared/selectors/configuration.selector';

const LEARNER_OUT_OF_LOA_ALLOWANCE = 'Learner has used up all LOA allowance';

class LOAScheduler extends React.PureComponent<
ICommonProps,
ILOASchedulerState
> {
  constructor(props: ICommonProps) {
    super(props);

    this.state = {
      show: false,
    };
  }

  public componentDidMount(): void {
    const {
      getLoaSchedulerInfo,
      programEnrollmentId,
    } = this.props;
    const { learnerId, getBearerToken } = this.context;
    getLoaSchedulerInfo(getBearerToken, learnerId, programEnrollmentId);
  }

  private handleModal(show: boolean) {
    this.setState({ show });
  }

  public render(): JSX.Element {
    const {
      loaSchedulerData,
      canEditLOA,
      loaSchedulerDataPending,
      loaOptions,
      pendingDataSave,
      disableScheduleLoaAfterXDays,
      learnerPaymentStatus,
      loaOptionsPending,
      programEnrollmentId,
    } = this.props;
    const { learnerId, getBearerToken } = this.context;
    const {
      isLoaScheduled, startDate, returnDate, remaining,
    } = loaSchedulerData;
    const { show } = this.state;
    const startDateFormatted = startDate ? moment(startDate).format('DD MMM YYYY') : null;
    const loaSelectorValue = isLoaScheduled
      ? `On LOA from ${startDateFormatted}`
      : 'LOA is not scheduled';

    const noLoaScheduled = isNull(returnDate);
    const learnerHasLoaNoAllowanceLeft = remaining === 0 && noLoaScheduled;

    return (
      <>
        <WithLoading
          loading={loaSchedulerDataPending || loaOptionsPending}
          loadingText="Loa Scheduler loading ..."
        >
          <InputGroup className="xs-6">
            { !learnerHasLoaNoAllowanceLeft ? (
              <LoaInput
                readOnly
                aria-describedby="basic-addon2"
                size="sm"
                value={loaSelectorValue}
              />
            ) : (
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id="tooltip-top">
                    {LEARNER_OUT_OF_LOA_ALLOWANCE}
                  </Tooltip>
                )}
              >
                <LoaInput
                  readOnly
                  aria-describedby="basic-addon2"
                  size="sm"
                  value={LEARNER_OUT_OF_LOA_ALLOWANCE}
                />
              </OverlayTrigger>
            )}

            <InputGroup.Append>
              {canEditLOA && !learnerHasLoaNoAllowanceLeft && (
                <>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => this.handleModal(true)}
                  >
                    {pendingDataSave ? (
                      <Spinner size="sm" animation="border" />
                    ) : (
                      <FiEdit />
                    )}
                  </Button>
                </>
              )}
            </InputGroup.Append>
          </InputGroup>
          <LoaSchedulerModal
            programEnrollmentId={programEnrollmentId}
            loaSchedulerData={loaSchedulerData}
            getBearerToken={getBearerToken}
            loaOptions={loaOptions}
            isLoaScheduled={isLoaScheduled}
            learnerPaymentStatus={learnerPaymentStatus}
            disableScheduleLoaAfterXDays={disableScheduleLoaAfterXDays}
            learnerId={learnerId}
            show={show}
            handleClose={() => this.handleModal(false)}
          />
        </WithLoading>
      </>
    );
  }
}

LOAScheduler.contextType = AuthContext;

const mapStateToProps = (state: IRootState): ILOASchedulerStateProps => ({
  loaSchedulerData: getLoaSchedulerData(state),
  loaSchedulerDataPending: getLoaSchedulerDataPending(state),
  loaOptionsPending: getLoaSchedulerOptionsPending(state),
  disableScheduleLoaAfterXDays: getDisableScheduleLoaAfterXDays(state),
  pendingDataSave: getLoaSchedulerUpdatePending(state),
  loaOptions: getLoaSchedulerOptions(state),
  error: getLoaSchedulerDataError(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>,
): IDispatchProps => ({
  getLoaSchedulerInfo: (
    token: () => Promise<string>,
    learnerId: string,
    programEnrollmentId: string,
  ): void => {
    dispatch(getLoaStatusData(token, learnerId, programEnrollmentId));
  },
});

export default withLoading(
  withPermission(connect(mapStateToProps, mapDispatchToProps)(LOAScheduler)),
);
