/* eslint react/prop-types: 0 */
/* eslint-disable react/destructuring-assignment, max-len */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Col, Row, Tabs, Tab,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  LearnerDashboardContainer,
  TabsContainer,
  LearnerNameIdContainer,
} from './styled-components';
import LearnerInfo, { getProgramType } from '../learner-info/learner-info';
import LearnerGrades from '../learner-grades';
import LearnerExternalRecords from './components/learner-ext-records';
import LearnerNameId from './components/learner-name-id';
import LearnerHistory from '../learner-history/learner-history';
import LearnerFiles from '../learner-files/learner-files';
import {
  getLearnerProfileViewPermission,
  getEnrollmentEditingPermissions,
  loadLearnerApplicantInfo,
  loadLearnerAchievements,
  updateLearnerAchievementStatus,
  getLearnerAchievementsPermission,
  getLPPPermission,
  getAdmissionEditingPermissions,
  updateActiveProgramEnrollment,
} from '../../shared/actions/learner-profile.action';
import {
  getLearnerStatus,
  getLearnerAchievements,
  getLearnerInfoPermissions,
  getLearnerInfoPermissionsPending,
  getLearnerInfoPermissionsError,
  getLearnerAchievementsPermissions,
  getLearnerAchievementsPending,
  getLearnerAchievementsPermissionsError,
  getUpdateAchievementStatusPending,
  getLearnerProgramEnrollment,
  getActiveProgramEnrollmentId,
  getLPPPermissions,
  getLPPPermissionsPending,
  getLearnerApplicantInfo,
  getLearnerApplicantInfoPending,
} from '../../shared/selectors/learner-info.selector';
import {
  getLearnerProfilePermission,
  getLearnerProfileViewPermissionStatus,
  getLearnerProfileViewPermissionError,
} from '../../shared/selectors/learner-dashboard.selector';
import { WithLoading } from '../helper-components/loading.component';
import { WithPermissions } from '../helper-components/view-permission.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import { IRootState } from '../../shared/reducers';
import {
  IDispatchProps,
  ILearnerDashboardStateProps,
  LearnerDashboardProps,
} from './learner-dashboard.model';
import { ACCESS_DENIED_TEXT } from '../learning-path/learning-path.constants';
import Communications from '../communications/communications';
// eslint-disable-next-line import/no-named-as-default
import Achievements from '../achievements/achievements';
import getQueryVariable from '../helper-components/get-query-variable';
import { LearningPath } from '../learning-path/learning-path';
import { InternalProvider } from '../constants/common-constants';
import { LoadingP } from '../helper-components/styled-components';

const GeneralLearnerInfoComponent: React.FC<{useRegistration: boolean; learnerApplicantInfoPending: boolean}> = (props): JSX.Element => (
  <>
    <LearnerNameIdContainer>
      <Row>
        <Col xs={8}>
          <LearnerNameId />
        </Col>
        <Col xs={4}>
          <LearnerExternalRecords useRegistration={props.useRegistration} learnerApplicantInfoPending={props.learnerApplicantInfoPending} />
        </Col>
      </Row>
      <LearnerInfo />
    </LearnerNameIdContainer>
  </>
);

// eslint-disable-next-line max-len
export const LearnerDashboard: React.FC<LearnerDashboardProps> = (props: LearnerDashboardProps): JSX.Element => {
  const {
    getLearnerProfilePermissionObject,
    getLearnerInfoPermissionsObject,
    getLearnerApplicantInfoObject,
    getLearnerAchievementsObject,
    getLearnerAchievementsPermissionsObject,
    getAdmissionPermissionsObject,
    getLPPPermissionsObject,
    learnerAchievementsPermissions,
    lppPermissions,
    lppPermissionsPending,
    learnerId,
    learnerApplicantInfo,
    learnerApplicantInfoPending,
    getBearerToken,
    learnerInfoPermissionsObject,
    learnerProfileViewPermission,
    canReadLearnerProfilePending,
    learnerInfoPermissionsPending,
    learnerProfilePermissionError,
    learnerInfoPermissionsError,
    learnerAchievements,
    updateLearnerAchievement,
    learnerAchievementsPending,
    learnerAchievementsPermissionsError,
    updateAchievementStatusPending,
    learnerProgramEnrollments,
    setActiveProgramEnrollment,
    activeProgramEnrollmentId,
  } = props;

  const {
    canViewEnrollmentData,
    canEditProgramStatus,
    canDeleteLearnerCourses,
    canEditAepStatus,
    canApproveDropRequest,
    canEditCompletedCourse,
  } = learnerInfoPermissionsObject;

  const {
    canEditLearningPathPlan,
    canViewLearningPathPlan,
  } = lppPermissions;

  const windowHref = window.location.origin + window.location.pathname;
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    getAdmissionPermissionsObject(getBearerToken);
    getLearnerProfilePermissionObject(getBearerToken);
    getLearnerInfoPermissionsObject(getBearerToken);
    getLearnerAchievementsPermissionsObject(getBearerToken);
    getLPPPermissionsObject(getBearerToken);

    const lastUrlSegment = windowHref.substring(windowHref.lastIndexOf('/') + 1);
    if (lastUrlSegment !== learnerId && lastUrlSegment !== 'transfers') {
      setCurrentTab(lastUrlSegment);
    }

    window.onpopstate = (e: any) => {
      const currentHref = e.target.location.href;
      const lastSegment = currentHref.split('?')[0].substring(currentHref.lastIndexOf('/') + 1);
      const currentPE = getQueryVariable('programEnrollmentId');

      if (currentPE) {
        setActiveProgramEnrollment(currentPE);
      }

      if (lastSegment !== learnerId && lastSegment !== 'transfers') {
        setCurrentTab(lastSegment);
      } else {
        setCurrentTab('performance');
      }
    };
    // eslint-disable-next-line max-len
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    learnerAchievementsPermissions && learnerAchievementsPermissions.canReadLearnerAchievements
      && getLearnerAchievementsObject(getBearerToken, learnerId);
  }, [learnerAchievementsPermissions]);

  useEffect(() => {
    if (learnerProfileViewPermission) {
      getLearnerApplicantInfoObject(getBearerToken, learnerId);
    }
  }, [learnerProfileViewPermission]);

  const handleSelect = (key: any) => {
    const windowPath = window.location.pathname;
    const isEndsWithId = windowPath.endsWith(learnerId);
    const urlKey = isEndsWithId ? `${learnerId}/` : '';
    const queryParams = new URLSearchParams(window.location.search);
    const peId = queryParams.get('programEnrollmentId');
    const peSearch = peId ? `?programEnrollmentId=${peId}` : '';

    setCurrentTab(key);
    window.history.replaceState({}, '', urlKey + key + peSearch);
  };

  useEffect(() => {
    const lastUrlSegment = windowHref.substring(windowHref.lastIndexOf('/') + 1);
    const isTransferActive = lastUrlSegment === 'transfers';
    const isLastSegmentNotId = (lastUrlSegment !== learnerId) && !windowHref.endsWith(`${learnerId}/`);

    if (isLastSegmentNotId && !isTransferActive) {
      handleSelect(lastUrlSegment);
    } else if (currentTab) {
      handleSelect(currentTab);
    } else if (!isTransferActive) {
      handleSelect('performance');
    }
  });

  learnerProgramEnrollments.sort((a, b) => {
    const cond = b.enrolledDateTime > a.enrolledDateTime ? 1 : 0;
    return a.enrolledDateTime > b.enrolledDateTime ? -1 : cond;
  });

  let programEnrollmentId = getQueryVariable('programEnrollmentId');
  const latestPE = learnerProgramEnrollments.find((item) => item.isLatest);
  const currentPE = learnerProgramEnrollments.find((item) => {
    const activePE = activeProgramEnrollmentId || programEnrollmentId;
    if (activePE) {
      return item.programEnrollmentId === activePE;
    }
    return item.isLatest;
  });

  if (latestPE) {
    if (!programEnrollmentId) {
      setActiveProgramEnrollment(latestPE.programEnrollmentId);
    } else {
      setActiveProgramEnrollment(programEnrollmentId);
    }
  }

  // @ts-ignore
  const currentPEName = currentPE && currentPE.displayName;
  const currentPeCode = currentPE ? currentPE.productCode : '';
  const currentPeType = currentPE ? currentPE.productType : '';

  const isCurrentProgramEnrollment = latestPE && latestPE.programEnrollmentId === programEnrollmentId;
  const isExternalEnrollment = currentPE && currentPE.productProvider !== InternalProvider;

  if (!programEnrollmentId) {
    programEnrollmentId = activeProgramEnrollmentId;
  }

  return (
    <LearnerDashboardContainer>
      <WithLoading loading={canReadLearnerProfilePending} loadingText="Loading access permissions...">
        <WithErrorHandling error={learnerProfilePermissionError}>
          <WithPermissions permission={learnerProfileViewPermission} noAccesText="You do not have permission to access Learner Personal Data">
            <GeneralLearnerInfoComponent
              learnerApplicantInfoPending={learnerApplicantInfoPending}
              useRegistration={learnerApplicantInfo?.ApplicationSource === 'apply-nxu' || false}
            />
          </WithPermissions>
        </WithErrorHandling>
      </WithLoading>
      <hr />
      {currentPE && programEnrollmentId && (
        <div id="dashboard-tabs">
          <Tabs defaultActiveKey="performance" id="learnerInfoTabs" onSelect={handleSelect} activeKey={currentTab}>
            <Tab
              eventKey="performance"
              title={
                (
                  <div>
                    <span>Academic Performance</span>
                    <p className="enrollment-name">{currentPEName}</p>
                  </div>
                )
              }
            >
              <TabsContainer>
                {isExternalEnrollment
                  ? (<LoadingP>Not available for bootcamp learners</LoadingP>)
                  : (
                    <WithLoading loading={learnerInfoPermissionsPending} loadingText="Loading access permissions...">
                      <WithErrorHandling error={learnerInfoPermissionsError}>
                        <WithPermissions
                          permission={canViewEnrollmentData}
                          noAccesText="You do not have permission to access Learner Grades"
                        >
                          <LearnerGrades
                            getBearerToken={getBearerToken}
                            canUpdateAcademicPerformance={canEditProgramStatus}
                            canDeleteLearnerCourses={canDeleteLearnerCourses}
                            canApproveDropRequest={canApproveDropRequest}
                            canEditCompletedCourse={canEditCompletedCourse}
                            learnerId={learnerId}
                            programEnrollmentId={programEnrollmentId}
                          />
                        </WithPermissions>
                      </WithErrorHandling>
                    </WithLoading>)}
              </TabsContainer>
            </Tab>

            <Tab eventKey="credentials" title="Credentials">
              <WithLoading loading={learnerAchievementsPending} loadingText="Loading access Achievements...">
                <WithErrorHandling error={learnerAchievementsPermissionsError}>
                  <WithPermissions
                    permission={learnerAchievementsPermissions.canReadLearnerAchievements}
                    noAccesText="You do not have permission to access credentials"
                  >
                    <Achievements
                      // eslint-disable-next-line max-len
                      canApproveAchievements={learnerAchievementsPermissions.canApproveLearnerAchievements}
                      infoAchievements={learnerAchievements}
                      getBearerToken={getBearerToken}
                      learnerId={learnerId}
                      updateStatusPending={updateAchievementStatusPending}
                      onUpdateLearnerAchievementStatus={updateLearnerAchievement}
                    />
                  </WithPermissions>
                </WithErrorHandling>
              </WithLoading>
            </Tab>

            <Tab eventKey="history" title="Learner History">
              <LearnerHistory
                getBearerToken={getBearerToken}
                learnerId={learnerId}
              />
            </Tab>
            <Tab
              eventKey="paths"
              title={
                (
                  <div>
                    <span>Learning Path</span>
                    <p className="enrollment-name">{currentPEName}</p>
                  </div>
                )
              }
            >
              {isExternalEnrollment
                ? (<LoadingP>Not available for bootcamp learners</LoadingP>)
                : (
                  <WithLoading
                    loading={lppPermissionsPending}
                    loadingText="Loading access permissions..."
                  >
                    <WithErrorHandling error={learnerInfoPermissionsError}>
                      <WithPermissions
                        permission={canViewLearningPathPlan}
                        noAccesText={ACCESS_DENIED_TEXT}
                      >
                        <LearningPath
                          canSaveLearningPathChanges={canEditLearningPathPlan && (isCurrentProgramEnrollment || false)}
                          learnerProgramType={getProgramType(currentPeCode, currentPeType)}
                          learnerProductCode={currentPeCode}
                          learnerId={learnerId}
                          programEnrollmentId={programEnrollmentId}
                          getBearerToken={getBearerToken}
                        />
                      </WithPermissions>
                    </WithErrorHandling>
                  </WithLoading>)}

            </Tab>
            <Tab eventKey="files" title="Learner Files">
              <WithLoading loading={learnerInfoPermissionsPending} loadingText="Loading access permissions...">
                <WithErrorHandling error={learnerInfoPermissionsError}>
                  <WithPermissions
                    permission
                    noAccesText={ACCESS_DENIED_TEXT}
                  >
                    <LearnerFiles
                      editAepOnUpload={canEditAepStatus}
                      learnerId={learnerId}
                      getBearerToken={getBearerToken}
                      isAdmission={false}
                    />
                  </WithPermissions>
                </WithErrorHandling>
              </WithLoading>
            </Tab>
            <Tab eventKey="communication" title="Communication">
              <Communications
                getBearerToken={getBearerToken}
                learnerId={learnerId}
              />
            </Tab>
          </Tabs>
        </div>)
      }
    </LearnerDashboardContainer>
  );
};

const mapStateToProps = (state: IRootState): ILearnerDashboardStateProps => ({
  learnerProgramEnrollments: getLearnerProgramEnrollment(state),
  activeProgramEnrollmentId: getActiveProgramEnrollmentId(state),
  learnerProfileViewPermission: getLearnerProfilePermission(state),
  learnerInfoPermissionsObject: getLearnerInfoPermissions(state),
  learnerInfoPending: getLearnerStatus(state),
  learnerInfoPermissionsPending: getLearnerInfoPermissionsPending(state),
  canReadLearnerProfilePending: getLearnerProfileViewPermissionStatus(state),
  learnerProfilePermissionError: getLearnerProfileViewPermissionError(state),
  learnerInfoPermissionsError: getLearnerInfoPermissionsError(state),
  learnerAchievements: getLearnerAchievements(state),
  learnerApplicantInfoPending: getLearnerApplicantInfoPending(state),
  learnerApplicantInfo: getLearnerApplicantInfo(state),
  learnerAchievementsPermissions: getLearnerAchievementsPermissions(state),
  learnerAchievementsPending: getLearnerAchievementsPending(state),
  learnerAchievementsPermissionsError: getLearnerAchievementsPermissionsError(state),
  updateAchievementStatusPending: getUpdateAchievementStatusPending(state),
  lppPermissionsPending: getLPPPermissionsPending(state),
  lppPermissions: getLPPPermissions(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>,
): IDispatchProps => (
  {
    getLearnerProfilePermissionObject: (token: () => Promise<string>): void => {
      dispatch(getLearnerProfileViewPermission(token));
    },
    getLearnerInfoPermissionsObject: (token: () => Promise<string>): void => {
      dispatch(getEnrollmentEditingPermissions(token));
    },
    getLPPPermissionsObject: (token: () => Promise<string>): void => {
      dispatch(getLPPPermission(token));
    },
    getLearnerAchievementsPermissionsObject: (token: () => Promise<string>): void => {
      dispatch(getLearnerAchievementsPermission(token));
    },
    getLearnerApplicantInfoObject: (
      token: () => Promise<string>,
      learnerId: string,
    ): void => {
      dispatch(loadLearnerApplicantInfo(token, learnerId));
    },
    getLearnerAchievementsObject: (
      token: () => Promise<string>,
      learnerId: string,
    ): void => {
      dispatch(loadLearnerAchievements(token, learnerId));
    },
    updateLearnerAchievement: (
      token: () => Promise<string>,
      achievementId: string,
    ): void => {
      dispatch(updateLearnerAchievementStatus(token, achievementId));
    },
    getAdmissionPermissionsObject: (token: () => Promise<string>): void => {
      dispatch(getAdmissionEditingPermissions(token));
    },
    setActiveProgramEnrollment: (programEnrollmentId: string): void => {
      dispatch(updateActiveProgramEnrollment(programEnrollmentId));
    },
  });
export default connect(mapStateToProps, mapDispatchToProps)(LearnerDashboard);
