import { useMemo } from 'react';
import moment from 'moment';
import { useLatestAgreement } from '../../hooks/use-latest-agreement';
import { usePropertyValues } from '../../hooks/use-property-values';
import { PartnerAgreementFormValues } from '../../types';

export const useAgreementInitialValues = () => {
  const { data: propertyValues } = usePropertyValues();
  const { data: latestAgreement } = useLatestAgreement();

  return useMemo<Partial<PartnerAgreementFormValues>>(
    () => ({
      enrollmentAgreementId: latestAgreement?.enrollmentAgreementId,
      startDate: latestAgreement?.startDate
        ? moment(latestAgreement.startDate).valueOf()
        : undefined,
      endDate: latestAgreement?.endDate
        ? moment(latestAgreement.endDate).valueOf()
        : undefined,
      applicationFeeAmount: latestAgreement?.applicationFeeAmount,
      agreementTuitions: propertyValues?.productGroups.map((product) => {
        const activeTier = latestAgreement?.agreementTuitions.find(
          (agreement) => agreement.productGroup === product.id && !!agreement.planId,
        );

        return {
          enabled: !!activeTier,
          productId: product.id,
          tierTypeIds: propertyValues.tierTypes.map((tier) => {
            const defaultTier = latestAgreement?.agreementTuitions.find(
              (agreement) => agreement.productGroup === product.id
                && agreement.tier === tier.id,
            );

            return {
              id: tier.id,
              value: defaultTier ? defaultTier.planId : '',
            };
          }),
        };
      }),
      applicationFeePaidBy: latestAgreement?.applicationFeePaidBy,
      tuitionFeeInvoiceType: latestAgreement?.tuitionFeeInvoiceType,
      tuitionFeePaymentUrl: latestAgreement?.tuitionFeePaymentUrl,
    }),
    [propertyValues, latestAgreement],
  );
};
