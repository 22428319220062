import React from 'react';
import { format } from 'util';
import styles from './partner-details-card.module.scss';
import { urlsTemplates } from '../../../../../config.json';
import { usePartnerDetailsParams } from '../../hooks/use-partner-details-params';
import { usePartner } from '../../../hooks/use-partner';
import { EditPartnerButton } from './edit-partner-button';

type FieldProps = {
  label: string;
  value: string;
};

const Field = ({ label, value }: FieldProps) => (
  <div className={styles.field}>
    <span className={styles.label}>{label}:</span>
    <span className={styles.value}>{value}</span>
  </div>
);

export const PartnerDetailsCard = () => {
  const { partnerId } = usePartnerDetailsParams();
  const { data: partner } = usePartner(partnerId, { suspense: true });

  const applicationURL = format(urlsTemplates.partnersApplyNXU, partnerId);

  return (
    <div className={styles.container}>
      <Field label="Application URL" value={applicationURL} />
      <div className={styles.row}>
        <Field label="Customer ID" value={partner!.partnerId} />
        <Field label="Email" value={partner!.email} />
        <Field label="Contact Name" value={partner!.contactName?.trim() || 'N/A'} />
        <EditPartnerButton />
      </div>
    </div>
  );
};
