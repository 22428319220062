import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { PartnerFormModal } from '../../../components/partner-form-modal/partner-form-modal';
import { usePartnerDetailsParams } from '../../hooks/use-partner-details-params';
import { usePartner } from '../../../hooks/use-partner';
import { PartnerFormValues } from '../../../components/partner-form/partner-form';
import api from '../../../../../shared/api/adminUI.api';
import { CreatePartnerPayload } from '../../../../../models/Partner';
import { useAuthContext } from '../../../../auth/auth-context';
import { AppError } from '../../../../../shared/api/app-error';

export const EditPartnerButton = () => {
  const { getBearerToken } = useAuthContext();
  const { partnerId } = usePartnerDetailsParams();

  const [open, setOpen] = useState(false);
  const [img, setImage] = useState<FileList | undefined>();

  const { data: partner, refetch } = usePartner(partnerId, { suspense: true });
  const { mutateAsync, error, reset } = useMutation<
  unknown,
  AppError,
  CreatePartnerPayload
  >({
    mutationFn: (payload) => api.partners.updatePartner(partnerId, payload, getBearerToken),
  });

  const handleSubmit = async (data: PartnerFormValues) => {
    try {
      await mutateAsync({
        accountName: data['partner-name'],
        contactName: data['partner-contact-name'],
        email: data['partner-email'],
        logo: data['partner-logo']?.item(0),
      });

      toast.success('Partner updated successfully');
      setOpen(false);
      reset();
      refetch();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [reset, open]);

  useEffect(() => {
    if (partner?.logoUploadedFileName) {
      const data = new window.DataTransfer();
      data.items.add(new window.File([], `/api/public-learner-files/partners/${partner.partnerId}/${partner.logoUploadedFileName}`));
      setImage(data.files);
    }
  }, [partner]);

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="dark">
        EDIT
      </Button>

      <PartnerFormModal
        initialValues={{
          'partner-contact-name': partner!.contactName,
          'partner-email': partner!.email,
          'partner-name': partner!.accountName,
          'partner-logo': img,
        }}
        show={open}
        onSubmit={handleSubmit}
        onHide={() => setOpen(false)}
        error={error}
        title="Edit Partner"
      />
    </>
  );
};
