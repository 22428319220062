import {
  Row,
  Button,
} from 'react-bootstrap';
import styled from 'styled-components';

export const LearnerFilesContainer = styled.div`
    font-family: "Open Sans";
    text-align: left;
    padding: 10px;
    margin-bottom: 20px;
`;

export const FilesListContainer = styled.div`
    font-family: "Open Sans";
    text-align: left;
    margin-top: 20px;
`;

export const FormRow = styled(Row)`
  padding: 10px;
`;

export const FileEditingButton = styled(Button)`
border-color: white;
background-color: white;
margin: 0 1px 0 1px;
&:hover {
  background-color: transparent;
  border-color: #6c757d;
}
&:active {
  background-color: transparent!important;
  border-color: #6c757d!important;
}
&:focus {
  box-shadow: none!important;
}

&:disabled {
  opacity: 0.30!important;
  background-color: transparent!important;
  border-color: transparent!important;
}`;

export const FormWarning = styled.div`
  margin: 10px;
`;
