// eslint-disable-next-line no-empty
// eslint-disable-next-line @typescript-eslint/camelcase
import { AnyAction } from 'redux';
import {
  IApplicantEditableDataStateTypes,
} from './applicant-editable-info.model';
import {
  GET_APPLICANT_DATA_ERROR,
  GET_APPLICANT_DATA_PENDING,
  GET_APPLICANT_DATA_SUCCESS,
  UPDATE_APPLICANT_FIELD_VALUE,
} from './applicant-editable-info.constants';

const initialState: IApplicantEditableDataStateTypes = {
  applicantEditableInfo: {
    ApplicationId: '',
    FullPhoneNumber: '',
    PhoneCountryCode: '',
    PhoneNumber: '',
    YearOfBirth: null,
    FirstName: '',
    LastName: '',
    Gender: '',
    Country: '',
    Nationality: '',
    City: '',
    Language: '',
    InstitutionName: '',
    InstitutionLanguage: '',
    GraduationYear: null,
    InstitutionCountry: '',
    HighestEducationLevel: '',
    DegreeOrCertificateName: '',
    ApplicationStatus: null,
    State: null,
  },
  optionsPendingApplicantEditableInfo: false,
  errorApplicantEditableInfo: null,
};

const applicantEditableDataState = (
  state: IApplicantEditableDataStateTypes = initialState,
  { type, payload }: AnyAction,
): IApplicantEditableDataStateTypes => {
  switch (type) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    case GET_APPLICANT_DATA_PENDING:
      return {
        ...state,
        optionsPendingApplicantEditableInfo: true,
      };
      // eslint-disable-next-line @typescript-eslint/camelcase
    case GET_APPLICANT_DATA_SUCCESS:
      return {
        ...state,
        applicantEditableInfo: {
          ...payload,
          FullPhoneNumber: `${payload.PhoneCountryCode}${payload.PhoneNumber}`,
        },
        optionsPendingApplicantEditableInfo: false,
      };
      // eslint-disable-next-line @typescript-eslint/camelcase
    case UPDATE_APPLICANT_FIELD_VALUE:
      return {
        ...state,
        applicantEditableInfo: {
          ...state.applicantEditableInfo,
          ...payload,
        },
      };
      // eslint-disable-next-line @typescript-eslint/camelcase
    case GET_APPLICANT_DATA_ERROR:
      return {
        ...state,
        errorApplicantEditableInfo: payload,
        applicantEditableInfo: {
          ApplicationId: '',
          FullPhoneNumber: '',
          PhoneCountryCode: '',
          PhoneNumber: '',
          YearOfBirth: 0,
          FirstName: '',
          LastName: '',
          Gender: '',
          Country: '',
          Nationality: '',
          City: '',
          Language: '',
          InstitutionName: '',
          InstitutionLanguage: '',
          GraduationYear: 0,
          InstitutionCountry: '',
          HighestEducationLevel: '',
          DegreeOrCertificateName: '',
          ApplicationStatus: null,
          State: null,
        },
        optionsPendingApplicantEditableInfo: false,
      };
    default:
      return state;
  }
};

export default applicantEditableDataState;
