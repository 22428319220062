import React from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-final-form';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './form.module.scss';
import { usePartnerAgreementsParams } from '../../hooks/use-params';

export const FormActions = () => {
  const { getState } = useForm();

  const { partnerId } = usePartnerAgreementsParams();

  const { submitting } = getState();

  return (
    <div className={cn(styles.row, styles.actions)}>
      <Button as={Link} to={`/partners/${partnerId}`} variant="secondary" disabled={submitting}>
        Cancel
      </Button>
      <Button variant="success" type="submit" disabled={submitting}>
        Submit
      </Button>
    </div>
  );
};
