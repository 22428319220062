import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PersonalDetailsCard } from '../../styled-components';
import { BoldColumn } from '../../../learner-info/styled-components';
import { IPersonalInfo, INationalityInfo, IIdentityInfo } from '../../admissions.model';
import { StyledWrapper } from '../styled-components';

interface IApplicantPersonalDetailsProps {
  personalInfo: IPersonalInfo | null;
  nationalityInfo: INationalityInfo | null;
  identityInfo: IIdentityInfo | null;
}

const ApplicantPersonalDetails: React.FC<IApplicantPersonalDetailsProps> = (
  props: IApplicantPersonalDetailsProps,
): JSX.Element => {
  const { personalInfo, nationalityInfo, identityInfo } = props;
  return (
    <StyledWrapper>
      <h3>Personal Details</h3>
      <PersonalDetailsCard>
        <Row>
          <Col xs={6}>
            <span>Name:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>
              {(identityInfo && identityInfo.officialName)
              || (personalInfo && (`${personalInfo.FirstName} ${personalInfo.LastName}`))}
            </span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>Phone:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{personalInfo && (`${personalInfo.PhoneCountryCode} ${personalInfo.PhoneNumber}`)}
            </span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>Year Of Birth:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{personalInfo && personalInfo.YearOfBirth}
            </span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>Gender:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{personalInfo && personalInfo.Gender}</span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>Country:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{nationalityInfo && nationalityInfo.Country}</span>
          </BoldColumn>
        </Row>
        {nationalityInfo && nationalityInfo.State && (
        <Row>
          <Col xs={6}>
            <span>State:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{nationalityInfo.State}</span>
          </BoldColumn>
        </Row>
        )}
        <Row>
          <Col xs={6}>
            <span>Nationality:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{nationalityInfo && nationalityInfo.Nationality}</span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>City:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{nationalityInfo && nationalityInfo.City}</span>
          </BoldColumn>
        </Row>
        <Row>
          <Col xs={6}>
            <span>Native Language:</span>
          </Col>
          <BoldColumn xs={6}>
            <span>{nationalityInfo && nationalityInfo.Language}</span>
          </BoldColumn>
        </Row>
      </PersonalDetailsCard>
    </StyledWrapper>
  );
};
export default ApplicantPersonalDetails;
