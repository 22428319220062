import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './form-card.module.scss';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const FormCard = ({ title, children }: Props) => (
  <Card>
    <Card.Title className={styles.title}>{title}</Card.Title>
    <Card.Body className={styles.body}>{children}</Card.Body>
  </Card>
);
