import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const ExternalTransferWrapper = styled.div`
  margin-top: 20px;
  .single-course-block {
    &:not(:last-child) {
      border-bottom: 1px solid #dee2e6;
      margin-bottom: 12px;
    }
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  .single-external-transfer {
    margin-bottom: 18px;
  }
  .course-label {
    font-weight: bold;
  }
  .row-codes {
    margin-bottom: 8px;
  }
  .field-error {
    font-size: 11px;
  }
  .transfer-buttons {
    margin-top: 20px;
    text-align: right;
    button {
      width: 100px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
`;

export const DeleteButton = styled(Button)`
  background-color: transparent;
  border: 0;
  margin: 0 1px 0 1px;
  opacity: 0.7;
  &:hover {
    background-color: transparent;
    border-color: #6c757d;
    opacity: 1;
  }
  &:active {
    background-color: transparent !important;
    opacity: 1;
  }
  &:focus {
    box-shadow: none !important;
    opacity: 1;
  }
  
  &:disabled {
    background-color: transparent !important;
    opacity: 0.30 !important;
  }
`;

export default ExternalTransferWrapper;
