/* eslint-disable max-len */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-closing-bracket-location */
import React from 'react';
import ReviewIcon from './application-review-icon';
import { DocumentInfo } from '../admissions.model';
import WaitButton from '../../../shared/wait-button';
import AdmissionsFileUpload from './file-upload';
import { INxuFileUploadResult } from '../../../shared/models/file-upload-result';

export interface IdentityPhotoProps {
  readonly applicantId: string;
  readonly applicationStatus: string;
  readonly photoDocument: DocumentInfo;
  readonly url: string | null;
  readonly status: string;
  readonly reviewer: string;
  readonly saveIdentityPhoto: (
    applicantId: string,
    foo: DocumentInfo
  ) => Promise<void>;
  readonly getBearerToken: () => Promise<string>;
}

interface IdentityPhotoState {
  readonly photoDocument: DocumentInfo;
  readonly photoDocumentChanged: boolean;
  readonly saving: boolean;
  readonly valid: boolean;
}

class IdentityPhoto extends React.Component<
IdentityPhotoProps,
IdentityPhotoState
> {
  constructor(props: IdentityPhotoProps) {
    super(props);
    this.state = {
      photoDocument: props.photoDocument,
      photoDocumentChanged: false,
      saving: false,
      valid: true,
    };
  }

  readonly isLatinOnly = (name: string): boolean => (!name ? true : !!name.match('^[\u0000-\u00FF]{1,200}$'));

  async save(): Promise<boolean> {
    const { photoDocument } = this.state;
    const { saveIdentityPhoto, applicantId } = this.props;

    this.setState({ saving: true });
    await saveIdentityPhoto(applicantId, photoDocument);
    this.setState({
      saving: false,
      photoDocumentChanged: false,
    });
    return true;
  }

  photoUploadStarted(): void {
    this.setState({
      saving: true,
      photoDocumentChanged: true,
    });
  }

  async handlePhotoUploaded(result: INxuFileUploadResult): Promise<void> {
    this.setState({
      photoDocument: {
        originalFileName: result.originalFileName,
        uploadedFileName: result.uploadedFileName,
      },
      photoDocumentChanged: true,
    });
    await this.save();
  }

  render() {
    const {
      url,
      status,
      reviewer,
      applicantId,
      applicationStatus,
      getBearerToken,
    } = this.props;
    const {
      photoDocumentChanged,
      saving,
      valid,
      photoDocument,
    } = this.state;
    const showSave = photoDocumentChanged
      && photoDocument
      && photoDocument.uploadedFileName;
    const editable = (status === 'Failed' || status === 'Passed')
      && (applicationStatus === 'Submitted' || applicationStatus === 'Enrolling');

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ReviewIcon status={status} />
        </div>
        <div
          style={{
            background: '#F3F3F3',
            paddingLeft: 5,
            paddingRight: 10,
            paddingTop: 5,
            paddingBottom: 6,
          }}
        >
          <div>
            {url && (
              <a href={url} target="_blank" rel="noopener noreferrer">
                Selfie
              </a>
            )}
            {!url && <span>No selfie</span>}
            {editable && (
              <span style={{ fontSize: 'small', paddingLeft: 3 }}>
                <AdmissionsFileUpload
                  getBearerToken={getBearerToken}
                  learnerId={applicantId}
                  type="personal-photo"
                  uploadStarted={() => this.photoUploadStarted()}
                  onFileUploaded={(p) => this.handlePhotoUploaded(p)}
                >
                  change
                </AdmissionsFileUpload>
              </span>
            )}
          </div>
          {reviewer && <div>Verified By: {reviewer}</div>}
        </div>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 10,
          }}
        >
          {showSave && (
            <WaitButton
              size="sm"
              variant="outline-primary"
              onClick={() => this.save()}
              disabled={!valid}
              waiting={saving}
            >
              Save
            </WaitButton>
          )}
        </div>
      </div>
    );
  }
}

export default IdentityPhoto;
