import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SearchResponse from '../models/SearchResponse';
import { IRootState } from '../../../shared/reducers';
import 'react-table/react-table.css';

const StyledReactTable = styled(ReactTable)`
  .-pageSizeOptions {
    display: none;
  }
`;

interface LearnerSearchTableProps extends StateProps {
  alterPage: any;
  currentPage: number;
}

function LearnerSearchResultsTable(props: LearnerSearchTableProps) {
  const {
    searchResponse,
    resultStatus,
    alterPage,
    currentPage,
  } = props;
  const pageCount = Math.ceil(searchResponse.Count / 20);
  switch (resultStatus) {
    case 'Empty':
      return (
        <> </>
      );
    default:
      return (
        <StyledReactTable
          data={searchResponse.Results}
          columns={[
            {
              Header: 'ID',
              accessor: 'Id',
              minWidth: 200,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Full Name',
              accessor: 'FullName',
              minWidth: 300,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Email',
              accessor: 'Email',
              minWidth: 300,
              sortable: false,
              filterable: false,
            },
            {
              Header: 'Success Advisor',
              accessor: 'SuccessAdvisorFullName',
              minWidth: 200,
              sortable: false,
              filterable: false,
            },
            {
              Header: '',
              id: 'viewbutton',
              accessor: 'Id',
              getProps: () => ({ style: { textAlign: 'center' } }),
              Cell: (cellInfo) => <Link to={`/learners/${cellInfo.value}`}>View</Link>,
              minWidth: 100,
            },
          ]}
          manual
          defaultPageSize={20}
          onFetchData={(state) => {
            alterPage(state.page);
          }}
          loading={resultStatus === 'Loading'}
          className="-striped -highlight"
          pages={pageCount}
          page={currentPage}
        />
      );
  }
}

const mapStateToProps = ({ learnerSearchState }: IRootState) => ({
  resultStatus: learnerSearchState.resultStatus,
  searchResponse: learnerSearchState.searchResponse,
});

interface StateProps {
  resultStatus: 'Empty' | 'Loading'| 'Errored' | 'Loaded';
  searchResponse: SearchResponse;
}

export default connect(mapStateToProps)(LearnerSearchResultsTable);
