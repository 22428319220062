import { AnyAction } from 'redux';
import {
  IReadmissionPermissionState,
} from './readmission.model';
import {
  GET_READMISSION_PERMISSIONS_ERROR,
  GET_READMISSION_PERMISSIONS_PENDING,
  GET_READMISSION_PERMISSIONS_SUCCESS,
} from './readmission.constants';

const initialState: IReadmissionPermissionState = {
  permissionsData: {
    canApproveReadmissionRequest: false,
    canCancelReadmissionRequest: false,
    canCreateReadmissionRequest: false,
    canEditReadmissionRequest: false,
    canViewReadmissionRequest: false,
  },
  permissionsPending: true,
  error: null,
};

const readmissionState = (
  state: IReadmissionPermissionState = initialState,
  { type, payload }: AnyAction,
):
IReadmissionPermissionState => {
  switch (type) {
    case GET_READMISSION_PERMISSIONS_PENDING:
      return {
        ...state,
        permissionsPending: true,
      };
    case GET_READMISSION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsPending: false,
        permissionsData: payload,
      };
    case GET_READMISSION_PERMISSIONS_ERROR:
      return {
        ...state,
        permissionsPending: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default readmissionState;
