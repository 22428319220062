import { AnyAction } from 'redux';
import {
  ILearnerAEPDocumentsState,
} from './aep-documents.model';
import {
  GET_AEP_DOCUMENTS_SUCCESS,
  GET_AEP_DOCUMENTS_PENDING,
  GET_AEP_DOCUMENTS_ERROR,
  UPDATE_AEP_DOCUMENTS,
} from './aep-documents.constants';


const initialState: ILearnerAEPDocumentsState = {
  aepDocuments: [],
  optionsPending: false,
  error: null,
};

const aepDocumentsState = (
  state: ILearnerAEPDocumentsState = initialState,
  { type, payload }: AnyAction,
):
ILearnerAEPDocumentsState => {
  switch (type) {
    case GET_AEP_DOCUMENTS_PENDING:
      return {
        ...state,
        optionsPending: true,
      };
    case GET_AEP_DOCUMENTS_SUCCESS:
      return {
        ...state,
        aepDocuments: payload,
        optionsPending: false,
      };
    case GET_AEP_DOCUMENTS_ERROR:
      return {
        ...state,
        error: payload,
        aepDocuments: [],
        optionsPending: false,
      };
    case UPDATE_AEP_DOCUMENTS:
      return {
        ...state,
        aepDocuments: payload,
      };
    default:
      return state;
  }
};

export default aepDocumentsState;
