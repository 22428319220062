type AppErrorData = {
  code?: string;
  property?: string;
  status?: number | string;
};

interface AppErrorType {
  message: string;
  data?: AppErrorData;
}

export class AppError extends Error implements AppErrorType {
  data: AppErrorData | undefined;

  constructor(err: AppErrorType) {
    super(err.message);
    this.name = 'AppError';
    this.data = err.data;
  }

  static getError(err: any): AppErrorType {
    if (!err) {
      return { message: 'Unknown error', data: { code: 'UNKNOWN' } };
    }

    if (typeof err === 'object' && Array.isArray(err)) {
      return this.getError(err[0]);
    }
    if (typeof err === 'object') {
      const message = err.errorMessage || err.message || 'Unknown error';
      const code = err.errorCode || err.code || 'UNKNOWN';
      const property = err.propertyName;

      return { message, data: { code, property } };
    }

    return { message: err };
  }

  static fromError(err: any, status?: AppErrorData['status']) {
    if (err instanceof AppError) {
      return err;
    }
    const error = this.getError(err);

    return new AppError({
      ...error,
      data: { ...error.data, status },
    });
  }
}
