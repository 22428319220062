import { AnyAction } from 'redux';
import {
  IConfigurationState,
} from './configuration.model';
import {
  GET_CONFIGURATION_SUCCESS,
  GET_CONFIGURATION_ERROR,
  GET_CONFIGURATION_PENDING,
} from './configuration.constants';

const initialState: IConfigurationState = {
  configuration: {
    maxDeferralMonths: 0,
    daysBeforeNextPeriod: 0,
    disableScheduleLoaAfterXDays: 0,
    disableStartDateAfterXDays: 0,
  },
  configurationPending: true,
  error: null,
};

const configurationState = (
  state: IConfigurationState = initialState,
  { type, payload }: AnyAction,
):
IConfigurationState => {
  switch (type) {
    case GET_CONFIGURATION_PENDING:
      return {
        ...state,
        configurationPending: true,
      };
    case GET_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configurationPending: false,
        configuration: payload,
      };
    case GET_CONFIGURATION_ERROR:
      return {
        ...state,
        configurationPending: false,
        error: payload,
      };
    default:
      return state;
  }
};

export default configurationState;
