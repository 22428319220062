/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { CSSProperties } from 'styled-components';
import { IProgramEnrollmentFilesProps } from './program-enrollment-files.model';
import api from '../../../shared/api/adminUI.api';
import { FileLink, FilesLoadingSpinner, SpinnerContainer } from './styled-components';
import errorHandling from '../../helper-components/alert-component.component';

// eslint-disable-next-line max-len
const ProgramEnrollmentFiles: React.FunctionComponent<IProgramEnrollmentFilesProps> = (props: IProgramEnrollmentFilesProps) => {
  const {
    enrollmentAgreementFile,
    catalogFile,
    catalogAddendumFile,
    catalogVersion,
    learnerId,
    getBearerToken,
  } = props;

  const [pending, setPending] = useState(false);
  const emptyStyle: CSSProperties = {
    color: 'black',
    textDecoration: 'none',
    cursor: 'default',
  };

  const downloadFile = async (fileName: string) => {
    if (fileName) {
      try {
        setPending(true);

        const requestUrl = `/api/learner-files/learners/${learnerId}/files/${fileName}`;
        const response = await api.documentUpload.previewFile(
          getBearerToken,
          requestUrl,
        );
        if (!response.ok) throw response;

        const url = await response.json();

        window.open(url, '_blank');
        setPending(false);
      } catch (error) {
        errorHandling(error);
        setPending(false);
      }
    }
  };
  return (
    <> {pending ? (
      <SpinnerContainer>
        <FilesLoadingSpinner animation="border" />
      </SpinnerContainer>
    ) : (
      <span>
        <FileLink
          style={enrollmentAgreementFile ? {} : emptyStyle}
          onClick={() => downloadFile(enrollmentAgreementFile)}
        >
                 Agreement
        </FileLink>
        <span> / </span>
        <FileLink
          title={catalogVersion}
          style={catalogFile ? {} : emptyStyle}
          onClick={() => downloadFile(catalogFile)}
        >
                    Catalog ({(catalogVersion || '').split('.').slice(0, 2).join('.')})
        </FileLink>
        {catalogAddendumFile
        && (
        <span>
          <span> / </span>
          <FileLink
            onClick={() => downloadFile(catalogAddendumFile)}
          >
                    Addendum
          </FileLink>
        </span>
        )}
      </span>
    )}
    </>
  );
};

export default ProgramEnrollmentFiles;
