import React, { useState } from 'react';
import {
  Button, Modal, Col, Spinner,
} from 'react-bootstrap';
import {
  Form, FormRenderProps, AnyObject, Field,
} from 'react-final-form';
import { FormState } from 'final-form';
import {
  Error,
  renderSelectField,
  renderTextInput,
  ConditionalField,
  renderTextField,
} from '../../helper-components/form-components/form-filed-components/form-filed.components';
import { selectRequired } from '../../../utils/validate.utils';
import {
  CLOSE, SAVE,
} from '../../constants/common-constants';
import { FormRow } from '../styled-component';
import { ISelectOption } from '../../../models/SelectOptions';
import { IUploadedFile } from '../../../models/UploadedFile';

const FILE_NAME = 'fileName';
const CATEGORY = 'categorySelect';
const SUBCATEGORY = 'subcategory';
const OTHER_DOCUMENTS = 'other';
const COMMENT = 'comment';

interface IInitialFormValues {
  [FILE_NAME]: string;
  [CATEGORY]: ISelectOption;
  [SUBCATEGORY]: string | null;
  [COMMENT]: string | null;
}

export interface IEditingDialog {
  handleSave: (values: AnyObject) => void;
  handleClose: (showUploadModal: boolean) => void;
  categoryOptions: ISelectOption[];
  file: IUploadedFile;
}

const EditingDialog: React.FunctionComponent<IEditingDialog> = (
  props: IEditingDialog,
) => {
  const [pending, setPending] = useState(false);
  const {
    handleSave,
    handleClose,
    categoryOptions,
    file,
  } = props;
  const category = categoryOptions.find((_) => _.value === file.category) || {
    value: '-',
    label: '-',
  };
  const initialFormValues: IInitialFormValues = {
    [FILE_NAME]: file.displayFileName,
    [CATEGORY]: category,
    [SUBCATEGORY]: file.subCategory || null,
    [COMMENT]: file.comment || null,
  };

  return (
    <>
      <Modal
        show
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Edit file attributes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSave}
            keepDirtyOnReinitialize
            initialValues={initialFormValues}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            validate={(values) => {
              const errors: { [key: string]: string | string[] } = {};
              const categoryValue = values[CATEGORY].value;
              if (!values[FILE_NAME]) {
                errors[FILE_NAME] = 'Please, provide file name';
              }
              if (!values[SUBCATEGORY] && categoryValue === OTHER_DOCUMENTS) {
                errors[SUBCATEGORY] = 'Please, provide sub-category';
              }

              return errors;
            }}
            render={<T extends object>({
              handleSubmit,
              pristine,
              invalid,
              values,
              form,
            }: FormRenderProps<any> & FormState<T>): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <>
                  <FormRow>
                    <Col xs={4}>File Name</Col>
                    <Col xs={8}>
                      <Field
                        data-testid="displayName-field"
                        disabled={file.canEdit === false}
                        name={FILE_NAME}
                        render={renderTextInput}
                        maxLength={50}
                      />
                      <Error name={FILE_NAME} />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col xs={4}>Category</Col>
                    <Col xs={8}>
                      <Field
                        data-testid="category-field"
                        disabled={file.canEdit === false}
                        name={CATEGORY}
                        render={renderSelectField}
                        options={categoryOptions}
                        validate={selectRequired}
                      />
                    </Col>
                  </FormRow>
                  {values[CATEGORY].value === OTHER_DOCUMENTS && (
                  <FormRow>
                    <Col xs={4}>
                      <span>Sub-category</span>
                    </Col>
                    <Col xs={8}>
                      <ConditionalField
                        disabled={file.canEdit === false}
                        name={SUBCATEGORY}
                        changeValue={form.mutators.setValue}
                        render={renderTextInput}
                        maxLength={50}
                      />
                      <Error name={SUBCATEGORY} />
                    </Col>
                  </FormRow>
                  )}
                  <FormRow>
                    <Col xs={4}>Comment</Col>
                    <Col xs={8}>
                      <Field
                        data-testid="comment-field"
                        name={COMMENT}
                        render={renderTextField}
                        maxLength={500}
                      />
                      <Error name={COMMENT} />
                    </Col>
                  </FormRow>
                </>
                <Modal.Footer>
                  <Button
                    disabled={pristine || invalid || pending}
                    variant="primary"
                    onClick={() => {
                      setPending(true);
                      handleSubmit();
                    }}
                  >
                    {SAVE}
                    {pending && <Spinner size="sm" animation="border" />}
                  </Button>
                  <Button
                    disabled={pending}
                    variant="secondary"
                    onClick={() => handleClose(false)}
                  >
                    {CLOSE}
                  </Button>
                </Modal.Footer>
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditingDialog;
