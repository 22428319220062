import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import AuthInProgress from './auth-in-progress';
import AuthErrored from './auth-errored';
import AppProps from './app-props';
import config from '../../config.json';
import { AuthContext } from './auth-context';

interface AuthConfig {
  readonly tenantId: string;
  readonly clientId: string;
  readonly adminApiScope: string;
}

interface AuthState {
  readonly status: 'Loading' | 'RenewIFrame' | 'Authenticated' | 'Errored';
  readonly authConfig: AuthConfig;
  readonly msal?: PublicClientApplication;
  readonly errorMessage?: string;
  readonly userName: string;
}

const withAuth = (
  WrappedComponent: React.ComponentType<AppProps>,
): React.ComponentType => class extends React.Component<{}, AuthState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      status: 'Loading',
      userName: '',
      authConfig: config.authentication,
    };
  }

  componentDidMount() {
    const { authConfig } = this.state;
    this.handleAuth(authConfig);
  }

  private readonly handleAuth = (authConfig: AuthConfig): void => {
    const msal = new PublicClientApplication({
      auth: {
        authority: `https://login.microsoftonline.com/${authConfig.tenantId}`,
        clientId: authConfig.clientId,
        redirectUri: `${window.location.origin}/`,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
      },
    });

    this.setState((state) => ({
      ...state,
      msal,
    }));

    const loginRequest = {
      scopes: ['User.Read'],
    };

    const accounts = msal.getAllAccounts();
    if (accounts.length > 0) {
      msal.setActiveAccount(accounts[0]);
      this.setState((state) => ({
        ...state,
        userName: accounts[0].name!,
        status: 'Authenticated',
      }));
    }

    // handle auth redired/do all initial setup for msal
    msal.handleRedirectPromise().then(() => {
      // Check if user signed in
      const account = msal.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page
        msal.loginRedirect(loginRequest);
        this.setState((state) => ({
          ...state,
          status: 'Authenticated',
        }));
      }
    }).catch((err) => {
      this.setState((state) => ({
        ...state,
        status: 'Errored',
        errorMessage: err.errorMessage,
      }));
    });
  };

  private readonly getBearerToken = async (): Promise<string> => {
    const { msal, authConfig } = this.state;

    const loginRequest = {
      scopes: [authConfig.adminApiScope],
    };

    const accounts = msal!.getAllAccounts();
    if (accounts.length > 0) {
      try {
        const account = await msal!.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        return account.accessToken;
      } catch (error) {
        await msal!.acquireTokenRedirect(
          {
            scopes: [authConfig.adminApiScope],
          },
        );
        throw error;
      }
    }
    throw new Error('Not Authenticated');
  };

  private readonly logout = async (): Promise<string> => {
    const { msal } = this.state;
    await msal!.logoutRedirect({
      postLogoutRedirectUri: '/',
    });

    throw new Error('Not Authenticated');
  };

  render(): JSX.Element {
    const { status, errorMessage, userName } = this.state;


    switch (status) {
      case 'Loading':
        return <AuthInProgress />;
      case 'RenewIFrame':
        return <div>hidden renew iframe - not visible</div>;
      case 'Errored':
        return <AuthErrored message={errorMessage} />;
      case 'Authenticated':
        return (
          <AuthContext.Provider value={{ getBearerToken: this.getBearerToken }}>
            <WrappedComponent
              getBearerToken={this.getBearerToken}
              logOut={this.logout}
              SAName={userName}
            />
          </AuthContext.Provider>
        );
      default:
        return <div />;
    }
  }
};

export default withAuth;
