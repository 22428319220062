export default {
  languages: [
    { label: '(Afan) Oromo', value: '(Afan) Oromo' },
    { label: 'Abkhazian', value: 'Abkhazian' },
    { label: 'Afar', value: 'Afar' },
    { label: 'Afrikaans', value: 'Afrikaans' },
    { label: 'Albanian', value: 'Albanian' },
    { label: 'Amharic', value: 'Amharic' },
    { label: 'Arabic', value: 'Arabic' },
    { label: 'Armenian', value: 'Armenian' },
    { label: 'Assamese', value: 'Assamese' },
    { label: 'Aymara', value: 'Aymara' },
    { label: 'Azerbaijani', value: 'Azerbaijani' },
    { label: 'Bashkir', value: 'Bashkir' },
    { label: 'Basque', value: 'Basque' },
    { label: 'Bengali', value: 'Bengali' },
    { label: 'Bhutani', value: 'Bhutani' },
    { label: 'Bihari', value: 'Bihari' },
    { label: 'Bislama', value: 'Bislama' },
    { label: 'Breton', value: 'Breton' },
    { label: 'Bulgarian', value: 'Bulgarian' },
    { label: 'Burmese', value: 'Burmese' },
    { label: 'Byelorussian', value: 'Byelorussian' },
    { label: 'Cambodian', value: 'Cambodian' },
    { label: 'Catalan', value: 'Catalan' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'Corsican', value: 'Corsican' },
    { label: 'Croatian', value: 'Croatian' },
    { label: 'Czech', value: 'Czech' },
    { label: 'Danish', value: 'Danish' },
    { label: 'Dutch', value: 'Dutch' },
    { label: 'English', value: 'English' },
    { label: 'Esperanto', value: 'Esperanto' },
    { label: 'Estonian', value: 'Estonian' },
    { label: 'Faeroese', value: 'Faeroese' },
    { label: 'Fiji', value: 'Fiji' },
    { label: 'Finnish', value: 'Finnish' },
    { label: 'French', value: 'French' },
    { label: 'Frisian', value: 'Frisian' },
    { label: 'Galician', value: 'Galician' },
    { label: 'Georgian', value: 'Georgian' },
    { label: 'German', value: 'German' },
    { label: 'Greek', value: 'Greek' },
    { label: 'Greenlandic', value: 'Greenlandic' },
    { label: 'Guarani', value: 'Guarani' },
    { label: 'Gujarati', value: 'Gujarati' },
    { label: 'Hausa', value: 'Hausa' },
    { label: 'Hebrew', value: 'Hebrew' },
    { label: 'Hindi', value: 'Hindi' },
    { label: 'Hungarian', value: 'Hungarian' },
    { label: 'Icelandic', value: 'Icelandic' },
    { label: 'Igbo', value: 'Igbo' },
    { label: 'Indonesian', value: 'Indonesian' },
    { label: 'Interlingua', value: 'Interlingua' },
    { label: 'Interlingue', value: 'Interlingue' },
    { label: 'Inupiak', value: 'Inupiak' },
    { label: 'Inuktitut (Eskimo)', value: 'Inuktitut (Eskimo)' },
    { label: 'Irish', value: 'Irish' },
    { label: 'Italian', value: 'Italian' },
    { label: 'Japanese', value: 'Japanese' },
    { label: 'Javanese', value: 'Javanese' },
    { label: 'Kannada', value: 'Kannada' },
    { label: 'Kashmiri', value: 'Kashmiri' },
    { label: 'Kazakh', value: 'Kazakh' },
    { label: 'Kinyarwanda', value: 'Kinyarwanda' },
    { label: 'Kirghiz', value: 'Kirghiz' },
    { label: 'Kirundi', value: 'Kirundi' },
    { label: 'Korean', value: 'Korean' },
    { label: 'Kurdish', value: 'Kurdish' },
    { label: 'Laothian', value: 'Laothian' },
    { label: 'Latin', value: 'Latin' },
    { label: 'Latvian, Lettish', value: 'Latvian, Lettish' },
    { label: 'Lingala', value: 'Lingala' },
    { label: 'Lithuanian', value: 'Lithuanian' },
    { label: 'Macedonian', value: 'Macedonian' },
    { label: 'Malagasy', value: 'Malagasy' },
    { label: 'Malay', value: 'Malay' },
    { label: 'Malayalam', value: 'Malayalam' },
    { label: 'Maltese', value: 'Maltese' },
    { label: 'Maori', value: 'Maori' },
    { label: 'Marathi', value: 'Marathi' },
    { label: 'Moldavian', value: 'Moldavian' },
    { label: 'Mongolian', value: 'Mongolian' },
    { label: 'Nauru', value: 'Nauru' },
    { label: 'Nepali', value: 'Nepali' },
    { label: 'Norwegian', value: 'Norwegian' },
    { label: 'Occitan', value: 'Occitan' },
    { label: 'Oriya', value: 'Oriya' },
    { label: 'Pashto, Pushto', value: 'Pashto, Pushto' },
    { label: 'Persian', value: 'Persian' },
    { label: 'Polish', value: 'Polish' },
    { label: 'Portuguese', value: 'Portuguese' },
    { label: 'Punjabi', value: 'Punjabi' },
    { label: 'Quechua', value: 'Quechua' },
    { label: 'Rhaeto-Romance', value: 'Rhaeto-Romance' },
    { label: 'Romanian', value: 'Romanian' },
    { label: 'Russian', value: 'Russian' },
    { label: 'Samoan', value: 'Samoan' },
    { label: 'Sangro', value: 'Sangro' },
    { label: 'Sanskrit', value: 'Sanskrit' },
    { label: 'Scots Gaelic', value: 'Scots Gaelic' },
    { label: 'Serbian', value: 'Serbian' },
    { label: 'Serbo-Croatian', value: 'Serbo-Croatian' },
    { label: 'Sesotho', value: 'Sesotho' },
    { label: 'Setswana', value: 'Setswana' },
    { label: 'Shona', value: 'Shona' },
    { label: 'Sindhi', value: 'Sindhi' },
    { label: 'Singhalese', value: 'Singhalese' },
    { label: 'Siswati', value: 'Siswati' },
    { label: 'Slovak', value: 'Slovak' },
    { label: 'Slovenian', value: 'Slovenian' },
    { label: 'Somali', value: 'Somali' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Sudanese', value: 'Sudanese' },
    { label: 'Swahili', value: 'Swahili' },
    { label: 'Swedish', value: 'Swedish' },
    { label: 'Tagalog', value: 'Tagalog' },
    { label: 'Tajik', value: 'Tajik' },
    { label: 'Tamil', value: 'Tamil' },
    { label: 'Tatar', value: 'Tatar' },
    { label: 'Tegulu', value: 'Tegulu' },
    { label: 'Thai', value: 'Thai' },
    { label: 'Tibetan', value: 'Tibetan' },
    { label: 'Tigrinya', value: 'Tigrinya' },
    { label: 'Tonga', value: 'Tonga' },
    { label: 'Tsonga', value: 'Tsonga' },
    { label: 'Turkish', value: 'Turkish' },
    { label: 'Turkmen', value: 'Turkmen' },
    { label: 'Twi', value: 'Twi' },
    { label: 'Uigur', value: 'Uigur' },
    { label: 'Ukrainian', value: 'Ukrainian' },
    { label: 'Urdu', value: 'Urdu' },
    { label: 'Uzbek', value: 'Uzbek' },
    { label: 'Vietnamese', value: 'Vietnamese' },
    { label: 'Volapuk', value: 'Volapuk' },
    { label: 'Welch', value: 'Welch' },
    { label: 'Wolof', value: 'Wolof' },
    { label: 'Xhosa', value: 'Xhosa' },
    { label: 'Yiddish', value: 'Yiddish' },
    { label: 'Yoruba', value: 'Yoruba' },
    { label: 'Zhuang', value: 'Zhuang' },
    { label: 'Zulu', value: 'Zulu' },
    { label: 'Fulfulde', value: 'Fulfulde' },
    { label: 'Ibibio', value: 'Ibibio' },
    { label: 'Kanuri', value: 'Kanuri' },
    { label: 'Tiv', value: 'Tiv' },
    { label: 'Edo', value: 'Edo' },
    { label: 'Igala', value: 'Igala' },
    { label: 'Nupe', value: 'Nupe' },
    { label: 'Izon', value: 'Izon' },
    { label: 'Berom', value: 'Berom' },
    { label: 'Sundanese', value: 'Sundanese' },
    { label: 'Minangkabau', value: 'Minangkabau' },
    { label: 'Palembang', value: 'Palembang' },
    { label: 'Buginese', value: 'Buginese' },
    { label: 'Banjarese', value: 'Banjarese' },
    { label: 'Acehnese', value: 'Acehnese' },
    { label: 'Balinese', value: 'Balinese' },
    { label: 'Isan', value: 'Isan' },
    { label: 'Kam', value: 'Kam' },
    { label: 'Mueang', value: 'Mueang' },
    { label: 'Pak', value: 'Pak' },
    { label: 'Tai', value: 'Tai' },
    { label: 'Asante Twi', value: 'Asante Twi' },
    { label: 'Akuapem Twi', value: 'Akuapem Twi' },
    { label: 'Bono', value: 'Bono' },
    { label: 'Dagaare', value: 'Dagaare' },
    { label: 'Dagbani', value: 'Dagbani' },
    { label: 'Dangme', value: 'Dangme' },
    { label: 'Ewe', value: 'Ewe' },
    { label: 'Ga', value: 'Ga' },
    { label: 'Gonja', value: 'Gonja' },
    { label: 'Kasem', value: 'Kasem' },
    { label: 'Mampruli', value: 'Mampruli' },
    { label: 'Fante', value: 'Fante' },
    { label: 'Nzema', value: 'Nzema' },
    { label: 'Wasa', value: 'Wasa' },
    { label: 'Talensi', value: 'Talensi' },
    { label: 'Frafra', value: 'Frafra' },
    { label: 'Kusaal', value: 'Kusaal' },
    { label: 'Aklanon', value: 'Aklanon' },
    { label: 'Central Bikol', value: 'Central Bikol' },
    { label: 'Cebuano', value: 'Cebuano' },
    { label: 'Chavacano', value: 'Chavacano' },
    { label: 'Hiligaynon', value: 'Hiligaynon' },
    { label: 'Ibanag', value: 'Ibanag' },
    { label: 'IlocanoIvatan', value: 'IlocanoIvatan' },
    { label: 'Kapampangan', value: 'Kapampangan' },
    { label: 'Kinaray-a', value: 'Kinaray-a' },
    { label: 'Maguindanao', value: 'Maguindanao' },
    { label: 'MaranaoIranun', value: 'MaranaoIranun' },
    { label: 'Pangasinan', value: 'Pangasinan' },
    { label: 'Sambal', value: 'Sambal' },
    { label: 'Surigaonon', value: 'Surigaonon' },
    { label: 'Tausug', value: 'Tausug' },
    { label: 'Waray', value: 'Waray' },
    { label: 'Yakan', value: 'Yakan' },
    { label: 'Akan', value: 'Akan' },
    { label: 'Abron', value: 'Abron' },
    { label: 'Konkomba', value: 'Konkomba' },
    { label: 'Kusasi', value: 'Kusasi' },
    { label: 'Mossi', value: 'Mossi' },
    { label: 'Joruba', value: 'Joruba' },
    { label: 'Sehwi', value: 'Sehwi' },
    { label: 'Bissa', value: 'Bissa' },
  ],
};
