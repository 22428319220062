import React, { useState } from 'react';
import {
  Button, Row, Col, Form,
} from 'react-bootstrap';

import { FiEdit, FiSave } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import {
  EditButton, EditingButtonsRow, EditingCol, FieldRow,
} from './styled-component';
import TextField from './text-edit-component';

interface IProps {
  oldValue: string;
  canEdit: boolean;
  propertyName: string;
  fieldType: string;
  handleNewValue: (newValue: string) => Promise<void>;
}

const TextFieldEditor: React.FunctionComponent<IProps> = ({
  oldValue,
  canEdit,
  propertyName,
  fieldType,
  handleNewValue,
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setNewFieldValue] = useState(oldValue);
  const [validationError, setFormError] = useState<null | string>(null);

  const edit = () => {
    setEditing(true);
    setNewFieldValue(oldValue);
  };

  const cancel = () => {
    setEditing(false);
    setNewFieldValue(oldValue);
    setFormError(null);
  };

  const save = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    await handleNewValue(fieldValue);
    setEditing(false);
  };

  if (editing) {
    return (
      <>
        <Form onSubmit={save}>
          <Row>
            <EditingCol xs={9}>
              <TextField
                fieldValue={fieldValue}
                fieldType={fieldType}
                propertyName={propertyName}
                setFormError={setFormError}
                setNewFieldValue={setNewFieldValue}
                validationError={validationError}
              />
            </EditingCol>
            <Col xs={3}>
              <EditingButtonsRow>
                <Button
                  data-testid="field-submit"
                  variant="outline-secondary"
                  size="sm"
                  disabled={!!validationError}
                  type="submit"
                >
                  <FiSave />
                </Button>
                <Button variant="outline-secondary" size="sm" onClick={cancel}>
                  <GiCancel />
                </Button>
              </EditingButtonsRow>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
  return (
    <>
      <Row>
        <Col id={propertyName} xs={9}>
          <span> {fieldValue} </span>
        </Col>
        <Col xs={3}>
          <FieldRow>
            {canEdit && (
            <EditButton data-testid="field-submit" variant="outline-secondary" size="sm" onClick={edit}>
              <FiEdit />
            </EditButton>
            )}
          </FieldRow>
        </Col>
      </Row>
    </>
  );
};
export default TextFieldEditor;
