import React from 'react';
import styled from 'styled-components';
import { ICourseKey } from '../learner-grades.model';

interface CumulativeGpaErrorsProps {
  readonly errors: ICourseKey[] | null;
}

export default function CumulativeGpaErrors(props: CumulativeGpaErrorsProps): JSX.Element {
  const { errors } = props;
  if (!errors || !errors.length) {
    return <></>;
  }

  return <Errors>Missing courses: {errors.map((e) => `${e.CourseCode} ${e.StartDate}`).toString()}</Errors>;
}

const Errors = styled.span`
  color: red;
  padding: 0.2em;
  margin-left: 1.5em;
  font-weight: 300;
  font-size: 14px;
`;
