import React from 'react';
import { usePartnerCodesParams } from '../hooks/use-partner-codes-params';
import { Breadcrumb } from '../../../breadcrumb/breadcrumb';

type Props = {
  accountName: string | undefined;
};

export const PartnerCodesBreadcrumb = ({ accountName } : Props) => {
  const { partnerId } = usePartnerCodesParams();

  return (
    <Breadcrumb>
      <Breadcrumb.Item to="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item to="/partners">Partners</Breadcrumb.Item>
      <Breadcrumb.Item to={`/partners/${partnerId}`}>
        {accountName}
      </Breadcrumb.Item>
      <Breadcrumb.Item active>Code</Breadcrumb.Item>
    </Breadcrumb>
  );
};
