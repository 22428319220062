/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-indent */

import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { ILearnerAchievementArtifact } from '../../shared/actions/learner-profile.action';
import api from '../../shared/api/adminUI.api';
import errorHandling from '../helper-components/alert-component.component';

export const Artifacts: React.FC<{learnerId: string, getBearerToken: () => Promise<string>, artifacts?: ILearnerAchievementArtifact[]}> = (props: {learnerId: string, getBearerToken: () => Promise<string>, artifacts?: ILearnerAchievementArtifact[]}): JSX.Element => {
  const { artifacts, learnerId, getBearerToken } = props;
  return <>
  {
    (artifacts || []).map((a) => <Artifact learnerId={learnerId}
                                           getBearerToken={getBearerToken}
                                           data={a.data}
                                           artifactName={a.artifactName}
                                           artifactType={a.artifactType}
                                           key={a.artifactName} />)
  }</>;
};

export default Artifacts;

const Artifact: React.FC<{learnerId: string, artifactType:string, data:string, artifactName: string, getBearerToken: () => Promise<string>}> = (props: {learnerId: string, artifactType: string, data: string, artifactName: string, getBearerToken: () => Promise<string>}): JSX.Element => {
  const { artifactType, data, artifactName, learnerId, getBearerToken } = props;
  if (artifactType === 'link') {
    return <ArtifactLink type="button" size="sm" href={data}>{artifactName}</ArtifactLink>;
  }

  if (artifactType === 'file') {
    return <ArtifactFile learnerId={learnerId} data={data} artifactName={artifactName} getBearerToken={getBearerToken} />;
  }

  if (artifactType === 'text') {
    return <ArtifactText>{data}</ArtifactText>;
  }

  return <>{artifactType}</>;
};

const viewFile = async (
  filename: string,
  learnerId: string,
  getBearerToken: () => Promise<string>,
  setPending: (pending: boolean) => void,
) => {
  try {
    setPending(true);

    const previewFileRequestUrl = `/api/learner-files/learners/${learnerId}/files/${filename}`;
    const response = await api.documentUpload.previewFile(
      getBearerToken,
      previewFileRequestUrl,
    );
    if (!response.ok) throw response;

    const url = await response.json();

    window.open(url, '_blank');
    setPending(false);
  } catch (error) {
    errorHandling({ error });
    setPending(false);
  }
};

const ArtifactFile: React.FC<{learnerId: string, data: string, artifactName: string, getBearerToken: () => Promise<string>}> = (props: {learnerId: string, data: string, artifactName: string, getBearerToken: () => Promise<string>}): JSX.Element => {
  const { learnerId, data, artifactName, getBearerToken } = props;

  const [linking, setLinking] = useState<boolean>(false);

  return (
    <ArtifactLink
      type="button"
      size="sm"
      onClick={() => viewFile(data, learnerId, getBearerToken, setLinking)}
    >
      {artifactName}
      {linking && <Spinner size="sm" animation="border" />}
    </ArtifactLink>);
};

const ArtifactLink = styled(Button)`
  margin-right: 10px;
  width: auto;
`;

const ArtifactText = styled.span`
  margin-right: 10px;
  padding: 2px 4px;
  color: yellow;
  background-color: black;
  font-family: "Cascadia Code", "Andalé Mono", "Monaco", "Lucida Console", "monospace";
  border-radius: 3.2px;
`;
