import { AnyAction } from 'redux';
import {
  GET_LOA_SCHEDULER_DATA_ERROR,
  GET_LOA_SCHEDULER_DATA_PENDING,
  GET_LOA_SCHEDULER_DATA_SUCCESS,
  SET_LOA_SCHEDULER_DATA_PENDING,
  SET_LOA_SCHEDULER_DATA_SUCCESS,
  SET_LOA_SCHEDULER_DATA_ERROR,
  GET_SELECT_OPTIONS_PENDING,
  GET_SELECT_OPTIONS_ERROR,
  GET_SELECT_OPTIONS_SUCCESS,
} from './loa-scheduler.constants';
import {
  ILoaSchedulerData,
  ILoaSchedulerStateTypes,
} from './loa-scheduler.modal';

import {
  emptySelectOption,
  capitalize,
} from '../../../components/helper-components/form-components/form-filed-components/form-filed.components';

const initialState: ILoaSchedulerStateTypes = {
  loaSchedulerData: {
    startDate: null,
    returnDate: null,
    remaining: 0,
    scheduleId: '',
    comment: '',
    reason: emptySelectOption,
    type: emptySelectOption,
    isLoaScheduled: false,
  },
  optionsData: { types: [] },
  pendingDataSave: false,
  pending: false,
  optionPending: false,
  error: null,
};
const isObjectEmpty = (
  object: ILoaSchedulerData,
) => (object.scheduleId === null || object.id === null);


const loaSchedulerState = (
  state: ILoaSchedulerStateTypes = initialState,
  { type, payload }: AnyAction,
): ILoaSchedulerStateTypes => {
  switch (type) {
    case GET_LOA_SCHEDULER_DATA_PENDING:
      return {
        ...state,
        pending: true,
      };
    case GET_SELECT_OPTIONS_PENDING:
      return {
        ...state,
        optionPending: true,
      };
    case SET_LOA_SCHEDULER_DATA_PENDING:
      return {
        ...state,
        pendingDataSave: true,
      };
    case GET_SELECT_OPTIONS_SUCCESS:
      return {
        ...state,
        optionsData: payload,
        optionPending: false,
      };
    case GET_LOA_SCHEDULER_DATA_SUCCESS:
      return {
        ...state,
        loaSchedulerData: {
          ...payload,
          scheduleId: payload.id,
          type: payload.type ? { label: `${capitalize(payload.type)}`, value: payload.type }
            : emptySelectOption,
          reason: payload.reason ? { label: `${capitalize(payload.reason)}`, value: payload.reason }
            : emptySelectOption,
          isLoaScheduled: !isObjectEmpty(payload),
        },
        pending: false,
      };
    case SET_LOA_SCHEDULER_DATA_SUCCESS:
      return {
        ...state,
        loaSchedulerData: {
          ...state.loaSchedulerData,
          ...payload,
          scheduleId: payload.id,
          type: payload.type ? { label: `${capitalize(payload.type)}`, value: payload.type }
            : emptySelectOption,
          reason: payload.reason ? { label: `${capitalize(payload.reason)}`, value: payload.reason }
            : emptySelectOption,
          isLoaScheduled: !isObjectEmpty(payload),
        },
        pendingDataSave: false,
      };

    case SET_LOA_SCHEDULER_DATA_ERROR:
    case GET_LOA_SCHEDULER_DATA_ERROR:
    case GET_SELECT_OPTIONS_ERROR:
      return {
        ...state,
        error: payload,
        pendingDataSave: false,
        pending: false,
        optionPending: false,
      };
    default:
      return state;
  }
};

export default loaSchedulerState;
