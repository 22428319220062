/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventPropComment from './utils/event-prop-comment';
import EventPropStartDate from './utils/event-prop-start-date';

export const programEnrollmentDetailsSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Program Enrollment',
    },
    date: event.editedDate,
    editor: event.editor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventPropStartDate value={event.startDate} primary />
      <EventPropComment value={event.comment} />
    </Row>
  );
};
