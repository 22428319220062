import React from 'react';

export interface ContextProps {
  getBearerToken: () => Promise<string>;
  learnerId: string;
}

const AuthContext = React.createContext<ContextProps | null>(null);

export default AuthContext;
