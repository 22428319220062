import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import LearnerHistoryTable from './learner-history-component';
import api from '../../shared/api/adminUI.api';
import { IEvent } from './learner-history-model';
import errorHandling from '../helper-components/alert-component.component';

interface IState {
  histories:IEvent[];
  loading:boolean;
}

interface ILearnerHistoryProps {
  readonly getBearerToken: () => Promise<string>;
  readonly learnerId: string;
}

class LearnerHistory extends React.PureComponent<ILearnerHistoryProps, IState> {
  constructor(props: ILearnerHistoryProps) {
    super(props);
    this.state = {
      histories: [],
      loading: true,
    };
  }

  public componentDidMount(): void {
    const {
      learnerId,
      getBearerToken,
    } = this.props;

    this.getAllLearnerHistories(getBearerToken, learnerId);
  }

  private async getAllLearnerHistories(
    getBearerToken: () => Promise<string>,
    learnerId: string,
  ): Promise<void> {
    try {
      const response = await api.history.getLearnerHistories(learnerId, getBearerToken);
      if (!response.ok) throw await response;
      const histories = (await response.json()) as IEvent[];
      this.setState({ histories, loading: false });
    } catch (error) {
      errorHandling({ error });
    }
  }

  public render(): JSX.Element {
    const {
      histories,
      loading,
    } = this.state;

    return (
      <LearnerHistoryContainer>

        <LearnerHistoryTable
          events={histories}
          loadingText="Loading all learner history..."
          loading={loading}
        />

      </LearnerHistoryContainer>
    );
  }
}

export default LearnerHistory;

const LearnerHistoryContainer = styled(Container)`
font-family: "Open Sans";
text-align: left;
margin-bottom: 20px;
@media (min-width: 1200px){
  max-width: 1440px;
}
`;
