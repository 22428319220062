import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAuthContext } from '../../auth/auth-context';
import api from '../../../shared/api/adminUI.api';
import { PartnerInfo } from '../../../models/Partner';

type Options = {} & Omit<UseQueryOptions<PartnerInfo>, 'queryKey' | 'queryFn'>;

export const usePartner = (id: string, options: Options = {}) => {
  const { getBearerToken } = useAuthContext();

  return useQuery<PartnerInfo>({
    queryKey: ['partners', id],
    queryFn: () => api.partners.getPartner(id, getBearerToken),
    ...options,
  });
};
