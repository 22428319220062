import React from 'react';

import { Card, Row } from 'react-bootstrap';
import api from '../../shared/api/adminUI.api';
import IPartnersPermission from '../../models/PartnersPermissions';
import {
  AdminContentContainer,
  OptionCard,
  FirstRowTitle,
  RowTitle,
  CardLink,
  WelcomeRow,
  CardContainer,
  TransparentPlaceholderCard,
  CardNavLink,
} from './styled.components';
import { urls } from '../../config.json';

interface IDashboardCard {
  name: string;
  link?: string;
  url?: string;
}
interface IAdminPageProps {
  SAName: string;
  getBearerToken : () => Promise<string>;
}

interface IAdminPageState {
  user: string;
  partnersViewer: boolean;
}
const DashboardCard = (props: IDashboardCard) => {
  const { name, link, url } = props;
  return (
    <OptionCard>

      {link ? (
        <CardNavLink to={`/${link}`}>
          <Card.Body>
            <Card.Title>{name}</Card.Title>
          </Card.Body>
        </CardNavLink>
      ) : (
        <CardLink target="_blank" href={`${url}`}>
          <Card.Body>
            <Card.Title>{name}</Card.Title>
          </Card.Body>
        </CardLink>
      )}
    </OptionCard>

  );
};

export default class AdminLandingPage extends React.Component<IAdminPageProps, IAdminPageState> {
  constructor(props: IAdminPageProps) {
    super(props);

    const { getBearerToken } = this.props;
    this.state = {
      user: props.SAName,
      partnersViewer: false,
    };

    this.getPermission(getBearerToken);
  }

  public componentDidUpdate(prevProps: IAdminPageProps): void {
    const prevName = prevProps.SAName;
    const { SAName } = this.props;

    if (SAName !== prevName) {
      this.updateUserName(SAName);
    }
  }

  private getPermission = async (getBearerToken: () => Promise<string>) => {
    try {
      const perm = await api.partners.getPermissions(getBearerToken);

      this.updatePartnersViewerPermission(perm.PartnersViewer);
    } catch (err) {
      this.updatePartnersViewerPermission(false);
    }
  };

  private updatePartnersViewerPermission = (permission: boolean) => {
    this.setState({ partnersViewer: permission });
  };

  private updateUserName = (name: string) => {
    this.setState({ user: name });
  };

  render(): JSX.Element {
    const { user, partnersViewer } = this.state;
    return (
      <>
        <AdminContentContainer>
          <WelcomeRow>{`Welcome back ${user}!`}</WelcomeRow>
          <CardContainer>
            <FirstRowTitle>Teaching</FirstRowTitle>
            <DashboardCard name="Seminar Availability" link="seminar-availability" />
          </CardContainer>
          <CardContainer>
            <FirstRowTitle>Learner Records</FirstRowTitle>
            <DashboardCard name="Learner Search" link="learners" />
          </CardContainer>
          <RowTitle>Reports</RowTitle>
          <DashboardCard name="Partner learners" url={urls.partnerLearners} />
          <DashboardCard name="EOC survey" url={urls.eocSurvey} />
          <RowTitle>Services</RowTitle>
          <Row style={{ width: '100%' }}>
            <DashboardCard name="Chargebee" url={urls.chargebee} />
            <DashboardCard name="WorQ Queue" link="worq" />
          </Row>
          <RowTitle>System Configuration</RowTitle>
          <Row style={{ width: '100%' }}>
            <DashboardCard name="FX Rates" link="fxrates" />
            {partnersViewer && (<DashboardCard name="Manage Partners" link="partners" />) }
          </Row>

        </AdminContentContainer>
      </>
    );
  }
}
