import React from 'react';
import {
  Container,
} from 'react-bootstrap';
import moment from 'moment';
import {
  RowTitle,
  StripedTableWithCenteredFirstColumn,
} from './styled.components';
import { WithLoading } from '../helper-components/loading.component';
import { WithErrorHandling } from '../helper-components/error-handling.component';
import IError from '../../models/Error';
import { Breadcrumb } from '../breadcrumb/breadcrumb';

interface IFXRatePageProps {
  SAName: string;
  readonly getBearerToken: () => Promise<string>;
}

interface IFXRatesPageState {
  rates: IFXRate[];
  status: 'initial' | 'loading' | 'success' | 'error';
  errorRespone: IError | null;
}

interface IFXRate {
  rateId: string;
  currencyCode: string;
  fxRate: number;
  effectiveFrom: string;
}

export default class FXRatesLandingPage extends React.Component<IFXRatePageProps, IFXRatesPageState> {
  constructor(props: IFXRatePageProps) {
    super(props);
    const { getBearerToken } = props;

    this.state = {
      rates: [],
      status: 'initial',
      errorRespone: null,
    };

    this.getActualRates(getBearerToken);
  }

  private getActualRates = async (
    getBearerToken: () => Promise<string>,
  ) => {
    try {
      const url = '/api/learner-payments-api/fx-rates/current';
      const token = await getBearerToken();
      this.setState({
        status: 'loading',
      });

      const response = await fetch(url, {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });
      if (!response.ok) {
        throw await response;
      }

      const urlResponse = (await response.json()) as IFXRate[];

      this.setState({
        rates: urlResponse,
        status: 'success',
      });
    } catch (err) {
      this.setState({
        status: 'error',
        errorRespone: err as IError,
      });
      console.error('Error fetching rates', err);
    }
  };

  render(): JSX.Element {
    const { rates, errorRespone, status } = this.state;
    return (
      <>
        <Container>
          <Breadcrumb>
            <Breadcrumb.Item to="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>FX Rates</Breadcrumb.Item>
          </Breadcrumb>
          <RowTitle>FX Rates</RowTitle>
          <WithLoading
            loading={status === 'initial' || status === 'loading'}
            loadingText="Loading rates"
          >
            <WithErrorHandling error={errorRespone}>
              <StripedTableWithCenteredFirstColumn>
                <tr>
                  <th>Currency Code</th>
                  <th>FX Rate (per $1.00)</th>
                  <th>Effective From</th>
                  <th>{ }</th>
                </tr>
                {rates.map((rate) => (
                  <tr>
                    <td>{rate.currencyCode}</td>
                    <td>{rate.fxRate}</td>
                    <td>{moment(rate.effectiveFrom).format('YYYY-MM-DD')}</td>
                    <td>
                      <a href={`/fxrates/${rate.currencyCode}`}>Manage</a>
                    </td>
                  </tr>
                ))}
              </StripedTableWithCenteredFirstColumn>
            </WithErrorHandling>
          </WithLoading>
        </Container>
      </>
    );
  }
}
