/* eslint-disable max-len */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import React from 'react';
import {
  IApplicantInfo,
  IIdentityInfo,
  AdminSubmitIdentityId,
  AdminSubmitIdentityIdV2,
  DocumentInfo,
} from '../admissions.model';
import { ReviewIconWithLink } from './application-review-icon';
import { StyledWrapper, StyledIdentityBlock } from './styled-components';
import IdentityId from './application-identity-id';
import RejectionComment from './rejection-comment';
import IdentityPhoto from './application-identity-photo';
import { featureFlags } from '../../../config.json';

interface IdentityProps {
  readonly identityInfo: IIdentityInfo | null;
  readonly applicantId: string;
  readonly applicationStatus: string;
  readonly saveIdentityIdV2: (
    applicantId: string,
    identityData: AdminSubmitIdentityIdV2
  ) => Promise<void>;
  readonly saveIdentityId: (
    applicantId: string,
    foo: AdminSubmitIdentityId
  ) => Promise<void>;
  readonly saveIdentityPhoto: (
    applicantId: string,
    foo: DocumentInfo
  ) => Promise<void>;
  readonly getBearerToken: () => Promise<string>;
}

const Identity: React.FC<IdentityProps> = (
  props: IdentityProps,
): JSX.Element | null => {
  const {
    identityInfo,
    applicantId,
    saveIdentityId,
    saveIdentityIdV2,
    saveIdentityPhoto,
    applicationStatus,
    getBearerToken,
  } = props;
  if (!identityInfo) {
    return null;
  }
  const { officialName, idDocument, idUrl, process, photoUrl, personalPhoto } = identityInfo;

  return (
    <>
      <IdentityId
        applicantId={applicantId}
        applicationStatus={applicationStatus}
        officialName={officialName}
        idDocument={idDocument}
        url={idUrl}
        status={process.idReviewStatus}
        reviewer={process.idReviewer}
        saveIdentityIdV2={saveIdentityIdV2}
        saveIdentityId={saveIdentityId}
        getBearerToken={getBearerToken}
      />
      {process.idRejectionReason && (
        <RejectionComment comment={process.idRejectionReason} />
      )}
      {featureFlags.useIdentityService === 'false' ? (
        <ReviewIconWithLink
          text="Selfie"
          url={photoUrl}
          status={process.photoReviewStatus}
          reviewer={process.photoReviewer}
        />
      ) : (
        <IdentityPhoto
          applicantId={applicantId}
          applicationStatus={applicationStatus}
          photoDocument={personalPhoto}
          url={photoUrl}
          status={process.photoReviewStatus}
          reviewer={process.photoReviewer}
          saveIdentityPhoto={saveIdentityPhoto}
          getBearerToken={getBearerToken}
        />
      )}
      <ReviewIconWithLink
        text="Match selfie with ID"
        status={process.photoVerificationStatus}
        reviewer={process.photoVerifier}
      />
      {process.photoRejectionReason && (
        <RejectionComment comment={process.photoRejectionReason} />
      )}
    </>
  );
};

export interface ApplicationInfoProps {
  readonly applicantId: string;
  readonly applicantInfo: IApplicantInfo;
  readonly identityInfo: IIdentityInfo | null;
  readonly loading: boolean;
  readonly saveIdentityId: (
    applicantId: string,
    foo: AdminSubmitIdentityId
  ) => Promise<void>;
  readonly saveIdentityIdV2: (
    applicantId: string,
    identityData: AdminSubmitIdentityIdV2
  ) => Promise<void>;
  readonly saveIdentityPhoto: (
    applicantId: string,
    identityData: DocumentInfo
  ) => Promise<void>;
  readonly getBearerToken: () => Promise<string>;
}

class ApplicationInfo extends React.Component<ApplicationInfoProps> {
  static renderDocument(text: string, url: string) {
    return url ? (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ marginRight: 5 }}
      >
        {text}
      </a>
    ) : null;
  }

  static renderPriorEducation(
    priorEducationDoc1: string,
    priorEducationDoc2: string,
  ) {
    if (!priorEducationDoc1 && !priorEducationDoc2) {
      return <></>;
    }

    return (
      <StyledWrapper>
        <h3>Prior Education</h3>
        {ApplicationInfo.renderDocument('Prior Education', priorEducationDoc1)}
        {ApplicationInfo.renderDocument('Prior Education', priorEducationDoc2)}
      </StyledWrapper>
    );
  }

  static renderEnglish(
    englishEvidenceOption: string,
    englishCertificate: string,
    individualEvaluation: string,
    efsetScore: number | null,
  ) {
    if (
      !englishEvidenceOption
      && !englishCertificate
      && !individualEvaluation
      && efsetScore === null
    ) {
      return <></>;
    }

    return (
      <StyledWrapper>
        <h3>English Proficiency</h3>
        {englishEvidenceOption !== null && (
          <div>Evidence Option: {englishEvidenceOption}</div>
        )}
        {ApplicationInfo.renderDocument(
          'English Certificate',
          englishCertificate,
        )}
        {ApplicationInfo.renderDocument(
          'Individual Evaluation',
          individualEvaluation,
        )}
        {efsetScore !== null && <div>Efset Score: {efsetScore}</div>}
      </StyledWrapper>
    );
  }

  render() {
    const { loading, saveIdentityId, saveIdentityIdV2, saveIdentityPhoto, getBearerToken } = this.props;
    if (loading) {
      return null;
    }

    const { applicantInfo, identityInfo, applicantId } = this.props;
    const {
      PriorEducationDoc1,
      PriorEducationDoc2,
      EnglishCertificate,
      EfsetScore,
      EnglishIndividualEvaluation,
      EnglishEvidenceOption,
    } = applicantInfo;

    return (
      <StyledWrapper>
        <StyledIdentityBlock>
          <h3>Verification Steps</h3>
          <Identity
            identityInfo={identityInfo}
            applicantId={applicantId}
            applicationStatus={applicantInfo.Status}
            saveIdentityIdV2={saveIdentityIdV2}
            saveIdentityId={saveIdentityId}
            saveIdentityPhoto={saveIdentityPhoto}
            getBearerToken={getBearerToken}
          />
        </StyledIdentityBlock>

        <StyledIdentityBlock>
          {ApplicationInfo.renderPriorEducation(
            PriorEducationDoc1,
            PriorEducationDoc2,
          )}
        </StyledIdentityBlock>

        <StyledIdentityBlock>
          {ApplicationInfo.renderEnglish(
            EnglishEvidenceOption,
            EnglishCertificate,
            EnglishIndividualEvaluation,
            EfsetScore,
          )}
        </StyledIdentityBlock>
      </StyledWrapper>
    );
  }
}

export default ApplicationInfo;
