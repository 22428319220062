
import { sortBy } from 'lodash';
import { IOption } from '../../models/SelectOptions';
import { IUploadedFile } from '../../models/UploadedFile';
import api, { LEARNER_FILE_TYPE, ADMISSION_FILE_TYPE, APPLICANT_FILE_TYPE } from '../../shared/api/adminUI.api';
import { IUpdateAepDocumentsRequest } from './learner-files.model';

const addFileType = (
  fileType: string,
  fileArray: IUploadedFile[],
) => fileArray.map((learnerFile: IUploadedFile) => ({
  ...learnerFile,
  fileType,
}));

export const getLearnerFilesCategoryOptions = async (
  getBearerToken: () => Promise<string>,
): Promise<IOption[]> => {
  const response = await api.documentUpload.getLearnerFilesCategoryOptions(getBearerToken);

  if (!response.ok) throw response;

  const learnerFilesCategoryOptions = await response.json() as IOption[];

  return learnerFilesCategoryOptions;
};

const getApplicantFilesCategoryOptions = async (
  getBearerToken: () => Promise<string>,
): Promise<IOption[]> => {
  const response = await api.documentUpload.getApplicantFilesCategoryOptions(getBearerToken);

  if (!response.ok) throw response;

  const applicantFilesCategoryOptions = await response.json() as IOption[];

  return applicantFilesCategoryOptions;
};

export const getApplicantFiles = async (
  learnerId: string,
  getBearerToken: () => Promise<string>,
): Promise<IUploadedFile[]> => {
  const response = await api.documentUpload.getAdmissionsFiles(learnerId, getBearerToken);

  if (!response.ok) throw response;

  const admissionFiles = await response.json() as IUploadedFile[];
  const admissionFilesWithType = addFileType(APPLICANT_FILE_TYPE, admissionFiles);
  return admissionFilesWithType;
};


export const getAdmissionsFiles = async (
  learnerId: string,
  getBearerToken: () => Promise<string>,
): Promise<IUploadedFile[]> => {
  const response = await api.documentUpload.getAdmissionsFiles(learnerId, getBearerToken);

  if (!response.ok) throw response;

  const admissionFiles = await response.json() as IUploadedFile[];
  const admissionFilesWithType = addFileType(ADMISSION_FILE_TYPE, admissionFiles);
  return admissionFilesWithType;
};

export const getLearnerFiles = async (
  learnerId: string,
  getBearerToken: () => Promise<string>,
): Promise<IUploadedFile[]> => {
  const response = await api.documentUpload.getLearnerFiles(learnerId, getBearerToken);

  if (!response.ok) throw await response;

  const learnerFiles = await response.json() as IUploadedFile[];

  const learnerFilesWithType = addFileType(LEARNER_FILE_TYPE, learnerFiles);

  return learnerFilesWithType;
};

export const getCategoryOptions = async (
  isAdmission: boolean,
  getBearerToken: () => Promise<string>,
): Promise<IOption[]> => {
  if (isAdmission) {
    const applicantFilesCategoryOptions = await getApplicantFilesCategoryOptions(getBearerToken);
    return applicantFilesCategoryOptions;
  }
  const learnerFilesCategoryOptions = await getLearnerFilesCategoryOptions(getBearerToken);
  return learnerFilesCategoryOptions;
};

export const sortFilesByUploadDate = (fileArray: IUploadedFile[]) => sortBy(
  fileArray, (file: IUploadedFile) => new Date(file.uploadDate),
).reverse();

export const createAcceptDocumetsModel = (acceptedDocuments: string[]) : IUpdateAepDocumentsRequest => ({ aepDocuments: acceptedDocuments.map((documentName) => ({ name: documentName, status: 'ok' })) });
