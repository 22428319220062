/* eslint-disable no-shadow */
import React from 'react';
import {
  Col,
  Row,
  Form,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import AuthProps from '../auth/auth-props';
import {
  SearchContainer,
  SearchButton,
  AdvanceSearchHeader,
  FilterSelect,
  SpacedLabel,
  ResultsRow,
  FlexGroup,
  FlexCol,
} from './styled-components';
import FilterValues from './models/FilterValues';
import LearnerSearchResultsTable from './components/learner-search-results-table';
import { loadFilterValues, getSearchResults } from '../../shared/reducers/learner-search.reducer';
import { IRootState } from '../../shared/reducers';


interface SearchState {
  learnerName: string;
  successAdvisorSelections: SelectOption[];
  productCodeSelections: SelectOption[];
  countriesOfResidenceSelections: SelectOption[];
  genderSelections: SelectOption[];
  currentPage: number;
  sortBySelection: SelectOption;
  searchString: string;
}

interface SelectOption {
  label: string;
  value: string;
}

interface LearnerSearchProps extends AuthProps, DispatchProps {
  filterValues: FilterValues;
  filterStatus: 'Loading' | 'Errored' | 'Loaded';
}

interface SortType {
  label: string;
  searchSubstring: string;
}

const SortTypes: SortType[] = [
  { label: 'Name A-Z', searchSubstring: 'FullName asc' },
  { label: 'Name Z-A', searchSubstring: 'FullName desc' },
  { label: 'Success Advisor A-Z', searchSubstring: 'SuccessAdvisorFullName asc' },
  { label: 'Gender A-Z', searchSubstring: 'Gender asc' },
  { label: 'Start Date oldest first', searchSubstring: 'StartDate asc' },
  { label: 'Start Date newest first', searchSubstring: 'StartDate desc' },
];

class LearnerSearch extends React.PureComponent<LearnerSearchProps, SearchState> {
  constructor(props: LearnerSearchProps) {
    super(props);

    const { getBearerToken, loadFilterValues } = this.props;

    this.state = {
      learnerName: '',
      successAdvisorSelections: [],
      productCodeSelections: [],
      countriesOfResidenceSelections: [],
      genderSelections: [],
      currentPage: 0,
      sortBySelection: {
        label: SortTypes[0].label,
        value: SortTypes[0].searchSubstring,
      },
      searchString: '?search=*&$orderby=FullName asc',
    };

    this.performSearch = this.performSearch.bind(this);
    this.setSearchString = this.setSearchString.bind(this);
    this.handleSuccessAdvisorChange = this.handleSuccessAdvisorChange.bind(this);
    this.handleLearnerNameChange = this.handleLearnerNameChange.bind(this);
    this.alterPage = this.alterPage.bind(this);
    loadFilterValues(getBearerToken);
  }

  setSearchString = async (): Promise<void> => {
    const {
      productCodeSelections, learnerName, successAdvisorSelections, sortBySelection,
      countriesOfResidenceSelections, genderSelections,
    } = this.state;

    let searchString = '?search=';

    // search
    if (learnerName !== '') {
      searchString += `FullName:/.*${learnerName}.*/&queryType=full`;
    } else {
      searchString += '*';
    }

    // filter
    const filters:string[] = [];
    if (successAdvisorSelections && successAdvisorSelections.length !== 0) {
      const filterString = `search.in(SuccessAdvisorEmail, '${successAdvisorSelections.map((x) => x.value).join(' ')}')`;
      filters.push(filterString);
    }
    if (productCodeSelections && productCodeSelections.length !== 0) {
      const filterString = `search.in(ProductCode, '${productCodeSelections.map((x) => x.value).join(' ')}')`;
      filters.push(filterString);
    }
    if (countriesOfResidenceSelections && countriesOfResidenceSelections.length !== 0) {
      const filterString = `search.in(CountryOfResidence, '${countriesOfResidenceSelections.map((x) => x.value).join(' ')}')`;
      filters.push(filterString);
    }
    if (genderSelections && genderSelections.length !== 0) {
      const filterString = `search.in(Gender, '${genderSelections.map((x) => x.value).join(' ')}')`;
      filters.push(filterString);
    }
    searchString += filters.length > 0 ? `&$filter=${filters.join(' and ')}` : '';

    // sort
    searchString += `&$orderby=${sortBySelection.value}`;

    this.setState({ searchString });
  };

  performSearch = async (): Promise<void> => {
    const { searchString, currentPage } = this.state;
    const { getSearchResults, getBearerToken } = this.props;

    getSearchResults(getBearerToken, `${searchString}&$top=20&$skip=${currentPage * 20}&$count=true`);
  };

  handleSearchClick = () => {
    this.setState({ currentPage: 0 }, this.performSearch);
  };

  handleSuccessAdvisorChange = (successAdvisorSelections: SelectOption[]) => {
    this.setState({ successAdvisorSelections }, this.setSearchString);
  };

  handleProductCodeChange = (productCodeSelections : SelectOption[]) => {
    this.setState({ productCodeSelections }, this.setSearchString);
  };

  handleCountryOfResidenceChange = (countriesOfResidenceSelections : SelectOption[]) => {
    this.setState({ countriesOfResidenceSelections }, this.setSearchString);
  };

  handleGenderChange = (genderSelections : SelectOption[]) => {
    this.setState({ genderSelections }, this.setSearchString);
  };

  handleLearnerNameChange = (event:any) => {
    this.setState({ learnerName: event.target.value }, this.setSearchString);
  };

  handleSortByChange = (sortBySelection: SelectOption) => {
    this.setState({ sortBySelection }, this.setSearchString);
  };

  alterPage = (pageNumber:number) => {
    const { currentPage } = this.state;
    if (pageNumber !== currentPage) {
      this.setState({ currentPage: pageNumber }, this.performSearch);
    }
  };

  render(): JSX.Element {
    const { filterValues, filterStatus } = this.props;
    const {
      productCodeSelections,
      successAdvisorSelections,
      currentPage,
      sortBySelection,
      countriesOfResidenceSelections,
      genderSelections,
    } = this.state;

    switch (filterStatus) {
      case 'Loaded':
        return (
          <SearchContainer>
            <Row>
              <Col xs={12}>
                <AdvanceSearchHeader>Advanced Search</AdvanceSearchHeader>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form>
                  <FlexGroup>
                    <SpacedLabel>
                      Learner Name:
                    </SpacedLabel>
                    <Form.Control
                      placeholder="Any"
                      type="text"
                      onChange={this.handleLearnerNameChange}
                    />
                  </FlexGroup>
                  <FlexGroup>
                    <SpacedLabel>
                      Success Advisor:
                    </SpacedLabel>
                    <FilterSelect
                      placeholder="Any"
                      value={successAdvisorSelections}
                      options={filterValues.successAdvisors.map((x) => ({
                        value: x.email,
                        label: x.fullName,
                      }))}
                      onChange={this.handleSuccessAdvisorChange}
                      isMulti
                    />
                  </FlexGroup>
                  <FlexGroup>
                    <SpacedLabel>
                      Program:
                    </SpacedLabel>
                    <FilterSelect
                      placeholder="Any"
                      value={productCodeSelections}
                      options={filterValues.productCodes.map((x) => ({
                        value: x,
                        label: x,
                      }))}
                      onChange={this.handleProductCodeChange}
                      isMulti
                    />
                  </FlexGroup>
                  <FlexGroup>
                    <SpacedLabel>
                      Country of Residence:
                    </SpacedLabel>
                    <FilterSelect
                      placeholder="Any"
                      value={countriesOfResidenceSelections}
                      options={filterValues.countriesOfResidence.map((x) => ({
                        value: x.iso,
                        label: x.name,
                      }))}
                      onChange={this.handleCountryOfResidenceChange}
                      isMulti
                    />
                  </FlexGroup>
                  <FlexGroup>
                    <SpacedLabel>
                      Gender:
                    </SpacedLabel>
                    <FilterSelect
                      placeholder="Any"
                      value={genderSelections}
                      options={filterValues.genders.map((x) => ({
                        value: x,
                        label: x,
                      }))}
                      onChange={this.handleGenderChange}
                      isMulti
                    />
                  </FlexGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <FlexCol xs={6}>
                <SpacedLabel>
                  Sort By:
                </SpacedLabel>
                <FilterSelect
                  placeholder="Any"
                  value={sortBySelection}
                  options={SortTypes.map((x) => ({
                    value: x.searchSubstring,
                    label: x.label,
                  }))}
                  onChange={this.handleSortByChange}
                />
              </FlexCol>
              <Col xs={2}>
                <SearchButton variant="info" onClick={this.handleSearchClick}>Search</SearchButton>
              </Col>
            </Row>
            <ResultsRow>
              <LearnerSearchResultsTable alterPage={this.alterPage} currentPage={currentPage} />
            </ResultsRow>
          </SearchContainer>
        );
      default:
        return (
          <SearchContainer>
            <Row>
              <Col xs={12}>
                <AdvanceSearchHeader>Advanced Search</AdvanceSearchHeader>
                <div> Loading... </div>
              </Col>
            </Row>
          </SearchContainer>
        );
    }
  }
}

const mapStateToProps = ({ learnerSearchState }: IRootState) => ({
  filterValues: learnerSearchState.filterValues,
  filterStatus: learnerSearchState.filterStatus,
});

const mapDispatchToProps = { loadFilterValues, getSearchResults };

interface DispatchProps {
  loadFilterValues: (getBearerToken: () => Promise<string>) => Promise<any>;
  getSearchResults: (getBearerToken: () => Promise<string>, searchString: string) => Promise<any>;
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnerSearch);
