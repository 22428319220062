export const COMMENT_ID = 'comment';
export const COMMENT = 'Comment';
export const MBA = 'MBA';
export const BBA = 'BBA';
export const INDIVIDUAL_COURSE = 'Individual Course';
export const CERTIFICATE = 'Certificate';
export const SUBMIT = 'Submit';
export const CLOSE = 'Close';
export const UPLOAD = 'Upload';
export const SAVE_CHANGES = 'Save Changes';
export const SAVE = 'Save';
export const WARNING = 'Warning';
export const YES = 'Yes';
export const CANCEL = 'Cancel';
export const CHECKBOX = 'checkbox';
export const RADIO = 'radio';
export const PROGRAM = 'Program';
export const PROGRAM_ID = 'program';
export const InternalProvider = 'Nexford';
export const APPLICANT_STATUS = {
  ENROLLING: 'Enrolling',
  ENROLLED: 'Enrolled',
  SUBMITTED: 'Submitted',
  REGISTRATION_INITIATED: 'Registration_Initiated',
  REGISTRATION: 'Registration',
};
