import React from 'react';

interface GradeGPAProps {
  readonly gpa: number | string | null;
}

export default function GradeGPA(props: GradeGPAProps): JSX.Element {
  const { gpa } = props;
  if (!gpa && gpa !== 0) {
    return <>-</>;
  }

  return <>{gpa}</>;
}
