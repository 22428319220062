/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventProp from './utils/event-prop';

export const learnerProfileLearnerEmailUpdatedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Learner Profile',
      eventName: 'Email Updated',
    },
    date: event.updatedDateTime,
    editor: event.actor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventProp name="Email" value={event.email} primary />
    </Row>
  );
};
