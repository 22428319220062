/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { IEventType } from './learner-history-model';

const EventType: React.FunctionComponent<{ type: IEventType }> = (
  props: { type: IEventType },
) => {
  const {
    category,
    eventName,
  } = props.type;

  return (
    <>
      <Row><CatagoryCol>{category}</CatagoryCol></Row>
      <Row><Col><StyledName>{eventName}</StyledName></Col></Row>
    </>
  );
};

export default EventType;

const CatagoryCol = styled(Col)`
  font-size: 16px;
  font-weight: 600;
  padding-top: 4px;
`;

const StyledName = styled.div`
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 4px;
`;
