/* eslint-disable react/jsx-props-no-spreading,max-len */
import React from 'react';
import {
  Row, Col, Navbar, Button,
} from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  AdminNavBar,
  AdminNavBarBrand,
  AdminStyledContainer,
  GlobalStyles,
  InstructionsDiv,
  MainContentContainer,
} from './styled-components';
import withAuth from '../components/auth';
import LearnerDashboard from '../components/learner-dashboard';
import LearnerSearch from '../components/learner-search';
import LearnerReference from '../components/learner-reference/learner-reference';

import QuickSearch from '../components/quick-search';
import WorQ from '../components/worq';
import AppProps from '../components/auth/app-props';
import AdminLandingPage from '../components/admin-landing-page/admin-landing-page';
import Sidebar from '../components/admin-landing-page/components/Sidebar/Sidebar';
import Admissions from '../components/admissions/admissions';
import AdmissionsOriginalInfo from '../components/admissions/original-info/admissions-original';
import AdmissionDocument from '../components/admissions/documents/admission-document';
import AuthContext from './authProvider';
import CommunicationsRecent from '../components/communications/communications-recent';
import logo from '../assets/images/logo.svg';
import Transcript from '../components/transcript-page/transcript';
import { NationalityOptionsProvider } from '../shared/providers/nationalityOptionsProvider';
import SeminarAvailability from '../components/seminar-availability';
import LearnerRegistration from '../components/learner-registrations/learner-registrations';
import FXRatesLandingPage from '../components/fx-rates/fx-rates-page';
import FXRatePage from '../components/fx-rates/fx-rate-page';
import LearnerPrivacySettingsPage from '../components/learner-privacy-settings/learner-privacy-settings';
import { PartnerAgreements } from '../components/manage-partners/partner-agreements/partner-agreements';
import { PartnerPage } from '../components/manage-partners/partners-page/partners-page';
import { PartnerDetails } from '../components/manage-partners/partner-details/partner-details';
import { PartnerCodes } from '../components/manage-partners/partner-codes/partner-codes';

interface LearnerPerformanceWithIdProps {
  getBearerToken: () => Promise<string>;
  logOut: () => void;
}

interface ParamTypes {
  learnerId: string;
}

function LearnerPerformanceWithId(props: LearnerPerformanceWithIdProps) {
  const { learnerId } = useParams<ParamTypes>();
  const { getBearerToken } = props;
  return (
    <AuthContext.Provider
      value={{
        learnerId,
        getBearerToken,
      }}
    >
      <LearnerDashboard getBearerToken={getBearerToken} learnerId={learnerId} />
    </AuthContext.Provider>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FunctionComponent<AppProps> = (props) => {
  const { logOut, getBearerToken, SAName } = props;

  // @ts-ignore
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <GlobalStyles />
          <AdminNavBar>
            <AdminNavBarBrand>
              <img
                src={logo}
                width="60"
                height="60"
                alt="Nexford University Logo"
              />
            </AdminNavBarBrand>
            <Navbar.Collapse className="justify-content-end">
              <Button variant="dark" onClick={logOut}>
                Logout
              </Button>
            </Navbar.Collapse>
          </AdminNavBar>
          <Sidebar width={300} getBearerToken={getBearerToken} />
          <MainContentContainer id="page-wrap">
            <NationalityOptionsProvider getBearerToken={getBearerToken}>
              <Switch>
                <Route exact path="/">
                  <AdminLandingPage
                    SAName={SAName}
                    getBearerToken={getBearerToken}
                  />
                </Route>
                <Route path="/seminar-availability">
                  <SeminarAvailability getBearerToken={getBearerToken} />
                </Route>
                <Route
                  exact
                  path="/learners/:learnerId/transcript"
                  render={(transcriptProps) => (
                    <Transcript
                      getBearerToken={getBearerToken}
                      {...transcriptProps}
                    />
                  )}
                />
                <Route
                  exact
                  path="/learners/:learnerId/privacy-settings"
                  render={() => (
                    <LearnerPrivacySettingsPage
                      getBearerToken={getBearerToken}
                    />
                  )}
                />
                <Route path="/learners/:learnerId">
                  <LearnerPerformanceWithId
                    getBearerToken={getBearerToken}
                    logOut={logOut}
                  />
                </Route>
                <Route path="/learnersearch">
                  <LearnerSearch getBearerToken={getBearerToken} />
                </Route>
                <Route path="/credentials">
                  <LearnerReference getBearerToken={getBearerToken} />
                </Route>
                <Route path="/communications/recent">
                  <CommunicationsRecent getBearerToken={getBearerToken} />
                </Route>
                <Route
                  path="/applicants/:applicantId/documents/*"
                  render={(admissionDocumentProps) => (
                    <AdmissionDocument
                      getBearerToken={getBearerToken}
                      {...admissionDocumentProps}
                    />
                  )}
                />
                <Route
                  exact
                  path="/applicants/original-info/:applicantId"
                  render={(admissionsProps) => (
                    <AdmissionsOriginalInfo
                      getBearerToken={getBearerToken}
                      {...admissionsProps}
                    />
                  )}
                />
                <Route
                  path="/applicants/:applicantId"
                  render={(admissionsProps) => (
                    <Admissions
                      getBearerToken={getBearerToken}
                      {...admissionsProps}
                    />
                  )}
                />
                <Route
                  path="/learner-registration/:applicantId"
                  render={() => (
                    <LearnerRegistration getBearerToken={getBearerToken} />
                  )}
                />
                <Route path="/worq">
                  <WorQ getBearerToken={getBearerToken} />
                </Route>
                <Route
                  path="/fxrates/:currency"
                  render={() => <FXRatePage getBearerToken={getBearerToken} />}
                />
                <Route path="/fxrates">
                  <FXRatesLandingPage
                    getBearerToken={getBearerToken}
                    SAName={SAName}
                  />
                </Route>
                <Route
                  path="/partners/:partnerId/codes"
                  render={() => <PartnerCodes />}
                />

                <Route path="/partners/:partnerId/agreements">
                  <PartnerAgreements />
                </Route>
                <Route
                  path="/partners/:partnerId"
                  render={() => (<PartnerDetails />)}
                />
                <Route path="/partners">
                  <PartnerPage />
                </Route>
                <Route>
                  <AdminStyledContainer>
                    <Row>
                      <Col>
                        <InstructionsDiv>
                          Please search for learner by name or email address
                          above, or enter the learner id into the address bar
                          like so:
                          <br />
                          <br />
                          https://admin.nexford.org/learners/
                          <strong>021593</strong>
                        </InstructionsDiv>
                        <QuickSearch getBearerToken={getBearerToken} />
                      </Col>
                    </Row>
                  </AdminStyledContainer>
                </Route>
              </Switch>
              <ToastContainer autoClose={2000} transition={Slide} />
            </NationalityOptionsProvider>
          </MainContentContainer>
        </div>
      </Router>
    </QueryClientProvider>
  );
};

App.propTypes = {
  logOut: PropTypes.func.isRequired,
  getBearerToken: PropTypes.func.isRequired,
  SAName: PropTypes.string.isRequired,
};

export default withAuth(App);
