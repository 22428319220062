/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import moment from 'moment';
import { Button, Modal, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  Form, FormRenderProps, FormSpy, Field,
} from 'react-final-form';
import {
  AnyObject, FormState, FormApi,
} from 'final-form';
import { isNull } from 'lodash';
import { OnChange } from 'react-final-form-listeners';
import { IRootState } from '../../../../shared/reducers';
import {
  selectRequired,
  isEmptyValue,
  dataSelectorRequired,
} from '../../../../utils/validate.utils';
import { setLoaStatusData } from '../../../../shared/actions/loa-scheduler.action';
import {
  ILoaSchedulerState,
  IDispacthProps,
  ICommonProps,
  ILoaSchedulerDataObject,
  IFormValues,
  ILoaDataObject,
} from './loa-scheduler-model.model';
import {
  renderSelectField,
  renderTextField,
  StartDatePicker,
  ReturnDatePicker,
  RemainingLoaField,
  isStartDateSelectorDisabled,
  Error,
  emptySelectOption,
  createOptions,
} from '../../../helper-components/form-components/form-filed-components/form-filed.components';
import SaveChangesDialog from '../../../helper-components/form-components/save-changes-dialog';
import {
  LOA_TYPE,
  RETURN_DATE,
  REMAINING_LOA,
  REMAINING_LOA_REQUIRED_WARNING,
  START_DATE,
  COMMENT,
  REQUIRED,
  OTHER,
} from './loa-scheduler-modal.constants';
import {
  CLOSE, YES, SAVE, SAVE_CHANGES,
} from '../../../constants/common-constants';

import { RedLabel } from '../../../helper-components/form-components/styled-components';
import MainRow from './styled-components';
import { REASON } from '../../program-status/program-status-editing-modal/program-status-editing-modal.constants';
import { ISelectOption } from '../../../../models/SelectOptions';

interface ILOACancelingWarning {
  show: boolean;
  form: FormApi<IFormValues>;
  closeDialog: (isLOAWarningVisible: boolean) => void;
  сancelLoa: () => void;
}
const LOACancelingWarning: React.FunctionComponent<ILOACancelingWarning> = (
  props: ILOACancelingWarning,
) => {
  const {
    show, сancelLoa, closeDialog, form,
  } = props;
  const formValues = form.getState().values;
  const startDate = moment(formValues[START_DATE] as string).format(
    'DD MMM YYYY',
  );
  const returnDate = moment(formValues[RETURN_DATE] as string).format(
    'DD MMM YYYY',
  );
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{SAVE_CHANGES}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{`Do you want to cancel the LOA scheduled from ${startDate} to ${returnDate}`}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => closeDialog(false)}>
          {CLOSE}
        </Button>
        <Button variant="primary" onClick={() => сancelLoa()}>
          {YES}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

class LoaSchedulerModal extends React.PureComponent<
ICommonProps,
ILoaSchedulerState
> {
  constructor(props: ICommonProps) {
    super(props);
    this.state = {
      loaSchedulerDateObject: props.loaSchedulerData,
      isChangedForm: false,
      isSaveChangesDialogVisible: false,
      isLOAWarningVisible: false,
      reasonOptions: [],
    };
  }

  public componentDidMount() {
    const { loaSchedulerData } = this.props;
    this.setLoaReasonOptionsByType(loaSchedulerData.type.value);
  }

  public componentDidUpdate(prevProps: ICommonProps): void {
    const { loaSchedulerData } = this.props;
    const prevSelectOptions = prevProps.loaSchedulerData;
    if (
      JSON.stringify(prevSelectOptions) !== JSON.stringify(loaSchedulerData)
    ) {
      this.updateLoaSchedulerData(loaSchedulerData);
    }
  }

  private countMaximumAvaliableLoa = (
    returnDate: string | null,
    startDate: string | null,
    remainigLoa: number,
    isRequiredTypeSelected: boolean,
  ): number => (!isNull(returnDate) && !isNull(startDate) && !isRequiredTypeSelected
    ? Math.abs(moment(startDate).diff(
      returnDate,
      'months',
      true,
    )) + remainigLoa : remainigLoa);

  private updateLoaSchedulerData = (
    loaSchedulerData: ILoaSchedulerDataObject,
  ): void => {
    this.setState({ loaSchedulerDateObject: loaSchedulerData });
  };

  private handleDetectChanges = (isChanged: boolean): void => {
    this.setState({ isChangedForm: isChanged });
  };

  private handleSave = (values: AnyObject, form: FormApi<IFormValues>) => {
    const {
      type, startDate, returnDate, comment, reason,
    } = values;
    const {
      getBearerToken,
      learnerId,
      setSelectedDate,
      loaSchedulerData,
      programEnrollmentId,
      loaOptions,
    } = this.props;

    const oneOptionAvailable = loaOptions.types.length === 1;

    const loaDataObject = {
      type: oneOptionAvailable ? loaOptions.types[0].id : isEmptyValue(type),
      startDate: moment(startDate).format('YYYY-MM-DD'),
      returnDate: moment(returnDate).format('YYYY-MM-DD'),
      comment,
      reason: isEmptyValue(reason),
    };
    setSelectedDate(
      getBearerToken,
      learnerId,
      programEnrollmentId,
      loaDataObject,
      false,
      loaSchedulerData.scheduleId,
    );
    this.closeAllModals(form, false);
  };

  private closeFrom = (
    form: FormApi<IFormValues>,
    formResetNeeded: boolean,
  ) => {
    const { handleClose } = this.props;
    handleClose();
    if (formResetNeeded) {
      form.reset();
    }
  };

  private openSaveChangesDialog = (form: FormApi<IFormValues>) => {
    const { isChangedForm } = this.state;
    if (isChangedForm) {
      this.handleSaveChangesModal(true);
    } else {
      this.closeFrom(form, true);
    }
  };

  private closeAllModals = (
    form: FormApi<IFormValues>,
    formResetNeeded: boolean,
  ) => {
    this.closeFrom(form, formResetNeeded);
    this.handleSaveChangesModal(false);
  };

  private cancelLoa = () => {
    const {
      handleClose,
      setSelectedDate,
      getBearerToken,
      learnerId,
      programEnrollmentId,
    } = this.props;
    const { loaSchedulerDateObject } = this.state;
    const {
      scheduleId,
      returnDate,
      startDate,
      remaining,
      type,
    } = loaSchedulerDateObject;
    const maxPossibleRemainingLoa = this.countMaximumAvaliableLoa(
      startDate,
      returnDate,
      remaining,
      type.value === REQUIRED,
    );
    const INITIAL_LOA_VALUES = {
      [LOA_TYPE]: isEmptyValue(emptySelectOption),
      [START_DATE]: null,
      id: null,
      [RETURN_DATE]: null,
      reason: isEmptyValue(emptySelectOption),
      remaining: maxPossibleRemainingLoa,
      [COMMENT]: '',
    };
    handleClose();
    this.handleLoaWarningDialog(false);
    setSelectedDate(
      getBearerToken,
      learnerId,
      programEnrollmentId,
      INITIAL_LOA_VALUES,
      true,
      scheduleId,
    );
  };

  private handleSaveChangesModal = (isSaveChangesDialogVisible: boolean) => {
    this.setState({ isSaveChangesDialogVisible });
  };

  private handleLoaWarningDialog = (isLOAWarningVisible: boolean) => {
    this.setState({ isLOAWarningVisible });
  };

  private handleLoaTypeChange = (value: ISelectOption,
    previousValue: ISelectOption,
    formValues: any) => {
    if (value.value !== previousValue.value) {
      this.setLoaReasonOptionsByType(value.value);
      // eslint-disable-next-line no-param-reassign
      formValues[REASON] = emptySelectOption;
    }
  };

  private setLoaReasonOptionsByType = (loaType: string) => {
    const { loaOptions } = this.props;
    if (loaOptions.types.length === 1) {
      const loaReasons = loaOptions.types[0].reasons.filter((x) => x.available);
      const reasonOptions = createOptions(loaReasons);
      this.setState({ reasonOptions });
    } else {
      const selectedType = loaOptions.types.find((x) => x.id === loaType);
      if (selectedType) {
        const loaReasons = selectedType.reasons.filter((x) => x.available);
        const reasonOptions = createOptions(loaReasons);
        this.setState({ reasonOptions });
      }
    }
  };

  public render(): JSX.Element {
    const { isSaveChangesDialogVisible, isLOAWarningVisible, reasonOptions } = this.state;
    const {
      show,
      handleClose,
      loaOptions,
      isLoaScheduled,
      disableScheduleLoaAfterXDays,
      learnerPaymentStatus,
    } = this.props;
    const { loaSchedulerDateObject } = this.state;
    const {
      type,
      reason,
      startDate,
      returnDate,
      comment,
      remaining,
    } = loaSchedulerDateObject;

    const initialFormValues = {
      [LOA_TYPE]: type || emptySelectOption,
      [START_DATE]: startDate,
      [RETURN_DATE]: returnDate,
      [REASON]: reason || emptySelectOption,
      [COMMENT]: comment,
      remaining,
    };

    const optionsType = createOptions(loaOptions.types);
    const maxPossibleRemainingLoa = this.countMaximumAvaliableLoa(
      startDate,
      returnDate,
      remaining,
      type.value === REQUIRED,
    );

    const checkIfRemainingLoaValid = (
      returnTime: string | null,
      startTime: string | null,
      isRequiredTypeSelected: boolean,
    ) => {
      if (returnTime && startTime && !isRequiredTypeSelected) {
        const timeScheduled = Math.abs(moment(returnTime).diff(
          startTime,
          'months',
          true,
        ));
        const timeLeft = maxPossibleRemainingLoa - timeScheduled;
        return timeLeft < 0;
      }
      return false;
    };

    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Editing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={this.handleSave}
            initialValues={initialFormValues}
            validate={(values) => {
              const errors: { [key: string]: string } = {};
              if (values[REASON].value === OTHER && (!values[COMMENT]
                || values[COMMENT].trim().length === 0)) {
                errors[COMMENT] = 'Comment is required';
              }

              if (checkIfRemainingLoaValid(
                values[RETURN_DATE],
                values[START_DATE],
                values[LOA_TYPE].value === REQUIRED,
              )) {
                errors[REMAINING_LOA] = 'Please select correct date range';
              }
              return errors;
            }}
            render={<T extends object>({
              handleSubmit,
              form,
              pristine,
              valid,
              values,
            }: FormRenderProps<IFormValues> & FormState<T>): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <Col>
                  {optionsType && optionsType.length > 1
                  && (
                  <MainRow>
                    <Col xs={5}>LOA type</Col>
                    <Col>
                      <Field
                        onChange={this.handleLoaTypeChange}
                        render={renderSelectField}
                        name={LOA_TYPE}
                        validate={selectRequired}
                        options={optionsType}

                      />
                      <OnChange
                        name={LOA_TYPE}
                      >
                        {(value, previous) => {
                          this.handleLoaTypeChange(value, previous, values);
                        }}
                      </OnChange>
                    </Col>
                  </MainRow>
                  )}
                  <MainRow>
                    <Col xs={5}>Reason</Col>
                    <Col>
                      <Field
                        name={REASON}
                        options={reasonOptions}
                        validate={selectRequired}
                        render={renderSelectField}
                      />
                    </Col>
                  </MainRow>
                  <MainRow>
                    <Col xs={5}>Start Date</Col>
                    <Col>
                      <Field
                        component={StartDatePicker}
                        hasUnpaidInvoices={learnerPaymentStatus}
                        disableScheduleLoaAfterXDays={disableScheduleLoaAfterXDays}
                        name={START_DATE}
                        maxPossibleRemainingLoa={maxPossibleRemainingLoa}
                        validate={dataSelectorRequired}
                      />
                      <p>
                        <Error name={START_DATE} />
                      </p>
                    </Col>
                  </MainRow>
                  <MainRow>
                    <Col xs={5}>Return Date</Col>
                    <Col>
                      <Field
                        component={ReturnDatePicker}
                        name={RETURN_DATE}
                        disableScheduleLoaAfterXDays={disableScheduleLoaAfterXDays}
                        maxPossibleRemainingLoa={maxPossibleRemainingLoa}
                        validate={dataSelectorRequired}
                      />
                      <p>
                        <Error name={RETURN_DATE} />
                      </p>
                    </Col>
                  </MainRow>
                  <MainRow>
                    <Col xs={5}>Remaining LOA</Col>
                    <Col>
                      <Field
                        render={RemainingLoaField}
                        name={REMAINING_LOA}
                        maxPossibleRemainingLoa={maxPossibleRemainingLoa}
                        remainingLOA={remaining}
                        countRemaining={values[LOA_TYPE].value !== REQUIRED}
                      />
                      {values[LOA_TYPE].value === REQUIRED
                        && (
                          <p>
                            {REMAINING_LOA_REQUIRED_WARNING}
                          </p>
                        )}
                      <p>
                        <Error name={REMAINING_LOA} />
                      </p>
                    </Col>
                  </MainRow>
                  <MainRow>
                    <Col xs={5}>Comment</Col>
                    <Col>
                      <Field
                        name={COMMENT}
                        render={renderTextField}
                        maxLength={250}
                      />
                      <p>
                        <Error name={COMMENT} />
                      </p>
                    </Col>
                  </MainRow>
                </Col>
                <Modal.Footer>
                  <Col xs={8}>
                    {isLoaScheduled
                      && !isStartDateSelectorDisabled(
                        form.getState().values[START_DATE],
                        false,
                        disableScheduleLoaAfterXDays,
                      ) && (
                      <>
                        <Button
                          variant="danger"
                          disabled={learnerPaymentStatus}
                          onClick={() => this.handleLoaWarningDialog(true)}
                        >
                            Cancel LOA
                        </Button>
                          {learnerPaymentStatus && <RedLabel>Learner has unpaid invoices</RedLabel>}
                      </>
                    )}
                  </Col>
                  <Col xs={2}>
                    <Button
                      variant="primary"
                      onClick={() => handleSubmit()}
                      disabled={pristine || !valid}
                    >
                      {SAVE}
                    </Button>
                  </Col>
                  <Col xs={2}>
                    <Button
                      variant="secondary"
                      onClick={() => this.openSaveChangesDialog(form)}
                    >
                      {CLOSE}
                    </Button>
                  </Col>
                </Modal.Footer>
                <SaveChangesDialog
                  show={isSaveChangesDialogVisible}
                  closeModals={this.closeAllModals}
                  text="Your changes have not been saved"
                  formValid={valid}
                  form={form}
                  formResetNeeded
                  handleSubmit={handleSubmit}
                />
                <LOACancelingWarning
                  show={isLOAWarningVisible}
                  closeDialog={this.handleLoaWarningDialog}
                  form={form}
                  сancelLoa={this.cancelLoa}
                />
                <FormSpy
                  subscription={{ values: true, dirty: true, pristine: true }}
                  onChange={<K extends object>({
                    dirty,
                  }: FormState<K>): void => {
                    this.handleDetectChanges(dirty);
                  }}
                />
              </form>
            )}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispacthProps, AnyAction>,
): IDispacthProps => ({
  setSelectedDate: (
    token: () => Promise<string>,
    learnerId: string,
    programEnrollmentId: string,
    loaObject: ILoaDataObject,
    cancelLoa: boolean,
    scheduleId: null | string,
  ): void => {
    dispatch(
      setLoaStatusData(token, learnerId, programEnrollmentId, loaObject, cancelLoa, scheduleId),
    );
  },
});

export default connect(null, mapDispatchToProps)(LoaSchedulerModal);
