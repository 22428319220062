import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const WorQHeader = styled.h2`
  font-variant: small-caps;
  text-align: left;
`;

export const WorQDiv = styled.div`
  font-family: "Open Sans";
  text-align: left;
  margin-left: 10px;
`;

export const WorQSpinner = styled(Spinner)`
  margin-left: -20px;
  margin-right: 5px;
`;

export const AdvancedSearchLink = styled(Link)`
  margin-left: 10px;
`;
