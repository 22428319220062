import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import api from '../../../shared/api/adminUI.api';

import IdentityInfo from '../../../shared/models/identity-info';
import AdmissionsFileUpload from '../../admissions/admissions-form/file-upload';
import { INxuFileUploadResult } from '../../../shared/models/file-upload-result';
import { DocumentInfo } from '../../admissions/admissions.model';
import IError from '../../../models/Error';

export interface VerificationStepsProps {
  readonly getBearerToken: () => Promise<string>;
  applicantId: string;
  learnerIdentity: IdentityInfo;
  refreshVerificationSteps: (id: string) => {};
  canEditDocuments: boolean;
}

const VerificationSteps = (props: VerificationStepsProps) => {
  const {
    getBearerToken, applicantId, learnerIdentity, refreshVerificationSteps, canEditDocuments,
  } = props;

  const [inProgress, setInProgress] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<IError | null>(null);
  // State for verification steps edit
  const [canEditGovId, setCanEditGovId] = useState<boolean>(false);
  const [canEditPhotoId, setCanEditPhotoId] = useState<boolean>(false);

  const [officialNameFormValue, setOfficialNameFormValue] = useState<string>('');
  const [officialNameChanged, setOfficialNameChanged] = useState<boolean>(false);

  const [govIdFormValue, setGovIdFormValue] = useState<DocumentInfo>();
  const [photoFormValue, setPhotoFormValue] = useState<DocumentInfo>();

  useEffect(() => {
    if (learnerIdentity) {
      setOfficialNameFormValue(learnerIdentity.officialName);
      // @ts-ignore
      setGovIdFormValue(learnerIdentity.idDocument);
      // @ts-ignore
      setPhotoFormValue(learnerIdentity.personalPhoto);
      if (canEditDocuments) {
        if (learnerIdentity.process.idReviewStatus === 'Passed' || learnerIdentity.process.idReviewStatus === 'Failed') setCanEditGovId(true);
        if (learnerIdentity.process.photoReviewStatus === 'Passed' || learnerIdentity.process.photoReviewStatus === 'Failed') setCanEditPhotoId(true);
      }
    }
  }, [learnerIdentity, canEditDocuments]);

  const saveGovIdChanges = async (result?: INxuFileUploadResult) => {
    if (!applicantId || (!result && !govIdFormValue) || !officialNameFormValue) return;
    try {
      const identityData = {
        OfficialName: officialNameFormValue,
        Documents: [
          {
            originalFileName: result?.originalFileName || govIdFormValue?.originalFileName || '',
            uploadedFileName: result?.uploadedFileName || govIdFormValue?.uploadedFileName || '',
            id: 'id',
          },
        ],
      };

      const idInfo = await api.learnerIdentity.SubmitIdentityInfo(applicantId, getBearerToken, identityData);
      if (!idInfo.ok) throw await idInfo.json();
      refreshVerificationSteps(applicantId);
    } catch (err) {
      setInProgress(false);
      setUploadError(err as IError);
    }
  };

  const saveUploadedPhoto = async (result?: INxuFileUploadResult) => {
    if (!applicantId || (!result && !photoFormValue)) return;
    try {
      const identityInfo = await api.learnerIdentity.SubmitIdentityPhoto(applicantId, getBearerToken, {
        UploadedFileName: result?.uploadedFileName || photoFormValue?.uploadedFileName || '',
        OriginalFileName: result?.originalFileName || photoFormValue?.originalFileName || '',
      });
      if (!identityInfo.ok) throw await identityInfo.json();
      refreshVerificationSteps(applicantId);
    } catch (err) {
      setInProgress(false);
      setUploadError(err as IError);
    }
  };

  const updateOfficialName = (evt: any) => {
    if (!learnerIdentity) return;
    const { officialName } = learnerIdentity;
    setOfficialNameFormValue(evt.target.value.trim());
    setOfficialNameChanged(evt.target.value.trim() !== officialName);
  };

  const fileUploadStarted = () => setInProgress(true);

  const fileUploaded = async (type: 'govId' | 'photo', result: INxuFileUploadResult) => {
    if (type === 'govId') await saveGovIdChanges(result);
    else if (type === 'photo') await saveUploadedPhoto(result);
  };

  return (
    <ul>
      {uploadError && (
        <li className="status-line status-line--error">
          <span>{uploadError.message || 'There was an error on the verification updates'}</span>
        </li>
      )}
      <li className="upload-row">
        <span>Government ID:</span>
        <div className="row">
          {!learnerIdentity?.idUrl && <em>No Government ID</em>}
          {learnerIdentity?.idUrl && (
            <a className="col-7" href={learnerIdentity?.idUrl} target="_blank" rel="noopener noreferrer">
              Open Government ID
            </a>
          )}
          {learnerIdentity?.idUrl && canEditGovId && (
            <AdmissionsFileUpload
              getBearerToken={getBearerToken}
              learnerId={applicantId || ''}
              type="government-id"
              disabled={inProgress}
              uploadStarted={() => fileUploadStarted()}
              onFileUploaded={(r) => fileUploaded('govId', r)}
            >
              Upload new
            </AdmissionsFileUpload>
          )}
        </div>
      </li>
      <li>
        <span>Official name:</span>
        {canEditGovId && <input type="text" defaultValue={learnerIdentity?.officialName} onChange={(evt) => updateOfficialName(evt)} style={{ minWidth: 240 }} />}
        {!canEditGovId && learnerIdentity?.officialName}
        {officialNameChanged && (
          <Button size="sm" type="submit" onClick={() => saveGovIdChanges()}>
            Save
          </Button>
        )}
      </li>
      {(learnerIdentity?.process?.idReviewStatus === 'AwaitingResponse' || learnerIdentity?.process?.idReviewStatus === 'NotReviewed') && (
        <li className="status-line">
          <span>Awaiting ID verification</span>
        </li>
      )}
      {learnerIdentity?.process?.idReviewStatus === 'Failed' && (
        <li className="status-line--failed">
          <span>Rejected: </span> {learnerIdentity?.process?.idRejectionReason}
        </li>
      )}
      {learnerIdentity?.process?.idReviewStatus === 'Passed' && (
        <li className="status-line--success">
          <span>Accepted: </span> ID is valid & matches Official Name
        </li>
      )}
      {learnerIdentity?.process?.idReviewer && (
        <li>
          <span>Reviewed by:</span> {learnerIdentity?.process?.idReviewer}
        </li>
      )}
      <li className="upload-row">
        <span>Photo:</span>
        <div className="row">
          {!learnerIdentity?.photoUrl && <em>No Photograph</em>}
          {learnerIdentity?.photoUrl && (
            <a href={learnerIdentity?.photoUrl} className="col-7" target="_blank" rel="noopener noreferrer">
              Open Photograph
            </a>
          )}
          {learnerIdentity?.photoUrl && canEditPhotoId && (
            <AdmissionsFileUpload
              getBearerToken={getBearerToken}
              learnerId={applicantId || ''}
              type="personal-photo"
              disabled={inProgress}
              uploadStarted={() => fileUploadStarted()}
              onFileUploaded={(r) => fileUploaded('photo', r)}
            >
              Upload new
            </AdmissionsFileUpload>
          )}
        </div>
      </li>
      {(learnerIdentity?.process?.photoReviewStatus === 'AwaitingResponse' || learnerIdentity?.process?.photoReviewStatus === 'NotReviewed') && (
        <li className="status-line">
          <span>Awaiting Photo verification</span>
        </li>
      )}
      {learnerIdentity?.process?.photoReviewStatus === 'Failed' && (
        <li className="status-line--failed">
          <span>Rejected: </span> {learnerIdentity?.process?.photoRejectionReason}
        </li>
      )}
      {learnerIdentity?.process?.photoReviewStatus === 'Passed' && (
        <li className="status-line--success">
          <span>Accepted: </span> Photo is valid
        </li>
      )}
      {learnerIdentity?.process?.photoReviewer && (
        <li>
          <span>Reviewed by:</span> {learnerIdentity?.process?.photoReviewer}
        </li>
      )}
      {(learnerIdentity?.process?.photoVerificationStatus === 'AwaitingResponse' || learnerIdentity?.process?.photoVerificationStatus === 'NotReviewed') && (
        <li className="status-line">
          <span>Awaiting match with Government ID</span>
        </li>
      )}
      {learnerIdentity?.process?.photoVerificationStatus === 'Failed' && (
        <li className="status-line--failed">
          <span>Rejected: </span> Photo & Government ID don&apos;t match
        </li>
      )}
      {learnerIdentity?.process?.photoVerificationStatus === 'Passed' && (
        <li className="status-line--success">
          <span>Accepted: </span> Photo & Government ID match
        </li>
      )}
      {learnerIdentity?.process?.photoVerifier && (
        <li>
          <span>Verified by:</span> {learnerIdentity?.process?.photoVerifier}
        </li>
      )}
    </ul>
  );
};

export default VerificationSteps;
