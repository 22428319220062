export const ACCEPT = 'Accept';
export const REJECT = 'Reject';
export const PRODUCT_TYPE = 'productType';
export const PRODUCT_CODE = 'productCode';
export const PARTNER = 'partner';
export const ADMISSION_DECISION = 'admissionDecision';
export const ACCEPTANCE_TYPE = 'acceptanceType';
export const ACCEPTANCE_STATUS = 'acceptanceStatus';
export const ACCEPTANCE_PATH = 'acceptancePath';
export const ACCEPTED_EVALUATION_PENDING = 'acceptedEvaluationPending';
export const FOUNDATION_COURSES = 'foundationCourses';
export const TRANSCRIPT = 'transcript';
export const ENGLISH_PROFICIENCY = 'englishProficiency';
export const PROVISIONAL = 'provisional';
export const FULL = 'full';
export const AEP_DOCUMENTS = 'aepDocuments';

export const OK = 'ok';
export const INCORRECT = 'incorrect';
export const MISSING = 'missing';
export const UNREADBLE = 'unreadable';
export const WARNING_TEXT = 'Are you sure you want to submit this application decision? (This decision cannot be altered)';
export const COMMENT_WARNING = 'Please remember that all comments you enter in the comments box will be included (word-for-word) in an automatic email sent to the applicant. Make sure you follow appropriate external messaging guidelines';

export const PRODUCT_CODE_ERROR = 'Please, select product';
export const ADMISSION_DECISION_ERROR = 'Please, select admissions decision';
export const ACCEPTANCE_TYPE_ERROR = 'Please, select acceptance type';
export const FOUNDATION_COURSES_ERROR = 'In case of Provisional Acceptance at least one Foundational course must be selected';
export const AEP_ERROR = 'Mark what is incorrect with the applicant documents';
export const AEP_ERROR_WRONG_SELECTION = 'If all documents are OK, then this application should not be treated as AEP';
export const COMMENT_ERROR = 'Please, add comment';

export const UNITED_STATES = 'United States';
export const UNITED_STATES_ISO = 'US';
