import React from 'react';
import cn from 'classnames';
import styles from './helper-text.module.scss';

type Props = {
  variant?: 'default' | 'error' | 'warning' | 'success';
  children: React.ReactNode;
  className?: string;
};

export const HelperText = ({
  variant = 'default',
  children,
  className,
}: Props) => (
  <p
    className={cn(styles.text, styles[variant], className)}
    style={{ fontSize: '0.8rem', marginTop: '0.25rem' }}
  >
    {children}
  </p>
);
