import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { toast } from 'react-toastify';
import { StripedTable } from '../../../styled.components';
import styles from './partner-codes-table.module.scss';
import { usePartnerCodesParams } from '../../hooks/use-partner-codes-params';
import api from '../../../../../shared/api/adminUI.api';
import { useAuthContext } from '../../../../auth/auth-context';
import { codesTypeLabels, PartnerCode } from '../../../../../models/PartnerCode';
import { SuspenseContainer } from '../../../../suspense-container/suspense-container';
import { HelperText } from '../../../../helper-text/helper-text';
import { downloadBlobFile } from '../../../../../utils/download';
import { getCsvFileName } from '../../utils/get-csv-file-name';

type RowProps = {
  code: PartnerCode;
  accountName: string | undefined;
};

type BodyProps = {
  accountName: string | undefined;
};

type Props = {
  accountName: string | undefined;
};

const Row = ({ code, accountName }: RowProps) => {
  const { getBearerToken } = useAuthContext();
  const { mutateAsync: getCSV, isLoading } = useMutation({
    mutationFn: () => api.partners.codes.getCSV(code.id, getBearerToken),
  });

  const handleDownload = async () => {
    try {
      const file = await getCSV();
      const fileName = getCsvFileName(code.id, accountName, code.codesType);
      downloadBlobFile(file, fileName);
    } catch (error) {
      console.error(error);
      toast.error('Failed to download codes');
    }
  };

  const codesTypeLabel = codesTypeLabels[code.codesType] || code.codesType;

  return (
    <tr>
      <td>{moment(code.created).format('yyyy-MM-DD hh:mm')}</td>
      <td>{code.generatedBy}</td>
      <td>{codesTypeLabel}</td>
      <td className={styles.cellCodesCount}>{code.codesCount}</td>
      <td>
        <Button onClick={handleDownload} disabled={isLoading}>
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <FaDownload />
          )}{' '}
          Download
        </Button>
      </td>
    </tr>
  );
};

const Placeholder = ({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>) => (
  <tbody className={className}>
    <tr>
      <td colSpan={4}>{children}</td>
    </tr>
  </tbody>
);

const Body = ({ accountName } : BodyProps) => {
  const { getBearerToken } = useAuthContext();
  const { partnerId } = usePartnerCodesParams();

  const { data: codes } = useQuery({
    queryKey: ['partner', partnerId, 'codes'],
    queryFn: () => api.partners.codes.getBatches(partnerId, getBearerToken),
    suspense: true,
  });

  if (!codes?.length) {
    return (
      <Placeholder>
        <HelperText className={styles.noCodes}>There is no codes</HelperText>
      </Placeholder>
    );
  }

  return (
    <tbody>
      {codes?.map((code) => (
        <Row key={code.id} code={code} accountName={accountName} />
      ))}
    </tbody>
  );
};

const Header = () => (
  <thead>
    <tr>
      <th>Date</th>
      <th>Generated By</th>
      <th>Type</th>
      <th className={styles.cellCodesCount}># of Codes</th>
      <th>{' '}</th>
    </tr>
  </thead>
);

export const PartnerCodesTable = ({ accountName } : Props) => (
  <StripedTable className={styles.table}>
    <Header />
    <SuspenseContainer
      container={Placeholder}
      loadingText="Loading Partner Codes..."
    >
      <Body accountName={accountName} />
    </SuspenseContainer>
  </StripedTable>
);
