import React, { useState } from 'react';
import {
  Button, Row, Col, Form,
} from 'react-bootstrap';
import { FiSave, FiEdit } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import {
  EditingCol,
  EditingButtonsRow,
  FieldRow,
  EditButton,
} from './styled-component';
import TextField from './text-edit-component';
import DropdownField from './dropdown-edit-component';
import { INewFieldValue, ISAOption } from '../learner-info.model';
import CheckboxField from '../../admissions/applicant-editable-info/editing-component/checkbox-edit-component';

interface IProps {
  oldValue: string | boolean;
  canEdit: boolean;
  propertyName: string;
  changedFieldUrl: string;
  handleNewValue: (newValue: INewFieldValue | ISAOption, changedFieldUrl: string) => Promise<void>;
  fieldType: string;
  isBirthYear?: boolean;
  htmlLabel?: {[key: string]: any}
}

const returnFieldBasedOnType = (
  filedType: string,
  fieldValue: string | boolean,
  propertyName: string,
  validationError: string | null,
  setFormError: (error: string | null) => void,
  setNewFieldValue: (newValue: string | boolean) => void,
  setSAFieldValue: (newValue: ISAOption | null) => void,
): JSX.Element => {
  switch (filedType) {
    case 'email':
    case 'input':
    case 'number':
    default:
      return (
        <TextField
          fieldValue={fieldValue as string}
          fieldType={filedType}
          propertyName={propertyName}
          setFormError={setFormError}
          setNewFieldValue={setNewFieldValue}
          validationError={validationError}
        />
      );
    case 'checkbox':
      return (
        <CheckboxField
          fieldValue={fieldValue}
          setNewFieldValue={setNewFieldValue}
        />
      );
    case 'select':
      return (
        <DropdownField
          fieldValue={fieldValue as string}
          setNewFieldValue={setNewFieldValue}
          setSAFieldValue={setSAFieldValue}
          setFormError={setFormError}
          propertyName={propertyName}
        />
      );
  }
};

const FieldEditor: React.FunctionComponent<IProps> = ({
  oldValue,
  canEdit,
  propertyName,
  changedFieldUrl,
  fieldType,
  handleNewValue,
  htmlLabel,
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setNewFieldValue] = useState(oldValue);
  const [SAFieldValue, setSAFieldValue] = useState<null | ISAOption>(null);
  const [validationError, setFormError] = useState<null | string>(null);

  const edit = () => {
    setEditing(true);
    setNewFieldValue(oldValue);
  };

  const cancel = () => {
    setEditing(false);
    setNewFieldValue(oldValue);
    setFormError(null);
  };

  const save = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = propertyName === 'SuccessAdvisorName' && SAFieldValue ? SAFieldValue : { [propertyName]: fieldValue };
    await handleNewValue(payload, changedFieldUrl);
    setEditing(false);
  };

  if (editing) {
    return (
      <>
        <Form onSubmit={save}>
          <Row>
            <EditingCol xs={9}>
              {
               returnFieldBasedOnType(
                 fieldType,
                 fieldValue,
                 propertyName,
                 validationError,
                 setFormError,
                 setNewFieldValue,
                 setSAFieldValue,
               )
              }
            </EditingCol>
            <Col xs={3}>
              <EditingButtonsRow>
                <Button variant="outline-secondary" size="sm" disabled={!!validationError} type="submit">
                  <FiSave />
                </Button>
                <Button variant="outline-secondary" size="sm" onClick={cancel}>
                  <GiCancel />
                </Button>
              </EditingButtonsRow>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col id={propertyName} xs={9}>
          {!htmlLabel && <span>{fieldValue}</span>}
          {htmlLabel && htmlLabel[fieldValue as string]}
        </Col>
        <Col xs={3}>
          <FieldRow>
            {canEdit && (
            <EditButton variant="outline-secondary" size="sm" onClick={edit}>
              <FiEdit />
            </EditButton>
            )}
          </FieldRow>
        </Col>
      </Row>
    </>
  );
};

export default FieldEditor;
