import { groupBy, orderBy } from 'lodash';
import {
  ICourse,
  ICoursesWithPrereuisites,
  IWrongElement,
  ICoreCoursesOrderElement,
  SubstatusID,
} from './courses-summary.model';
import { ISelectOption } from '../../../../models/SelectOptions';

export const getPrerequisitesPositions = (ids: string[], courses: ICourse[]) => {
  const prerquisitesPositions: number[] = [];
  for (let i = 0; i < courses.length; i += 1) {
    const el = courses[i];
    if (ids.includes(el.id)) {
      prerquisitesPositions.push(i);
    }
  }
  return prerquisitesPositions;
};

export const getElementsWithPrerequisitesPositions = (
  courses: ICourse[],
): any[] => {
  const coursesWithPrereuisites: ICoursesWithPrereuisites[] = [];
  for (let i = 0; i < courses.length; i += 1) {
    const el = courses[i];
    if (el.prerequisites.length > 0) {
      coursesWithPrereuisites.push({
        courseId: el.id,
        index: i,
        inProgress: el.inProgress,
        prerquisitesPositions: getPrerequisitesPositions(
          el.prerequisites,
          courses,
        ),
      });
    }
  }
  return coursesWithPrereuisites;
};

export const returnElementsIdsWithWrongPositions = (
  coursesWithPrereuisites: ICoursesWithPrereuisites[],
  courses: ICourse[],
): IWrongElement[] => {
  const wrongElements: IWrongElement[] = [];
  if (coursesWithPrereuisites.length > 0) {
    coursesWithPrereuisites.forEach((course: ICoursesWithPrereuisites) => {
      if (
        course.prerquisitesPositions.length > 0
          && !course.prerquisitesPositions.every((elem) => elem < course.index
          || courses[course.index].subStatusId === SubstatusID.Passed)
          && !course.inProgress
      ) {
        wrongElements.push({ courseId: course.courseId });
      }
    });
  }
  return wrongElements;
};

export const groupCourses = (courses: ICourse[]): ICourse[] => {
  // This function checks if courses have common group field,
  // and if they have, groups them into one course,
  // which displays like a dropdown select
  const formatedCourses: ICourse[] = [];
  const groupedCourses = groupBy(courses, 'group');
  Object.keys(groupedCourses).forEach((key): void => {
    if (key === 'null') {
      formatedCourses.push(...groupedCourses.null);
    } else {
      const courseOrder = groupedCourses[key].find(
        (course: ICourse) => course.isGroupSelection,
      ) || groupedCourses[key][0];
      formatedCourses.push({
        id: groupedCourses[key][0].group || 'select',
        retaken: 0,
        externalTransferred: groupedCourses[key][0].externalTransferred,
        statusId: groupedCourses[key][0].statusId,
        subStatusId: groupedCourses[key][0].subStatusId,
        courses: groupedCourses[key],
        section: groupedCourses[key][0].section,
        isSelectDropdown: true,
        inProgress: groupedCourses[key][0].inProgress,
        group: groupedCourses[key][0].group || 'select',
        name: groupedCourses[key][0]!.group || 'select',
        order: courseOrder.order,
        fixed: groupedCourses[key][0].fixed,
        defaultFixed: groupedCourses[key][0].defaultFixed,
        prerequisites: [],
        isCourseAvailable: groupedCourses[key][0].isCourseAvailable,
      });
    }
  });
  return orderBy(formatedCourses, ['isCourseAvailable', 'order'], ['desc', 'asc']);
};

export const updateCourse = (newValue: ISelectOption, course: ICourse): ICourse => {
  const { courses: courseInnerCourses = [] } = course;
  const updatedInnerCourses = courseInnerCourses.map((innerCourse: ICourse) => {
    if (innerCourse.id === newValue.value) {
      return {
        ...innerCourse,
        isGroupSelection: true,
      };
    }
    return {
      ...innerCourse,
      isGroupSelection: false,
    };
  });

  return {
    ...course,
    courses: updatedInnerCourses,
  };
};

export const proccessCoursesDataForSave = (
  courses: ICourse[],
): ICoreCoursesOrderElement[] => courses.map((course: ICourse) => {
  if (course.courses) {
    const selectedCourse = course.courses.find(
      (innerCourse: ICourse) => innerCourse.isGroupSelection,
    );
    return {
      id: selectedCourse!.id,
      order: course.order,
      retaken: course.retaken,
      group: course.group,
      isGroupSelection: selectedCourse!.isGroupSelection,
    };
  }
  return {
    id: course.id,
    order: course.order,
    retaken: course.retaken,
  };
});

export const isSaveDisabled = (
  courses: ICourse[],
  wrongElements: IWrongElement[],
  pendingSave: boolean,
): boolean => {
  const coursesWithSelect = courses.filter(
    (course: ICourse) => course.isSelectDropdown,
  );
  const coursesSelected = coursesWithSelect.map(
    (courseWithSelect: ICourse) => courseWithSelect.courses!.some(
      (courseSelected) => courseSelected.isGroupSelection === true,
    ),
  );
  const areThereWrongElements = wrongElements.length > 0;
  const areAllCoursesSelected = coursesSelected.every(
    (course: boolean) => course === true,
  );
  return areThereWrongElements || !areAllCoursesSelected || pendingSave;
};
