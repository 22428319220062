import {
  Container,
  Button,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';


export const SearchContainer = styled(Container)`
  text-align: left;
  padding: 0;
  margin-left: 15px;
  min-width: 700px;
  @media (min-width: 700px) {
    margin-left: 60px;
  }
`;

export const ResultsRow = styled(Row)`
  margin: 50px 0;
`;

export const SearchButton = styled(Button)`
  height: 38px;
  margin-left: 15%;
`;

export const AdvanceSearchHeader = styled.h3`
  margin: 30px 0 50px 0;
`;


export const FilterSelect = styled(Select)`
  display:inline-block;
  width: 100%;
`;

export const SpacedLabel = styled(Form.Label)`
  padding-top:6px;
  display:inline-block;
  width: 40%;
  margin-right: 20px;
`;

export const FlexGroup = styled(Form.Group)`
  display:flex;
`;

export const FlexCol = styled(Col)`
  display:flex;
`;
