import {
  Table,
} from 'react-bootstrap';
import styled from 'styled-components';

export const ProfessorsTable = styled(Table)`
  margin-top: 5px;
  max-width: 350px;
  font-size: 14px;
  border-top-style: hidden;

  td,th {
    padding-left: 0;
  }
`;

export const CourseInfoHeader = styled.p`
  font-weight: bold;
  margin-bottom: 0;
`;

export const GradesTable = styled(Table)`
  font-size: 14px;
  td,th {
    min-width: 90px;
  }
`;
