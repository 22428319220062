/* eslint-disable import/prefer-default-export */
import {
  IApplicantInfo,
} from './admissions.model';
import api from '../../shared/api/adminUI.api';

export const getApplicantInfo = async (
  applicantId: string,
  getBearerToken: () => Promise<string>,
): Promise<IApplicantInfo> => {
  const applicantInfoResponse = await api.admissions.getApplicantInfo(
    applicantId,
    getBearerToken,
  );
  return applicantInfoResponse.json();
};
