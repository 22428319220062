import React from 'react';
import { ReactSortable, MoveEvent } from 'react-sortablejs';
import ListItem from './list-item/drag-n-drop-list-item';
import {
  WelcomeRow,
} from './styled-components';
import { ICourse } from '../steps/courses-summary/courses-summary.model';
import {
  CourseListDnDProps,
} from './course-list-drag-n-drop.model';

const CourseListDnD: React.FunctionComponent<CourseListDnDProps> = (props: CourseListDnDProps) => {
  const onMove = (e: MoveEvent) => {
    // prevent drop on disabled elements
    if (e.related.classList.contains('drag-elements')) {
      return true;
    }
    return false;
  };
  const {
    setRetakeCourse,
    deleteRetakenCourse,
    setList,
    handleSelectChange,
    retakenCoursesIds,
    summaryCoursesData,
    elementIdsWithWrongPositions,
  } = props;

  return (
    <>
      <WelcomeRow>
        <span>Course Reorder</span>
      </WelcomeRow>
      <ReactSortable
        list={summaryCoursesData}
        id="dnd-list"
        setList={(corses: ICourse[]) => {
          setList(corses);
        }}
        animation={150}
        draggable=".drag-elements"
        scroll
        direction="vertical"
        onMove={onMove}
      >
        {summaryCoursesData.map((course: ICourse, i: number) => (
          <ListItem
            key={course.id + course.retaken}
            order={i}
            course={course}
            elementIdsWithWrongPositions={elementIdsWithWrongPositions}
            retakenCoursesIds={retakenCoursesIds}
            setRetakeCourse={setRetakeCourse}
            deleteCourse={deleteRetakenCourse}
            handleSelectChange={handleSelectChange}
          />
        ))}
      </ReactSortable>
    </>
  );
};


export default CourseListDnD;
