/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { FiEye } from 'react-icons/fi';
import withPermission from '../../helper-components/view-permission.component';
import withLoading from '../../helper-components/loading.component';
import { LearnerAcademicStanding } from '../../../shared/reducers/academic-standing/academic-standing.model';
import AcademicStandingDetails from './academic-standing-details-modal';
import AcademicStandingStatusIcon from './academic-standing-status-icon';
import UniversityDate from '../../university-date';
import AcademicStandingStatusAge from './academic-standing-status-age';
import { StylesForAcademicCol } from './styled-components';

export interface ISAPProps {
  readonly status: LearnerAcademicStanding;
  readonly programStatus: string;
}

export interface ISAPState {
  showModal: boolean;
}

class AcademicStanding extends React.Component<ISAPProps, ISAPState> {
  constructor(props: ISAPProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  private handleModal(showModal: boolean) {
    this.setState({ showModal });
  }

  public render(): JSX.Element {
    const {
      status,
      programStatus,
    } = this.props;

    const isGraduated = programStatus === 'Graduated';
    const isLoading = programStatus === '-';

    const {
      showModal,
    } = this.state;

    if (!status || !status.parameterStatus) return <></>;
    if (!status.academicStanding) return <>N/A</>;

    return (
      <>
        <StylesForAcademicCol>
          <Row>
            <Col xs={9} className={isGraduated || isLoading ? 'is-graduated' : 'not-graduated'}>
              <Row>
                <Col xs={12}>
                  <span className="academic-status">{status.academicStanding.statusText}</span>
                  <AcademicStandingStatusIcon value={status.academicStanding.status} />
                </Col>
              </Row>
              {status.academicStanding.lastStatusChangeDate
                && (
                <Row>
                  <AcademicStandingStatusCol xs={12}>
                    <UniversityDate value={status.academicStanding.lastStatusChangeDate} /> <AcademicStandingStatusAge days={status.daysSinceAcademicStatusUpdate} />
                  </AcademicStandingStatusCol>
                </Row>
                )}
            </Col>
            <Col xs={3}>
              <Row style={{ justifyContent: 'flex-end', margin: 0 }}>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => { this.handleModal(true); }}
                >
                  <FiEye />
                </Button>
              </Row>
            </Col>
          </Row>
        </StylesForAcademicCol>

        {showModal && status.parameterStatus
            && (
            <AcademicStandingDetails
              status={status}
              age={status.daysSinceAcademicStatusUpdate}
              academicStandingCode={status.academicStanding.parameterCode}
              handleClose={() => this.handleModal(false)}
            />
            )}
      </>
    );
  }
}

export default withLoading(withPermission(AcademicStanding));

const AcademicStandingStatusCol = styled(Col)`
  font-size: 12px;
  color: gray;
  font-weight: 400;
`;
