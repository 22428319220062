import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import {
  IStartDateModalTypes,
  IStartDateObject,
} from '../reducers/start-date/start-date.model';


export const getStartDateState = (
  state: IRootState,
): IStartDateModalTypes => state.startDateModalState;

export const getLearnerDetails = createSelector(
  [getStartDateState],
  (
    startDateModalState: IStartDateModalTypes,
  ): IStartDateObject => startDateModalState.learnerDetailsObject,
);

export const getSelectedStartDateString = createSelector(
  [getStartDateState],
  (
    startDateModalState: IStartDateModalTypes,
  ): null | string => startDateModalState.learnerDetailsObject.startDate,
);

export const getDeferredStatus = createSelector(
  [getStartDateState],
  (
    startDateModalState: IStartDateModalTypes,
  ): boolean => startDateModalState.deferred,
);

export const getEnrollmentDate = createSelector(
  [getStartDateState],
  (
    startDateModalState: IStartDateModalTypes,
  ): null | string => startDateModalState.learnerDetailsObject.enrollmentDate,
);
