/* eslint-disable import/prefer-default-export */

import { IRootState } from '../reducers';
import {
  ILearnerAEPDocumentsState,
} from '../reducers/aep-documents/aep-documents.model';

export const getAepDocumentsState = (
  state: IRootState,
): ILearnerAEPDocumentsState => state.aepDocumentsState;
