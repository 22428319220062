import {
  Col,
  Row,
  Button,
} from 'react-bootstrap';
import styled from 'styled-components';

export const Item = styled.div`
  display: flex;
  padding: 0.3rem;
  justify-content: space-between;
  border-radius: 0.2rem;
  background-color: #fff6;
  margin-bottom: 0.3rem;
  flex-direction: row;
  &.sortable-ghost {
    background-color: #c8ebfb;
  }
  &.wrong-elements {
    flex-direction: row;
    background-color: #ff8080!important;

    &.order-label {
      color: white;
      align-self: flex-end;
    }
  }
  .info-icon {
    margin-left: 4px;
  }

  &.inprogress-elements {
    flex-direction: row;
    background-color: #ff808080!important;

    &.order-label {
      color: white;
      align-self: flex-end;
    }
  }

  &.locked-elements {
    flex-direction: row;
    background-color: #c0c0c0;

    &.order-label {
      color: white;
      align-self: flex-end;
    }
  }

  &.drag-elements {
    flex-direction: row;
    background-color: #f5f5f5;
    cursor: grab;
    &.order-label {
      color: white;
      align-self: flex-end;
    }
  }
`;

export const ColWithoutPadding = styled(Col)`
  padding: 0;
  justify-content: space-between;
`;

export const ColWithSelect = styled(Col)`
  padding-right: 1em;
  padding-left: 0;
`;

export const Col10PxPadding = styled(Col)`
  padding-left: 10px;
  justify-content: space-between;
`;

export const BageRow = styled(Row)`
  padding-left: 10px;
  justify-content: flex-end;
`;

export const SectionLabel = styled.span`
  font-weight: bolder;
  color: #093222
  margin-right: 1em;
`;

export const ListItemButton = styled(Button)`
  margin-right: 1em;
  padding: .1rem .5rem;
`;

export const RetakeLabel = styled.span`
  color: #1c7430;
  font-weight: 800;
  margin-right: 1em;
`;

export const NotAvailableLabel = styled.span`
  color: #ff0000;
  font-weight: 800;
  margin-right: 1em;
`;
