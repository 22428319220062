// @ts-ignore
import React, { useState } from 'react';
import {
  Button, Row, Col, Form, Spinner,
} from 'react-bootstrap';
import { FiSave, FiEdit } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import PhoneInput from 'react-phone-input-2';

import {
  EditingCol,
  EditingButtonsRow,
  FieldRow,
  EditButton,
} from '../../editing-component/styled-component';
import {
  INewFieldValue,
} from '../../admissions.model';
import 'react-phone-input-2/lib/style.css';
import { APPLICANT_STATUS } from '../../../constants/common-constants';


interface IProps {
  oldPhoneCode:string | any;
  oldPhoneNumber: string | any;
  canEdit: boolean;
  propertyName: string;
  changedFieldUrl: string;
  handleNewValue: (newValue: INewFieldValue, changedFieldUrl: string) => Promise<void>;
  applicationStatus: string | any;
}

const FieldEditorPhone: React.FunctionComponent<IProps> = ({
  oldPhoneCode, oldPhoneNumber,
  propertyName,
  changedFieldUrl,
  handleNewValue,
  applicationStatus,
}: IProps) => {
  const fullPhoneValue = `${oldPhoneCode}${oldPhoneNumber}`;
  const [editing, setEditing] = useState(false);
  const [fieldValueCode, setNewFieldValueCode] = useState(oldPhoneCode);
  const [fieldValueNumber, setNewFieldValueNumber] = useState(oldPhoneNumber);
  const [validationError, setFormError] = useState<null | string>(null);


  // @ts-ignore
  const edit = () => {
    setEditing(true);
    setNewFieldValueCode(fieldValueCode);
    setNewFieldValueNumber(fieldValueNumber);
  };

  const cancel = () => {
    setEditing(false);
    setNewFieldValueCode(fieldValueCode);
    setNewFieldValueNumber(fieldValueNumber);
    setFormError(null);
  };

  const save = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    const payload = {
      PhoneCountryCode: fieldValueCode,
      PhoneNumber: fieldValueNumber,
    };
    await handleNewValue(payload, changedFieldUrl);
    setEditing(false);
  };

  const onPhoneChange = (newValue: any, { dialCode }: any) => {
    setNewFieldValueCode(dialCode);
    setNewFieldValueNumber(newValue.slice(dialCode.length));
  };

  if (editing) {
    // eslint-disable-next-line no-console
    return (
      <>
        <Form onSubmit={save}>
          <Row>
            <EditingCol xs={9}>
              {
                !oldPhoneCode || !oldPhoneNumber
                  ? <Spinner animation="border" size="sm" />
                  : (
                    <PhoneInput
                      value={`${oldPhoneCode}${oldPhoneNumber}`}
                      onChange={onPhoneChange}
                      countryCodeEditable={false}
                      disableCountryCode={false}
                    />
                  )
              }

            </EditingCol>

            <Col xs={3}>
              <EditingButtonsRow>
                <Button variant="outline-secondary" size="sm" disabled={!!validationError} type="submit">
                  <FiSave />
                </Button>
                <Button variant="outline-secondary" size="sm" onClick={cancel}>
                  <GiCancel />
                </Button>
              </EditingButtonsRow>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col id={propertyName} xs={9}>
          <span>
            {fullPhoneValue}
          </span>
        </Col>
        <Col xs={3}>
          <FieldRow>
            {(
              (applicationStatus && applicationStatus === APPLICANT_STATUS.ENROLLING)
                || (applicationStatus && applicationStatus === APPLICANT_STATUS.SUBMITTED)
                || (applicationStatus && applicationStatus === APPLICANT_STATUS.REGISTRATION)
            ) && (
            <EditButton variant="outline-secondary" size="sm" onClick={edit}>
              <FiEdit />
            </EditButton>
            )}
          </FieldRow>
        </Col>
      </Row>
    </>
  );
};

export default FieldEditorPhone;
