import React from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import AuthContext from '../../../app/authProvider';
import {
  ProgramStatusProps,
  ICommonLearanerStatusState,
  IProps,
} from './program-status.model';
import ProgramsStatusEditingModal from './program-status-editing-modal/program-status-editing-modal';
import withPermission from '../../helper-components/view-permission.component';
import withLoading from '../../helper-components/loading.component';
import { EditingInput } from './styled-components';

class ProgramStatus extends React.PureComponent<
IProps,
ICommonLearanerStatusState
> {
  constructor(props: ProgramStatusProps) {
    super(props);

    this.state = {
      show: false,
    };
  }

  private handleModal(show: boolean) {
    this.setState({ show });
  }

  public render(): JSX.Element {
    const {
      programStatus,
      canEditProgramStatus,
      programEnrollmentId,
    } = this.props;
    const { show } = this.state;
    return (
      <>
        <EditingInput className="xs-6">
          <FormControl
            readOnly
            aria-describedby="basic-addon2"
            size="sm"
            value={programStatus}
          />
          <InputGroup.Append>
            {canEditProgramStatus
                    && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => this.handleModal(true)}
                    >
                      <FiEdit />
                    </Button>
                    )}
            <AuthContext.Consumer>
              {(context) => context
              && (
              <ProgramsStatusEditingModal
                programEnrollmentId={programEnrollmentId}
                getBearerToken={context.getBearerToken}
                learnerId={context.learnerId}
                show={show}
                handleClose={() => this.handleModal(false)}
              />
              )}
            </AuthContext.Consumer>
          </InputGroup.Append>
        </EditingInput>

      </>
    );
  }
}

export default withLoading(withPermission(connect()(ProgramStatus)));
