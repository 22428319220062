/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import EventPropComment from './utils/event-prop-comment';
import EventPropStartDate from './utils/event-prop-start-date';

export const admissionAEPExtendedEventSelector: (event: IEvent) => IEventDetails = (event: IEvent) => ({
  metadata: {
    type: {
      category: 'Program Enrollment',
      eventName: 'AEP Extended',
    },
    date: event.updatedDateTime,
    editor: event.editor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <EventPropStartDate name="New Deadline:" value={event.deadline} primary />
      <EventPropStartDate name="Previous Deadline:" value={event.previousDeadline} />
      <EventPropComment value={event.comment} />
    </Row>
  );
};
