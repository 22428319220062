import React from 'react';
import { usePermissions } from './hooks/use-permissions';
import { Permission } from '../../shared/models/permission';
import { SuspenseContainer } from '../suspense-container/suspense-container';

type Props = {
  permissions: Permission[];
  children: React.ReactNode;
};

const Content = ({ permissions, children }: Props) => {
  const { data } = usePermissions({ suspense: true });

  const hasPermission = permissions.some((permission) => data?.[permission]);

  if (!hasPermission) {
    throw new Error("You don't have permission to see this page");
  }

  return <>{children}</>;
};

export const PermissionContainer = ({ permissions, children }: Props) => (
  <SuspenseContainer
    loadingText="Checking permissions..."
    errorText="You don't have permission to view this page"
  >
    <Content permissions={permissions}>{children}</Content>
  </SuspenseContainer>
);
