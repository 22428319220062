import { Dispatch } from 'redux';
import FilterValues from '../../components/learner-search/models/FilterValues';
import SearchResponse from '../../components/learner-search/models/SearchResponse';
import SearchResult from '../../components/learner-search/models/SearchResult';

interface IGetFilterValuesAction {
  type: 'learnerSearch/GET_FILTER_VALUES_FULFILLED';
  payload: {
    filterValues: FilterValues;
  };
}

interface IGetFilterValuesPendingAction {
  type: 'learnerSearch/GET_FILTER_VALUES_PENDING';
}

interface IGetFilterValuesRejectedAction {
  type: 'learnerSearch/GET_FILTER_VALUES_REJECTED';
  payload: {
    error: string;
  };
}

interface IGetSearchResultsAction {
  type: 'learnerSearch/GET_SEARCH_RESULTS_FULFILLED';
  payload: {
    searchResponse: SearchResponse;
  };
}

interface IGetSearchResultsPendingAction {
  type: 'learnerSearch/GET_SEARCH_RESULTS_PENDING';
}

interface IGetSearchResultsRejectedAction {
  type: 'learnerSearch/GET_SEARCH_RESULTS_REJECTED';
  payload: {
    error: string;
  };
}

const initialState = {
  filterStatus: 'Loading' as 'Loading' | 'Errored' | 'Loaded',
  resultStatus: 'Empty' as 'Empty' | 'Loading'| 'Errored' | 'Loaded',
  filterValues: {} as FilterValues,
  searchResponse: {} as SearchResponse,
  error: '',
};

type KnownAction =
IGetFilterValuesAction
| IGetFilterValuesPendingAction
| IGetFilterValuesRejectedAction
| IGetSearchResultsAction
| IGetSearchResultsPendingAction
| IGetSearchResultsRejectedAction
;

export type LearnerSearchState = Readonly<typeof initialState>;

export default (state: LearnerSearchState = initialState, action: KnownAction):
LearnerSearchState => {
  switch (action.type) {
    case 'learnerSearch/GET_FILTER_VALUES_PENDING':
      return {
        ...state,
        filterStatus: 'Loading',
      };
    case 'learnerSearch/GET_FILTER_VALUES_REJECTED':
      return {
        ...state,
        filterStatus: 'Errored',
        error: action.payload.error,
      };
    case 'learnerSearch/GET_FILTER_VALUES_FULFILLED':
      return {
        ...state,
        filterStatus: 'Loaded',
        filterValues: action.payload.filterValues,
        error: '',
      };
    case 'learnerSearch/GET_SEARCH_RESULTS_PENDING':
      return {
        ...state,
        resultStatus: 'Loading',
      };
    case 'learnerSearch/GET_SEARCH_RESULTS_REJECTED':
      return {
        ...state,
        resultStatus: 'Errored',
        error: action.payload.error,
      };
    case 'learnerSearch/GET_SEARCH_RESULTS_FULFILLED':
      return {
        ...state,
        resultStatus: 'Loaded',
        searchResponse: action.payload.searchResponse,
        error: '',
      };
    default:
      return state;
  }
};

export const loadFilterValues = (
  getBearerToken: () => Promise<string>,
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: 'learnerSearch/GET_FILTER_VALUES_PENDING' });
    const token = await getBearerToken();
    const response = await fetch('/api/learner-search/filter-values', {
      method: 'GET',
      headers: [['Authorization', `Bearer ${token}`]],
    });
    if (response.ok) {
      const filterValues = (await response.json()) as FilterValues;
      dispatch({
        type: 'learnerSearch/GET_FILTER_VALUES_FULFILLED',
        payload: { filterValues },
      });
    } else {
      dispatch({
        type: 'learnerSearch/GET_FILTER_VALUES_REJECTED',
        payload: { response },
      });
    }
  } catch (error) {
    dispatch({
      type: 'learnerSearch/GET_FILTER_VALUES_REJECTED',
      payload: { error },
    });
  }
};


export const getSearchResults = (
  getBearerToken: () => Promise<string>, searchString: string,
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: 'learnerSearch/GET_SEARCH_RESULTS_PENDING' });
    const token = await getBearerToken();
    const response = await fetch(`/api/learner-search/${searchString}`, {
      method: 'GET',
      headers: [['Authorization', `Bearer ${token}`]],
    });
    if (response.ok) {
      const resp = (await response.json());
      const searchResponse = {
        Count: resp['@odata.count'],
        Results: resp.value as SearchResult[],
      };
      dispatch({
        type: 'learnerSearch/GET_SEARCH_RESULTS_FULFILLED',
        payload: { searchResponse },
      });
    } else {
      dispatch({
        type: 'learnerSearch/GET_SEARCH_RESULTS_REJECTED',
        payload: { response },
      });
    }
  } catch (error) {
    dispatch({
      type: 'learnerSearch/GET_SEARCH_RESULTS_REJECTED',
      payload: { error },
    });
  }
};
