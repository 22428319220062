/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { IEvent, IEventDetails } from '../learner-history-model';
import NopEvent from './nop-event';

export const neoLearnerReactivatedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'System Access',
      eventName: 'NEO Learner Reactivated',
    },
    date: event.updatedDateTime,
  },
  element: <NopEvent event={event} />,
});
