import React from 'react';
import { Form } from 'react-final-form';
import { useMutation } from '@tanstack/react-query';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import styles from './form.module.scss';
import { PartnerAgreementFormValues } from '../../types';
import { ApplicationFee } from './application-fee';
import { AgreementDates } from './agreement-dates';
import { ProductsForm } from './products-form';
import { ProductsTableForm } from './products-table-form';
import { FormActions } from './form-actions';
import api from '../../../../../shared/api/adminUI.api';
import {
  AgreementTuition,
  CreatePartnerAgreementRequest,
} from '../../../../../models/Partner';
import { usePartnerAgreementsParams } from '../../hooks/use-params';
import { useAuthContext } from '../../../../auth/auth-context';
import { HelperText } from '../../../../helper-text/helper-text';
import { AppError } from '../../../../../shared/api/app-error';
import { useAgreementInitialValues } from './use-initial-values';
import { useLatestAgreement } from '../../hooks/use-latest-agreement';
import { FormField } from '../../../components/form-field/form-field';
import {
  composeValidators,
  minLength,
  required,
} from '../../../../../utils/validate.utils';

export const PartnerAgreementsForm = () => {
  const history = useHistory();
  const { partnerId } = usePartnerAgreementsParams();
  const { getBearerToken } = useAuthContext();
  const { data: latestAgreement } = useLatestAgreement();

  const initialValues = useAgreementInitialValues();

  const { mutateAsync: createAgreement, error: createError } = useMutation({
    mutationFn: (payload: CreatePartnerAgreementRequest) => api.partners.createAgreement(partnerId, payload, getBearerToken),
  });
  const { mutateAsync: updateAgreement, error: updateError } = useMutation({
    mutationFn: (payload: CreatePartnerAgreementRequest) => api.partners.updateAgreement(
      partnerId,
      latestAgreement?.agreementId!,
      payload,
      getBearerToken,
    ),
  });

  const onSubmit = async (data: PartnerAgreementFormValues) => {
    try {
      const agreementTuitions = data.agreementTuitions
        .filter((agreement) => agreement.enabled)
        .map((agreement) => agreement.tierTypeIds
          .filter((tier) => Boolean(tier.value))
          .map<AgreementTuition>((tier) => ({
          productGroup: agreement.productId,
          tier: tier.id,
          planId: tier.value,
        })))
        .flat();

      const fn = latestAgreement ? updateAgreement : createAgreement;
      await fn({
        ...data,
        applicationFeeAmount: +data.applicationFeeAmount,
        startDate: moment(data.startDate).toISOString(),
        endDate: data.endDate ? moment(data.endDate).toISOString() : undefined,
        agreementTuitions,
      });

      toast.success(
        latestAgreement
          ? 'Agreement updated successfully'
          : 'Agreement created successfully',
      );
      history.push(`/partners/${partnerId}`);
    } catch (err) {
      console.error('createAgreement error', err);
    }
  };

  const error = createError || updateError;
  const appError = error ? AppError.fromError(error) : null;

  return (
    <Form<PartnerAgreementFormValues>
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={cn(styles.row, styles.inline)}>
            <AgreementDates />
            <FormField
              name="enrollmentAgreementId"
              label="Enrollment Agreement"
              validate={composeValidators(required, minLength(3))}
            />
          </div>
          <ApplicationFee />
          <ProductsForm />
          <ProductsTableForm />
          {appError ? (
            <HelperText variant="error">{appError.message}</HelperText>
          ) : null}
          <FormActions />
        </form>
      )}
    />
  );
};
