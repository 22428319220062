import React from 'react';
import {
  InputGroup, FormControl, Button,
} from 'react-bootstrap';
import { FiCalendar } from 'react-icons/fi';
import { connect } from 'react-redux';
import moment from 'moment';
import AuthContext from '../../../app/authProvider';
import {
  ICommonStartDateState,
  IStartDateCommonProps,
  IStartDateStateProps,
} from './start-date.model';
import StartDateEditingModal from './start-date-modal/start-date-modal';
import { EditingInput, InputRow, DefferdLabel } from './styled-components';
import withPermission from '../../helper-components/view-permission.component';
import withLoading from '../../helper-components/loading.component';
import { getDeferredStatus } from '../../../shared/selectors/start-date.selector';
import { getProgramStatusLabel } from '../../../shared/selectors/learner-info-edit-modal.selector';
import { IRootState } from '../../../shared/reducers';

const DATE_NOT_SET = 'Date not set';
const TODAYS_DAY = moment(moment().format('DD MMM YYYY'));

class StartDate extends React.PureComponent<
IStartDateCommonProps,
ICommonStartDateState
> {
  constructor(props: IStartDateCommonProps) {
    super(props);
    this.state = {
      show: false,
      deferredStatus: props.deferred,
    };
  }

  public componentDidUpdate(prevProps: IStartDateCommonProps): void {
    const { deferred } = this.props;
    const prevDeferredStatus = prevProps.deferred;
    if (deferred !== prevDeferredStatus) {
      this.updateDeferredStatus(deferred);
    }
  }

  private updateDeferredStatus(deferredStatus: boolean) {
    this.setState({ deferredStatus });
  }

  private handleModal(show: boolean) {
    this.setState({ show });
  }

  private checkIfNotTooLateToChangeStartDate() {
    const { startDate, disableStartDateAfterXDays } = this.props;
    if (startDate) {
      const daysNeddedToDisableStartDate = moment(
        startDate,
      ).add(disableStartDateAfterXDays, 'days');
      return (TODAYS_DAY).isBefore(daysNeddedToDisableStartDate);
    }

    return false;
  }

  public render(): JSX.Element {
    const {
      startDate,
      canEditProgramStatus,
      programStatus,
      comment,
      enrolledDateTime,
      programEnrollmentId,
      maxDeferralMonths,
      disableStartDateAfterXDays,
      daysBeforeNextPeriod,
    } = this.props;
    const { show, deferredStatus } = this.state;
    const isDate = startDate && moment(startDate).isValid();
    const formatedDate = startDate ? moment(startDate).format('DD MMM YYYY') : '';
    const startDateInputValue = isDate ? formatedDate : DATE_NOT_SET;
    const isDateVaild = startDateInputValue !== DATE_NOT_SET;
    const isStatusEnrolled = programStatus === 'Enrolled';
    const canEditStartDate = canEditProgramStatus && isDateVaild
      && this.checkIfNotTooLateToChangeStartDate()
      && isStatusEnrolled;
    return (
      <>
        <InputRow>
          <EditingInput className="xs-6">
            <FormControl
              readOnly
              aria-describedby="basic-addon2"
              size="sm"
              value={startDateInputValue}
            />
            <InputGroup.Append>
              {canEditStartDate
                && (
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => this.handleModal(true)}
                  >
                    <FiCalendar />
                  </Button>
                )}
              <AuthContext.Consumer>
                {(context) => context
                  && (
                    <StartDateEditingModal
                      startDate={startDate}
                      comment={comment}
                      maxDeferralMonths={maxDeferralMonths}
                      disableStartDateAfterXDays={disableStartDateAfterXDays}
                      daysBeforeNextPeriod={daysBeforeNextPeriod}
                      programEnrollmentId={programEnrollmentId}
                      enrolledDateTime={enrolledDateTime}
                      getBearerToken={context.getBearerToken}
                      learnerId={context.learnerId}
                      show={show}
                      handleClose={() => this.handleModal(false)}
                    />
                  )}

              </AuthContext.Consumer>

            </InputGroup.Append>
          </EditingInput>
          {deferredStatus && <DefferdLabel>Deferred</DefferdLabel>}
        </InputRow>
      </>
    );
  }
}

const mapStateToProps = (state: IRootState): IStartDateStateProps => ({
  programStatus: getProgramStatusLabel(state),
  deferred: getDeferredStatus(state),
});


export default withLoading(withPermission(connect(mapStateToProps)(StartDate)));
