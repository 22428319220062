import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { SuspenseContainer } from '../../../../suspense-container/suspense-container';
import { usePartnerDetailsParams } from '../../hooks/use-partner-details-params';
import api from '../../../../../shared/api/adminUI.api';
import { useAuthContext } from '../../../../auth/auth-context';
import { StripedTable } from '../../../styled.components';
import { HelperText } from '../../../../helper-text/helper-text';
import styles from './partner-learners-table.module.scss';
import ProgramEnrollment from '../../../../../models/ProgramEnrollment';

const Placeholder = ({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>) => (
  <tbody className={className}>
    <tr>
      <td colSpan={3}>{children}</td>
    </tr>
  </tbody>
);

type RowProps = {
  enrollment: ProgramEnrollment;
};

const Row = ({ enrollment }: RowProps) => (
  <tr>
    <td>
      <Link to={`/learners/${enrollment.learnerId}`}>
        {enrollment.learnerId}
      </Link>
    </td>
    <td>{enrollment.productCode}</td>
    <td>{enrollment.programStatusName}</td>
  </tr>
);

const Body = () => {
  const { partnerId } = usePartnerDetailsParams();
  const { getBearerToken } = useAuthContext();

  const { data: enrollments } = useQuery({
    queryKey: ['partners', partnerId, 'learners'],
    queryFn: () => api.partners.programEnrollments(partnerId, getBearerToken),
    suspense: true,
  });

  if (!enrollments?.length) {
    return (
      <Placeholder>
        <HelperText className={styles.noLearner}>
          No learners found for this partner
        </HelperText>
      </Placeholder>
    );
  }

  return (
    <tbody>
      {enrollments.map((enrollment) => (
        <Row key={enrollment.learnerId} enrollment={enrollment} />
      ))}
    </tbody>
  );
};

export const PartnerLearnersTable = () => (
  <div>
    <Tabs defaultActiveKey="learners" id="learnerInfoTabs">
      <Tab eventKey="learners" title="Learners">
        <StripedTable className={styles.table}>
          <thead>
            <tr>
              <th>Learner Id</th>
              <th>Program</th>
              <th>Status</th>
            </tr>
          </thead>
          <SuspenseContainer
            container={Placeholder}
            loadingText="Loading learners..."
          >
            <Body />
          </SuspenseContainer>
        </StripedTable>
      </Tab>
    </Tabs>
  </div>
);
