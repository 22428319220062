/* eslint-disable import/prefer-default-export */
import {
  Container,
} from 'react-bootstrap';
import styled from 'styled-components';

export const LearningPathContainer = styled(Container)`
  font-family: "Open Sans";
  text-align: left;
  margin-bottom: 20px;
  padding: 20px;
`;

export const MaxCourseContainer = styled(Container)`
  font-family: "Open Sans";
  text-align: left;
  margin-bottom: 2px;
  padding-top: 5px;
  padding-left: 20px;
`;
