/* eslint-disable max-len */

import React from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import moment from 'moment';
import AuthContext from '../../../app/authProvider';
import {
  IAepDeadlineStateProps,
  IAepDeadlineState,
  IDispatchProps,
  IExtendAepDeadlineRequest,
  IProps,
} from './aep-deadline.model';

import { getLearnerAepDeadlineInfo, extendLearnerAepDeadlineInfo } from '../../../shared/actions/aep-deadline.action';
import { IRootState } from '../../../shared/reducers';
import AepExtensionEditor from './aep-extension-editor-modal/aep-extension-editor-modal';
import { IAepDeadlineFormValues } from './aep-extension-editor-modal/aep-extension-editor-modal.model';
import api, { IUploadData, IUploadFormData, LEARNER_FILE_TYPE } from '../../../shared/api/adminUI.api';
import fileUploadService, { UploadFileType } from '../../../shared/uploads/upload-service';
import { IFileUploadResponse } from '../../learner-files/learner-files.model';
import { isCurrentDate } from '../../helper-components/form-components/form-filed-components/form-filed.components';
import { getCurrentUniversityDate } from '../../../utils/date.utils';
import errorHandling from '../../helper-components/alert-component.component';
import UniversityDate from '../../university-date';

class AepDeadline extends React.Component<IProps, IAepDeadlineState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      aepExtensionPending: false,
    };
  }

  public componentDidMount(): void {
    const {
      canViewAepDeadline,
      getLearnerAEPDeadline,
    } = this.props;
    const { learnerId, getBearerToken } = this.context;
    if (canViewAepDeadline) {
      getLearnerAEPDeadline(getBearerToken, learnerId);
    }
  }

  private submitUploadForm = async (
    learnerId: string,
    getBearerToken: () => Promise<string>,
    fileType: string,
    uploadFormData: IUploadData,
  ): Promise<void> => {
    try {
      const response = await api.documentUpload.submitUploadForm(
        learnerId,
        getBearerToken,
        fileType,
        uploadFormData,
      );
      if (!response.ok) throw response;
    } catch (error) {
      errorHandling({ error });
    }
  };

  private uploadFile = async (
    learnerId: string,
    getBearerToken: () => Promise<string>,
    file: File,
    type: UploadFileType,
    uploadFormData: IUploadFormData,
  ): Promise<void> => {
    const { uploadDate } = uploadFormData;
    try {
      const token = await getBearerToken();

      const fileType = LEARNER_FILE_TYPE;

      const response = await fileUploadService.uploadFileByFileType(
        token,
        learnerId,
        fileType,
        type,
        file,
      );
      const fileUploadResponse = (await response) as IFileUploadResponse;
      const uploadedFileName = fileUploadResponse.fileName;
      const currentTime = `${new Date().getHours()}:${new Date().getMinutes().toString().padStart(2, '0')}`;

      const uploadData = {
        uploadedFileName,
        ...uploadFormData,
        uploadDate: `${moment(uploadDate).format('YYYY-MM-DD')} ${isCurrentDate(uploadDate) ? currentTime : ''}`,
      };
      this.submitUploadForm(learnerId, getBearerToken, type, uploadData);
    } catch (error) {
      errorHandling({ error });
    }
  };

  private handleModal = async (show: boolean) => {
    this.setState({ showModal: show });
  };

  private handleSave = async (formValues: IAepDeadlineFormValues) => {
    this.setState({ aepExtensionPending: true });
    const deadlineDate = new Date(moment(formValues.aepDeadlineDate!).format('YYYY-MM-DD'));
    const aepFileType = 'aep';
    const { learnerId, getBearerToken } = this.context;
    if (formValues.uploadField) {
      const uploadFormData: IUploadFormData = {
        fileName: formValues.fileName,
        uploadDate: formValues.uploadedDate.toDateString(),
        category: aepFileType,
        subcategory: '',
        comment: formValues.comment!,
      };
      await this.uploadFile(learnerId, getBearerToken, formValues.uploadField[0], aepFileType, uploadFormData);
    }

    const { extendLearnerAEPDeadline } = this.props;
    const requestData: IExtendAepDeadlineRequest = {
      deadline: deadlineDate,
      comment: formValues.comment!,
      fileName: formValues.fileName,
    };
    extendLearnerAEPDeadline(learnerId, getBearerToken, requestData);
    this.setState({ showModal: false, aepExtensionPending: false });
  };


  public render(): JSX.Element {
    const {
      deadline,
      comment,
      minDeadline,
      canEditAepDeadline,
      aepDeadlinePending,
    } = this.props;
    const aepDeadlinePassed = deadline && getCurrentUniversityDate().isAfter(deadline);
    const { showModal, aepExtensionPending } = this.state;

    return (
      <>
        {!aepDeadlinePending
          && (
            <Row>
              <Col xs={9} style={{ color: aepDeadlinePassed ? 'red' : 'black' }}>
                <UniversityDate value={deadline} skipTimezoneConversion />
              </Col>
              {canEditAepDeadline
                && (
                  <Col xs={3}>
                    <Row style={{ justifyContent: 'flex-end', margin: 0 }}>
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => { this.handleModal(true); }}
                      >
                        <FiEdit />
                      </Button>
                    </Row>
                  </Col>
                )}
            </Row>
          )}
        {showModal
          && (
            <AepExtensionEditor
              comment={comment}
              aepDeadline={deadline}
              aepMinDeadline={minDeadline}
              handleSave={this.handleSave}
              handleClose={this.handleModal}
              showModal={showModal}
              aepExtensionPending={aepExtensionPending}
            />
          )}
      </>
    );
  }
}

AepDeadline.contextType = AuthContext;

const mapStateToProps = (state: IRootState): IAepDeadlineStateProps => ({
  ...state.aepDeadlineState.aepDeadline,
  aepDeadlinePending: state.aepDeadlineState.aepDeadlinePending,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>,
): IDispatchProps => (
  {
    getLearnerAEPDeadline: (token: () => Promise<string>, learnerId: string):
    void => {
      dispatch(getLearnerAepDeadlineInfo(token, learnerId));
    },
    extendLearnerAEPDeadline: (
      learnerId: string,
      token: () => Promise<string>,
      requestData: IExtendAepDeadlineRequest,
    ): void => {
      dispatch(extendLearnerAepDeadlineInfo(learnerId, token, requestData));
    },

  });

export default connect(mapStateToProps, mapDispatchToProps)(AepDeadline);
