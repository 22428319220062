import React from 'react';
import { usePartner } from '../../hooks/use-partner';
import { usePartnerDetailsParams } from '../hooks/use-partner-details-params';
import { Breadcrumb } from '../../../breadcrumb/breadcrumb';

export const PartnerDetailsBreadcrumb = () => {
  const { partnerId } = usePartnerDetailsParams();

  const { data: partner } = usePartner(partnerId, { suspense: true });

  return (
    <Breadcrumb>
      <Breadcrumb.Item to="/">Home</Breadcrumb.Item>
      <Breadcrumb.Item to="/partners">Partners</Breadcrumb.Item>
      <Breadcrumb.Item active>{partner?.accountName}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
