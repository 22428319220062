import { Modal, ModalTitle } from 'react-bootstrap';
import React from 'react';
import styles from './partner-form-modal.module.scss';
import { PartnerForm, PartnerFormProps } from '../partner-form/partner-form';

type Props = {
  show: boolean;
  onHide: () => void;
  title: string;
} & Omit<PartnerFormProps, 'onCancel'>;

export const PartnerFormModal = ({
  show,
  onHide,
  error,
  onSubmit,
  initialValues,
  title,
}: Props) => (
  <Modal size="lg" show={show} onHide={onHide} animation={false}>
    <ModalTitle className={styles.title}>{title}</ModalTitle>
    <div className={styles.content}>
      <PartnerForm
        onSubmit={onSubmit}
        onCancel={onHide}
        initialValues={initialValues}
        error={error}
      />
    </div>
  </Modal>
);
