/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-danger */
/* eslint-disable max-len */

import React from 'react';
import { CommunicationItem, CommunicationsList } from './communications-styled-component';
import errorHandling from '../helper-components/alert-component.component';
import { EmailBodyDialog, LearnerEmail } from './communications-common';
import UniversityDate from '../university-date';

export interface ICommunicationsRecentProps {
  readonly getBearerToken: () => Promise<string>;
}

interface ICommunicationsRecentState {
  emails: LearnerEmail[] | null
  showEmail: LearnerEmail | null;
}


class CommunicationsRecent extends React.Component<ICommunicationsRecentProps, ICommunicationsRecentState> {
  constructor(props:ICommunicationsRecentProps) {
    super(props);
    this.state = {
      emails: null,
      showEmail: null,
    };
  }

  componentDidMount() : void {
    const { getBearerToken } = this.props;
    this.loadEmails(getBearerToken);
  }

  private readonly loadEmails = async (
    getBearerToken: () => Promise<string>,
  ): Promise<void> => {
    try {
      const token = await getBearerToken();
      const response = await fetch('/api/email-sync/emails/recent', {
        method: 'GET',
        headers: [['Authorization', `Bearer ${token}`]],
      });

      if (response.ok) {
        const emails = await response.json() as LearnerEmail[];
        this.setState({ emails });
      } else {
        throw await response;
      }
    } catch (error) {
      errorHandling({ error });
    }
  };

  render(): JSX.Element {
    const { emails, showEmail } = this.state;
    if (emails == null) return <>Loading...</>;

    const { getBearerToken } = this.props;

    return <>
      {showEmail && <EmailBodyDialog getBearerToken={getBearerToken} email={showEmail} close={() => this.setState({ showEmail: null })} />}
      <CommunicationsList>
        {emails.map((email) => <EmailRow showEmail={() => this.setState({ showEmail: email })} email={email} key={email.emailId} />)}
      </CommunicationsList>
    </>;
  }
}

interface IEmailRowProps {
  email: LearnerEmail;
  showEmail: () => void;
}
const EmailRow = ({ email, showEmail } : IEmailRowProps): JSX.Element => (
  <CommunicationItem onClick={showEmail}>
    <div className="table-cell" style={{ width: '25%' }} title={`From ${email.from} to ${email.to}`}>{email.fromNexford ? email.from : email.to}</div>
    <div className="table-cell" style={{ width: '50%' }}><h5>{email.subject}</h5></div>
    <div className="table-cell" style={{ width: '25%' }}><UniversityDate value={email.timestamp} withTime /></div>
    <div className="table-call" style={{ width: '100%' }}><span>{email.preview}</span></div>
  </CommunicationItem>
);

export default CommunicationsRecent;
