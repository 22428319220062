import React from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import {
  Field, Form, FormRenderProps, FormSpy,
} from 'react-final-form';
import { FormState } from 'final-form';
import { IFormValues } from '../start-date/start-date-modal/start-date-modal.model';
import api from '../../../shared/api/adminUI.api';
import AepStatusModal from './enrollment-modal-aep';
import { MainRow } from '../start-date/start-date-modal/styled-components';
import {
  ConditionalField,
  Error,
  FileUpload,
  renderCheckbox,
  renderDataPicker,
  renderSelectField,
  renderTextInput,
} from '../../helper-components/form-components/form-filed-components/form-filed.components';
import { WithLoading } from '../../helper-components/loading.component';
import { WithPermissions } from '../../helper-components/view-permission.component';
import {
  ICommonEnrollmentModelProps,
  IEnrollmentModelState,
  IFileData,
  IProductsData,
  IReadmisionRequestData,
  ISelectOption,
} from './enrollment-modal.model';
import errorHandling from '../../helper-components/alert-component.component';
import AuthContext from '../../../app/authProvider';
import {
  START_DATE,
  RADIO,
  SELECT,
  PROGRAM_TYPE,
  ACCEPTANCE_TYPE,
  PROGRAM_CODE,
  IS_SAP_REQUIRED,
  SAP_YES,
  SAP_YES_LABEL,
  SAP_NO,
  SAP_NO_LABEL,
  PROVISIONAL,
  FULL,
  UPLOAD_FIELD,
  ACCESS_RESTRICTED,
  LOADING_ACCESS_PERMISSIONS,
  PAYMENT_FREQUENCY,
  DISCOUNT_CODE,
  KEEP_VALUE,
  TEXT,
  READY_REQUEST_STATE,
  PENDING_REQUEST_STATE,
  UNSIGNED_REQUEST_STATE,
} from './enrollment-modal.constants';
import { OK } from '../../admissions/admissions-form/admissions-form.constants';
import { BoldColumn } from '../styled-components';
import './enrollment-modal.scss';
// eslint-disable-next-line import/order
import moment from 'moment';
import plusIcon from '../../../assets/images/plus.svg';
import clockIcon from '../../../assets/images/clock.svg';

class EnrollmentModal extends
  React.PureComponent<ICommonEnrollmentModelProps, IEnrollmentModelState> {
  constructor(props: Readonly<ICommonEnrollmentModelProps>) {
    super(props);

    this.state = {
      show: false,
      productsPending: false,
      productsData: [],
      currentProductType: {
        value: '',
        label: '',
      },
      currentProductCode: {
        value: '',
        label: '',
      },
      currentSapPlan: null,
      currentStartDate: '',
      currentSAPFile: '',
      currentSAPFileUrl: '',
      currentPaymentFrequency: {
        value: '',
        label: '',
        productType: '',
      },
      currentDiscountCode: '',
      currentRequestState: '',
      currentAepStatus: [],
      paymentFrequencyOptions: [],
      readmissionData: null,
      readmissionDataPending: false,
      shouldProductCodeReset: false,
      isChangesHaveBeenMade: true,
      formValues: {},
    };
  }

  componentDidMount() {
    this.getProducts();
    this.getReadmission();
  }

  paymentFrequencyOptionsToSelectOptions : (paymentFrequencyOptions: number[], productType:string) =>
  ISelectOption[] = (frequencyOptions, productType) => frequencyOptions.map((x) => ({
    value: x.toString(),
    label: `Every ${x} Month${x > 1 ? 's' : ''}`,
    productType,
  }));

  getPaymentFrequencies = async (productType: string, productCode: string) => {
    const { getBearerToken } = this.context;
    try {
      const response = await api.admissions.getProgramFrequencies(
        getBearerToken, productType, productCode,
      );

      if (!response.ok) throw response;
      const frequencyOptions = await response.json() as number[];
      const frequencyoptionsData = this.paymentFrequencyOptionsToSelectOptions(frequencyOptions, productType);
      this.setState({ paymentFrequencyOptions: frequencyoptionsData });
    } catch (error) {
      errorHandling(error as any);
    }
  };

  deleteReadmission = async () => {
    const { getBearerToken, learnerId } = this.context;

    try {
      const response = await api.readmission.cancelLearnerReadmissionRequest(
        learnerId,
        getBearerToken,
      );
      if (!response.ok) throw response;
      const deleteData = await response.json();

      if (deleteData) {
        this.resetForm();
      }
    } catch (error) {
      errorHandling(error as any);
    }
  };

  approveReadmission = async (enrollmentFormData: any) => {
    const { getBearerToken, learnerId } = this.context;

    try {
      const response = await api.readmission.approveLearnerReadmissionRequest(
        learnerId,
        enrollmentFormData,
        getBearerToken,
      );

      if (!response.ok) throw response;
      const approveData = await response.json();

      this.setState({
        readmissionData: approveData,
        shouldProductCodeReset: false,
        isChangesHaveBeenMade: true,
        formValues: {},
      });
    } catch (error) {
      errorHandling(error as any);
    }
  };

  createReadmission = async (enrollmentFormData: any) => {
    const { getBearerToken, learnerId } = this.context;
    const { readmissionData } = this.state;

    try {
      let response: any;

      if (readmissionData !== null) {
        response = await api.readmission.updateLearnerReadmissionRequest(
          learnerId,
          enrollmentFormData,
          getBearerToken,
        );
      } else {
        response = await api.readmission.createLearnerReadmissionRequest(
          learnerId,
          enrollmentFormData,
          getBearerToken,
        );
      }
      if (!response.ok) throw response;
      const createData = await response.json();

      this.setState({
        readmissionData: createData,
        shouldProductCodeReset: false,
        isChangesHaveBeenMade: true,
        formValues: {},
      });
      this.getReadmission();
    } catch (error) {
      errorHandling(error as any);
    }
  };

  getReadmission = async () => {
    const { getBearerToken, learnerId } = this.context;

    try {
      this.setState({ readmissionDataPending: true });

      const response = await api.readmission.getLearnerActiveReadmissionRequest(
        learnerId,
        getBearerToken,
      );

      if (!response.ok) throw response;
      const readmissionData = await response.json();

      this.setReadmissionState(readmissionData);

      this.setState({ readmissionData, readmissionDataPending: false });
    } catch (error) {
      errorHandling(error as any);
      this.setState({ readmissionDataPending: false });
    }
  };

  setReadmissionState = (data: any) => {
    if (data !== null) {
      const isSAPNull = data.isSapPlanRequired === null;
      let isSAPRequired;

      if (isSAPNull) {
        isSAPRequired = null;
      } else {
        isSAPRequired = data.isSapPlanRequired ? 'yes' : 'no';
      }
      this.setState({
        currentProductCode: {
          value: data.productCode,
          label: data.productCode,
        },
        currentProductType: {
          value: data.productType,
          label: data.productType,
        },
        currentSapPlan: isSAPRequired,
        currentStartDate: data.startDate,
        currentPaymentFrequency: data.paymentFrequency && this.paymentFrequencyOptionsToSelectOptions([data.paymentFrequency], data.productType)[0],
        currentDiscountCode: data.couponCode,
        currentRequestState: data.requestState,
        currentAepStatus: data.aepDocuments,
        currentSAPFile: data.sapDocumentFileId,
        currentSAPFileUrl: data.sapDocumentUrl,
        isChangesHaveBeenMade: true,
        formValues: {},
      });
      this.getPaymentFrequencies(data.productType, data.productCode);
    }
  };

  getProducts = async () => {
    const { getBearerToken } = this.context;

    try {
      this.setState({ productsPending: true });

      const response = await api.readmission.learnerProducts(
        getBearerToken,
      );

      if (!response.ok) throw response;

      const productsData = await response.json() as IProductsData[];

      this.setState({ productsData, productsPending: false });
    } catch (error) {
      errorHandling(error as any);
      this.setState({ productsPending: false });
    }
  };

  getFileForReadmission = async (file: any) => {
    const { getBearerToken, learnerId } = this.context;
    const extension = file[0].name.split('.').pop();
    const fileObj = file[0];

    try {
      const response = await api.program.getFileForReadmission(
        learnerId,
        fileObj,
        extension,
        getBearerToken,
      );

      if (!response.ok) throw response;
      const fileData: IFileData = await response.json();

      this.setState({ currentSAPFile: fileData.tempFileId });
    } catch (error) {
      errorHandling(error as any);
    }
  };

  resetForm() {
    this.setState(
      {
        currentProductType: {
          value: '',
          label: '',
        },
        currentProductCode: {
          value: '',
          label: '',
        },
        currentSapPlan: null,
        currentStartDate: '',
        currentSAPFile: '',
        currentSAPFileUrl: '',
        currentPaymentFrequency: {
          value: '',
          label: '',
          productType: '',
        },
        currentDiscountCode: '',
        currentRequestState: '',
        currentAepStatus: [],
        readmissionData: null,
        shouldProductCodeReset: false,
        isChangesHaveBeenMade: true,
        formValues: {},
      },
    );
  }

  render(): JSX.Element {
    const {
      show,
      productsPending,
      productsData,
      currentProductType,
      currentProductCode,
      currentSapPlan,
      readmissionDataPending,
      currentStartDate,
      currentSAPFile,
      currentSAPFileUrl,
      currentPaymentFrequency,
      currentDiscountCode,
      currentRequestState,
      currentAepStatus,
      readmissionData,
      shouldProductCodeReset,
      isChangesHaveBeenMade,
      paymentFrequencyOptions,
    } = this.state;
    const {
      permissionPending,
      canViewAepStatus,
      canEditAepStatus,
      permissionsData,
      identityVerified,
    } = this.props;

    const {
      canApproveReadmissionRequest,
      canCancelReadmissionRequest,
      canCreateReadmissionRequest,
      canEditReadmissionRequest,
      canViewReadmissionRequest,
    } = permissionsData;

    const { learnerId } = this.context;
    const isReadmissionData = readmissionData !== null;

    const handleShow = () => {
      this.setState({ show: !show });
    };

    const handleClose = () => {
      this.setState({ show: false, formValues: {} });
      if (!isReadmissionData) {
        this.resetForm();
      } else {
        this.setReadmissionState(readmissionData);
      }
    };

    const handleDelete = () => {
      this.deleteReadmission();
      handleClose();
    };

    const handleApprove = () => {
      const isCurrentSapPlanNull = currentSapPlan === null;
      let currentSapPlanData;
      if (isCurrentSapPlanNull) {
        currentSapPlanData = null;
      } else {
        currentSapPlanData = currentSapPlan === 'yes';
      }
      const enrollmentFormData: IReadmisionRequestData = {
        isSapPlanRequired: currentSapPlanData,
        learnerId,
        productCode: currentProductCode.value,
        productType: currentProductType.value,
        startDate: moment(currentStartDate).format('YYYY-MM-DD'),
        sAPDocuments: currentSAPFile,
        paymentFrequency: Number(currentPaymentFrequency.value),
        couponCode: currentDiscountCode.length > 0 ? currentDiscountCode : null,
        aepDocuments: currentAepStatus,
        sapDocumentFileId: currentSAPFile,
        sapDocumentUrl: currentSAPFileUrl,
      };
      if (canApproveReadmissionRequest) {
        this.approveReadmission(enrollmentFormData);
      }
      handleClose();
    };

    const handleSave = () => {
      const isCurrentSapPlanNull = currentSapPlan === null;
      let currentSapPlanData;
      if (isCurrentSapPlanNull) {
        currentSapPlanData = null;
      } else {
        currentSapPlanData = currentSapPlan === 'yes';
      }
      const enrollmentFormData: IReadmisionRequestData = {
        isSapPlanRequired: currentSapPlanData,
        learnerId,
        productCode: currentProductCode.value,
        productType: currentProductType.value,
        startDate: moment(currentStartDate).format('YYYY-MM-DD'),
        sAPDocuments: currentSAPFile,
        paymentFrequency: currentPaymentFrequency && currentPaymentFrequency.value
          ? Number(currentPaymentFrequency.value) : null,
        couponCode: currentDiscountCode.length > 0 ? currentDiscountCode : null,
        aepDocuments: currentAepStatus,
        sapDocumentFileId: currentSAPFile,
        sapDocumentUrl: currentSAPFileUrl,
      };
      if (canCreateReadmissionRequest) {
        this.createReadmission(enrollmentFormData);
      }
      handleClose();
    };

    const isProductType = currentProductType && (Object.keys(currentProductType).length !== 0);
    const isProductCode = currentProductCode && (Object.keys(currentProductCode).length !== 0);
    const isProductTypeNotEmpty = isProductType && currentProductType.value !== '';
    const isProductCodeNotEmpty = isProductCode && currentProductCode.value !== '';

    const productsTypes: { value: string; label: string; }[] = [];
    const productsCodes: { value: string; label: string; productType: string; }[] = [];

    productsData.map((product) => {
      if (!productsTypes.find((el) => el.value === product.productType)) {
        productsTypes.push({
          value: product.productType,
          label: product.productType,
        });
      }
      if (!productsCodes.find((el) => el.value === product.productCode)) {
        productsCodes.push({
          value: product.productCode,
          label: product.productCode,
          productType: product.productType,
        });
      }
      return true;
    });

    const handleChange = (values: any) => {
      const { formValues } = this.state;
      const {
        programType,
        programCode,
        startDate,
        isSapRequired,
        discountCode,
        uploadFile,
        paymentFrequency,
      } = values;
      let shouldResetStartDate = false;
      const hasIntroDiscountCode = discountCode && discountCode.toUpperCase().startsWith('INT');
      if (programCode && isProductCode && (programCode.value !== currentProductCode.value)) {
        shouldResetStartDate = true;
        if (programCode.productType === programType.value) {
          this.setState({ currentProductCode: programCode });
          this.getPaymentFrequencies(programType.value, programCode.value);
        }
      }

      if (programType && isProductType && (programType.value !== currentProductType.value)) {
        this.setState({ currentProductType: programType });
        shouldResetStartDate = true;
        if (programCode.productType !== programType.value) {
          this.setState({ shouldProductCodeReset: true, paymentFrequencyOptions: [], currentProductCode: { label: '', value: '' } });
        }
      }

      if (JSON.stringify(values) !== JSON.stringify(formValues)) {
        if (Object.keys(formValues).length !== 0) {
          this.setState({ isChangesHaveBeenMade: false }); // Save button is active
        }
        this.setState({ formValues: values });
      }

      this.setState({
        currentStartDate: shouldResetStartDate ? '' : startDate,
        currentSapPlan: isSapRequired,
        currentDiscountCode: discountCode,
      });
      if (uploadFile) {
        const fileFormat = uploadFile[0].type;
        if (fileFormat === 'application/pdf') {
          this.getFileForReadmission(uploadFile);
        }
      }

      if (hasIntroDiscountCode) {
        // Intro discount code overrides payment frequency behaviour and fixes it to 1 month
        const onePaymentFrequencyOption = this.paymentFrequencyOptionsToSelectOptions([1], currentProductType.value)[0];
        if (currentPaymentFrequency.value !== onePaymentFrequencyOption.value) {
          this.setState({ currentPaymentFrequency: onePaymentFrequencyOption });
        }
      } else if (paymentFrequency && paymentFrequency.productType) {
        this.setState({
          currentPaymentFrequency: paymentFrequency.productType === programType.value
            ? paymentFrequency : { label: '', value: '', productType: '' },
        });
      } else {
        this.setState({ currentPaymentFrequency: paymentFrequency });
      }
    };

    const handleAepStatusChange = (aepDocuments: any) => {
      const aepDocumentsFixed: { document: string; status: string; isDefault: boolean }[] = [];

      aepDocuments.map((item: { document: string; status: string; isDefault: boolean }) => {
        aepDocumentsFixed.push({
          document: item.document,
          status: item.status,
          isDefault: item.isDefault,
        });
        return item;
      });

      this.setState({ currentAepStatus: aepDocumentsFixed });
    };

    const handleFileRemove = () => {
      this.setState({ currentSAPFile: '', currentSAPFileUrl: '' });
    };

    const handleSAPUnselect = (e: any) => {
      e.preventDefault();
      this.setState({ currentSapPlan: null });
    };

    const filteredProductCodes = productsCodes.filter((object) => (isProductType
      ? object.productType === currentProductType.value
      : object.productType === productsTypes[0].value));

    const defaultProgramType = isProductTypeNotEmpty ? currentProductType : { label: '', value: '' };
    const defaultProgramCode = isProductCodeNotEmpty ? currentProductCode : { label: '', value: '' };

    let addButton = readmissionDataPending ? <div className="readmission-loader" /> : (
      <Button className="add-button" variant="primary" onClick={handleShow}>
        <img src={plusIcon} alt="Add" />
      </Button>
    );

    if (isReadmissionData) {
      addButton = (
        <Button className="add-button pending-button" variant="primary" onClick={handleShow}>
          <img src={clockIcon} alt="Add" />
          <span className="product-code">{currentProductCode.value}</span>
        </Button>
      );
    } else if (!canEditReadmissionRequest) {
      addButton = <div className="plus-placeholder" />;
    }

    const isStartDateEmpty = currentStartDate === '';
    const isProductCodeEmpty = currentProductCode.value === '';

    const startDateRange = !isProductCodeEmpty
      ? productsData.find((x) => x.productCode === currentProductCode.value)?.startDateRange
      : null;

    const aepCompletedLength = currentAepStatus
      && currentAepStatus.length && currentAepStatus.filter(
      (ad: { status: string; }) => ad.status !== OK,
    );
    const isAepCompleted = aepCompletedLength === 0 || aepCompletedLength.length === 0;
    const isCurrentSAPFile = currentSAPFile !== '' && currentSAPFile !== null;
    const isCurrentPaymentFrequency = currentPaymentFrequency === null
      || currentPaymentFrequency.value === '';
    const isSapNotDone = currentSapPlan === 'yes' && !isCurrentSAPFile;

    const isSaveButtonDisabled = !permissionsData.canEditReadmissionRequest
      || isProductCodeEmpty
      || isStartDateEmpty
      || isSapNotDone;

    const isApproveButtonDisabled = !permissionsData.canApproveReadmissionRequest
      || isSaveButtonDisabled
      || !isAepCompleted
      || !isReadmissionData
      || isCurrentPaymentFrequency
      || currentSapPlan === null
      || isSapNotDone;

    const hasIntroDiscountCode = currentDiscountCode && currentDiscountCode.toUpperCase().startsWith('INT');

    const isRequestReady = currentRequestState === READY_REQUEST_STATE
      || currentRequestState === PENDING_REQUEST_STATE;
    const isFormEditable = canEditReadmissionRequest
      && (isReadmissionData ? isRequestReady : true);
    const isDeleteButtonVisible = canEditReadmissionRequest
      && (isReadmissionData ? isRequestReady
        || currentRequestState === UNSIGNED_REQUEST_STATE : true);

    return (
      <div className={`enrollment-modal-main${canViewReadmissionRequest ? '' : ' hidden-modal-enrollment'}${isReadmissionData ? ' pending-modal-enrollment' : ''}`}>
        {addButton}
        <Modal show={show} onHide={handleClose} className="enrollment-modal-wrapper">
          <Modal.Header closeButton>
            <Modal.Title>Editing</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              initialValues={
                {
                  startDate: currentStartDate || '',
                  acceptanceType: 'full',
                  isSapRequired: currentSapPlan || null,
                  programType: defaultProgramType,
                  programCode: shouldProductCodeReset ? currentProductCode : defaultProgramCode,
                  paymentFrequency: currentPaymentFrequency,
                  discountCode: currentDiscountCode || '',
                }
              }
              onSubmit={handleSave}
              mutators={{
                removeFileUpload: (args, state, utils) => {
                  utils.changeValue(state, 'fileUpload', () => '');
                },
              }}
              render={({
                form,
              }: FormRenderProps<IFormValues> & FormState<any>): JSX.Element => (
                <form data-testid="enrollment-modal-form">
                  {!identityVerified && (
                    <div data-testId="unverified-message" className="unverified-message">
                      Identity verification needed before learner can be admitted to an approved program
                    </div>
                  )}
                  <Col>
                    <WithLoading
                      loading={productsPending}
                      loadingText="Loading Program Data..."
                    >
                      <MainRow>
                        <Col>Program Type</Col>
                        <Col>
                          <ConditionalField
                            type={SELECT}
                            fieldType={KEEP_VALUE}
                            name={PROGRAM_TYPE}
                            changeValue={form.mutators.setValue}
                            options={productsTypes}
                            render={renderSelectField}
                            maxLength={50}
                            disabled={!isFormEditable}
                            aria-label="program type"
                            testID="program-type-field"
                          />
                        </Col>
                      </MainRow>
                      <MainRow>
                        <Col>Program Code</Col>
                        <Col>
                          <ConditionalField
                            type={SELECT}
                            fieldType={KEEP_VALUE}
                            name={PROGRAM_CODE}
                            changeValue={form.mutators.setValue}
                            value={currentProductCode}
                            options={filteredProductCodes}
                            render={renderSelectField}
                            maxLength={50}
                            disabled={!isFormEditable}
                            aria-label="program code"
                            testID="program-code-field"
                          />
                        </Col>
                      </MainRow>
                      <MainRow>
                        <Col>Program Start Date</Col>
                        <Col>
                          <Field
                            render={renderDataPicker}
                            showMonthYearPicker
                            minDate={startDateRange?.minStartDate}
                            maxDate={startDateRange?.maxStartDate}
                            dateFormat="dd MMM yyyy"
                            name={START_DATE}
                            disabled={!isFormEditable || !startDateRange}
                            aria-label="start date"
                            testID="start-date-field"
                          />
                        </Col>
                      </MainRow>
                    </WithLoading>
                    <MainRow className="d-none">
                      <Col xs={4}>
                        <span>Acceptance Type</span>
                      </Col>
                      <Col xs={4}>
                        <Field
                          name={ACCEPTANCE_TYPE}
                          required
                          render={renderCheckbox}
                          type={RADIO}
                          value={FULL}
                          label={FULL}
                          disabled={!isFormEditable}
                        />
                      </Col>
                      <Col xs={4}>
                        <Field
                          name={ACCEPTANCE_TYPE}
                          render={renderCheckbox}
                          type={RADIO}
                          value={PROVISIONAL}
                          label={PROVISIONAL}
                          disabled={!isFormEditable}
                        />
                      </Col>
                    </MainRow>
                    <MainRow>
                      <Col xs={6}>
                        <span>Is a SAP Plan required?</span>
                      </Col>

                      <Col xs={2}>
                        <Field
                          name={IS_SAP_REQUIRED}
                          required
                          render={renderCheckbox}
                          type={RADIO}
                          value={SAP_YES}
                          label={SAP_YES_LABEL}
                          disabled={!isFormEditable}
                        />
                      </Col>
                      <Col xs={2}>
                        <Field
                          name={IS_SAP_REQUIRED}
                          render={renderCheckbox}
                          type={RADIO}
                          value={SAP_NO}
                          label={SAP_NO_LABEL}
                          onClick={form.mutators.removeFileUpload}
                          disabled={!isFormEditable}
                        />
                      </Col>
                      <Col xs={2}>
                        <button type="button" disabled={!isFormEditable} onClick={(e) => handleSAPUnselect(e)} className="sap-unselect">Unselect</button>
                      </Col>
                    </MainRow>
                    {
                      currentSapPlan === 'yes' && (
                        <MainRow className="upload-sap-row">
                          <Col xs={6}>Upload SAP Plan</Col>
                          <Col xs={6}>
                            {currentSAPFileUrl && currentSAPFileUrl.length
                              ? (
                                <div className="sap-file-area">
                                  <a href={currentSAPFileUrl} className="sap-link">SAP file</a>
                                  <button type="button" className="close" onClick={handleFileRemove} disabled={!isFormEditable}>
                                    <span aria-hidden="true">×</span>
                                    <span className="sr-only">Close</span>
                                  </button>
                                </div>
                              )
                              : (
                                <div>
                                  <FileUpload
                                    name={UPLOAD_FIELD}
                                    changeFileNameValue={form.mutators.setValue}
                                    disabled={!isFormEditable}
                                    accept=".pdf"
                                  />
                                  <Error name={UPLOAD_FIELD} multipleErrors />
                                </div>
                              )}
                          </Col>
                        </MainRow>
                      )
                    }
                    <MainRow>
                      <Col xs={6}>AEP Status </Col>
                      <BoldColumn xs={6} id="aepStatus">
                        <WithLoading
                          loading={permissionPending}
                          loadingText={LOADING_ACCESS_PERMISSIONS}
                        >
                          <WithPermissions
                            permission={canViewAepStatus}
                            noAccesText={ACCESS_RESTRICTED}
                          >
                            <AepStatusModal
                              canViewAepStatus={canViewAepStatus}
                              canEditAepStatus={canEditAepStatus}
                              handleAepStatusChange={handleAepStatusChange}
                              currentAepStatus={currentAepStatus}
                              isFormEditable={isFormEditable}
                            />
                          </WithPermissions>
                        </WithLoading>
                      </BoldColumn>
                    </MainRow>
                    <MainRow>
                      <Col>Payment Frequency</Col>
                      <Col>
                        <ConditionalField
                          type={SELECT}
                          fieldType={KEEP_VALUE}
                          name={PAYMENT_FREQUENCY}
                          changeValue={form.mutators.setValue}
                          options={paymentFrequencyOptions}
                          render={renderSelectField}
                          maxLength={50}
                          required
                          disabled={!isFormEditable || hasIntroDiscountCode}
                          aria-label="payment frequency"
                          testID="payment-frequency-field"
                        />
                        {hasIntroDiscountCode && <p>Payment frequency is locked to 1 month with the INT scholarship code</p>}
                      </Col>
                    </MainRow>
                    <MainRow>
                      <Col>Scholarship Code</Col>
                      <Col>
                        <Field
                          type={TEXT}
                          name={DISCOUNT_CODE}
                          changeValue={form.mutators.setValue}
                          render={renderTextInput}
                          maxLength={50}
                          disabled={!isFormEditable}
                        />
                      </Col>
                    </MainRow>
                  </Col>
                  <FormSpy
                    subscription={{ values: true, dirty: true, pristine: true }}
                    onChange={<K extends object>({ values }: FormState<K>): void => {
                      handleChange(values);
                    }}
                  />
                </form>
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            {canCancelReadmissionRequest && isReadmissionData && isDeleteButtonVisible && (
              <Button variant="danger" onClick={handleDelete}>Delete</Button>
            )}
            {canApproveReadmissionRequest && isFormEditable && (
              <Button variant="secondary" onClick={handleApprove} disabled={isApproveButtonDisabled}>Approve</Button>
            )}
            {canCreateReadmissionRequest && isFormEditable && (
              <Button variant="primary" onClick={handleSave} disabled={isSaveButtonDisabled || isChangesHaveBeenMade}>Save</Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

EnrollmentModal.contextType = AuthContext;

export default EnrollmentModal;
