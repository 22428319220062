/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import React from 'react';
import { Row } from 'react-bootstrap';
import { IEvent, IEventDetails, ICustomEventProps } from '../learner-history-model';
import { PropValue } from './utils/event-prop';

export const admissionAEPStatusChangedEventSelector: (event:IEvent) => IEventDetails = (event:IEvent) => ({
  metadata: {
    type: {
      category: 'Program Enrollment',
      eventName: 'AEP Status change',
    },
    date: event.updatedDateTime,
    editor: event.editor,
  },
  element: <Event event={event} />,
});

const Event: React.FunctionComponent<ICustomEventProps> = (props: ICustomEventProps) => {
  const { event } = props;

  return (
    <Row>
      <PropValue value={event.aEP ? 'On AEP' : 'Off AEP'} primary />
    </Row>
  );
};
