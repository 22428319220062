import styled from 'styled-components';
import { Col, Row, Button } from 'react-bootstrap';

export const EditingCol = styled(Col)`
    padding-right: 0;
`;
export const EditingButtonsRow = styled(Row)`
    height: 34px;
`;

export const FieldRow = styled(Row)`
    height: 100%;
    justifyContent: center;  
`;

export const EditButton = styled(Button)`
    height: 34px;
    justify-content: center;  
`;
