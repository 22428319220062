import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useAuthContext } from '../../auth/auth-context';
import api from '../../../shared/api/adminUI.api';
import IPartnersPermission from '../../../models/PartnersPermissions';

type Options = {} & Omit<
UseQueryOptions<IPartnersPermission>,
'queryKey' | 'queryFn'
>;

export const usePermissions = (options: Options = {}) => {
  const { getBearerToken } = useAuthContext();

  return useQuery<IPartnersPermission>({
    queryKey: ['permissions'],
    queryFn: () => api.partners.getPermissions(getBearerToken),
    ...options,
  });
};
