import React, { useState } from 'react';
import { FormControl, Form } from 'react-bootstrap';
import handleValidation from './validation-functions';

interface ITextFieldProps {
  fieldValue: string | any;
  originalValue: string | any;
  fieldType: string;
  validationError: string | null;
  setNewFieldValue: (newValue: string) => void;
  setFormError: (error: string | null) => void;
  paramsValidation?: string| any,
  maxSymbols?: number | undefined,
}

const TextField: React.FunctionComponent<ITextFieldProps> = ({
  fieldValue,
  originalValue,
  fieldType,
  setFormError,
  setNewFieldValue,
  validationError,
  paramsValidation,
  maxSymbols,
}: ITextFieldProps) => {
  const [isDirty, setIsDirty] = useState(false);

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setNewFieldValue(newValue);
    setIsDirty(originalValue !== newValue);
    setFormError(handleValidation(newValue, fieldType, paramsValidation));
  };

  const minSymbols = fieldType === 'number' ? 4 : 1;
  return (
    <>
      <FormControl
        aria-describedby="basic-addon2"
        size="sm"
        required
        minLength={minSymbols}
        maxLength={maxSymbols}
        type={fieldType}
        isInvalid={!!validationError}
        isValid={isDirty && !validationError}
        style={{ fontSize: '1rem' }}
        placeholder={fieldValue}
        onChange={onTextChange}
        value={fieldValue}
      />

      <Form.Control.Feedback type="invalid">
        {validationError}
      </Form.Control.Feedback>
    </>
  );
};
export default TextField;
