import React from 'react';
import { usePartnerAgreementsParams } from '../hooks/use-params';
import { usePartner } from '../../hooks/use-partner';

export const PartnerAgreementsHeader = () => {
  const { partnerId } = usePartnerAgreementsParams();
  const { data } = usePartner(partnerId, {
    suspense: true,
  });

  return (
    <h4>
      <b>Partner Name:</b> {data?.accountName}
    </h4>
  );
};
