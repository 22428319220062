import { IUploadedFile } from '../../models/UploadedFile';
import api, { LEARNER_FILE_TYPE } from '../../shared/api/adminUI.api';

export default {
  deleteLearnerFile: async (
    learnerId: string,
    updatedFile: IUploadedFile,
    getBearerToken: () => Promise<string>,
    isLearnerRegistration?: boolean,
  ) => {
    let response: Response;
    if (updatedFile.fileType === LEARNER_FILE_TYPE || isLearnerRegistration) {
      response = await api.documentUpload
        .deleteLearnerFile(learnerId, updatedFile.fileName, getBearerToken);
    } else {
      response = await api.admissions
        .deleteLearnerFile(learnerId, updatedFile.fileName, getBearerToken);
    }
    return response;
  },
  editLearnerFileProperties: async (
    learnerId: string,
    updatedFile: IUploadedFile,
    getBearerToken: () => Promise<string>,
    isLearnerRegistration?: boolean,
  ) => {
    let response: Response;
    if (updatedFile.fileType === LEARNER_FILE_TYPE || isLearnerRegistration) {
      response = await api.documentUpload
        .editLearnerFileProperties(learnerId, updatedFile, getBearerToken);
    } else {
      response = await api.admissions
        .editLearnerFileProperties(learnerId, updatedFile, getBearerToken);
    }
    return response;
  },
};
