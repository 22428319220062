import { AnyAction } from 'redux';
import {
  ILearnerAEPDeadlineState,
} from './aep-deadline.model';
import {
  GET_AEP_DEADLINE_SUCCESS,
  GET_AEP_DEADLINE_ERROR,
  EXTEND_AEP_DEADLINE,
  GET_AEP_DEADLINE_PENDING,
} from './aep-deadline.constants';


const initialState: ILearnerAEPDeadlineState = {
  aepDeadline: {
    comment: '',
    deadline: new Date(),
    minDeadline: new Date(),
  },
  aepDeadlinePending: true,
  error: null,
};

const aepDeadlineState = (
  state: ILearnerAEPDeadlineState = initialState,
  { type, payload }: AnyAction,
):
ILearnerAEPDeadlineState => {
  switch (type) {
    case GET_AEP_DEADLINE_PENDING:
      return {
        ...state,
        aepDeadlinePending: true,
      };
    case GET_AEP_DEADLINE_SUCCESS:
      return {
        ...state,
        aepDeadlinePending: false,
        aepDeadline: payload,
      };
    case GET_AEP_DEADLINE_ERROR:
      return {
        ...state,
        aepDeadlinePending: false,
        error: payload,
      };
    case EXTEND_AEP_DEADLINE:
      return {
        ...state,
        aepDeadline: payload,
      };
    default:
      return state;
  }
};

export default aepDeadlineState;
