import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AuthProps from '../../auth/auth-props';

interface AdmissionDocumentMatchParams {
  readonly applicantId: string;
  [index: number]: string;
}

interface AdmissionDocumentProps
  extends AuthProps,
  RouteComponentProps<AdmissionDocumentMatchParams> {}

interface AdmissionDocumentState {
  readonly status: 'Loading' | 'Unauthorized' | 'Error' | 'Redirecting';
  readonly applicantId: string;
  readonly documentPath: string;
  readonly documentUrl: string | null;
  readonly errorMessage: string | null;
}

interface DocumentUrlResponse {
  readonly url: string;
}

export default class AdmissionDocument extends React.Component<
AdmissionDocumentProps,
AdmissionDocumentState
> {
  constructor(props: AdmissionDocumentProps) {
    super(props);
    const { match } = this.props;
    this.state = {
      status: 'Loading',
      applicantId: match.params.applicantId,
      documentPath: match.params[0],
      documentUrl: null,
      errorMessage: null,
    };
    this.loadUrl();
  }

  private readonly loadUrl = async (): Promise<void> => {
    const { getBearerToken } = this.props;
    const { applicantId, documentPath } = this.state;
    try {
      const token = await getBearerToken();
      const response = await fetch(
        `/api/admission/applicants/${applicantId}/document-urls/${documentPath}`,
        {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        },
      );
      if (response.ok) {
        const urlResponse = (await response.json()) as DocumentUrlResponse;
        this.setState({
          status: 'Redirecting',
          documentUrl: urlResponse.url,
        });
      } else if (response.status === 403) {
        this.setState({ status: 'Unauthorized' });
      } else {
        this.setState({
          status: 'Error',
          errorMessage: response.statusText,
        });
      }
    } catch (error) {
      this.setState({
        status: 'Error',
        errorMessage: error.message,
      });
    }
  };

  render(): JSX.Element {
    const {
      status,
      applicantId,
      documentPath,
      documentUrl,
      errorMessage,
    } = this.state;
    switch (status) {
      case 'Loading':
        return (
          <>
            {`Loading document ${documentPath} for applicant ${applicantId}...`}
          </>
        );
      case 'Unauthorized':
        return (
          <>
            You are not authorised to view applicant documents.
          </>
        );
      case 'Error':
        return (
          <>
            {`Unexpected error trying to retrieve URL for document ${documentPath} for applicant ${applicantId}:`}
            <pre>{errorMessage}</pre>
          </>
        );
      case 'Redirecting':
        window.location.replace(documentUrl!.toString());
        return (
          <>
            {`Redirecting to document ${documentPath} for applicant ${applicantId}...`}
          </>
        );
      default:
        return <></>;
    }
  }
}
