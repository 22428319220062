/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Spinner, Row } from 'react-bootstrap';
import { LoadingP, StyledLoadingText } from './styled-components';

interface WithLoadingProps {
  loading: boolean;
  spinner?: boolean;
  loadingText: string;
}

const withLoading = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P & WithLoadingProps> => ({
    loading,
    spinner,
    loadingText,
    ...props
  }: WithLoadingProps) => (loading ? (
    spinner ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Row>
          <Spinner animation="border" size="sm" />
          <StyledLoadingText>{loadingText}</StyledLoadingText>
        </Row>
      </div>
    ) : (
      <>
        <LoadingP>{loadingText}</LoadingP>
      </>
    )
  ) : (
    <Component {...(props as P)} />
  ));
export default withLoading;

export const WithLoading = withLoading(({ children }) => <>{children}</>);
