/* eslint-disable import/prefer-default-export */
import {
  Accordion, Row,
} from 'react-bootstrap';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
    .card{
        overflow: visible;
    }
    && {
      .card-header {
        cursor: pointer;
      }
      .row .dropdown a {
        padding-top: 2px;
      }
      .row .dropdown a {
        font-size: 15px;
        padding-top: 4px;
      }
    }
  `;

export const DropRequestRow = styled(Row)`
  align-items: center;
  font-weight: bold;
  margin: 0;
  padding: 10px 0px;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
`;

export const DropRequestStatusRow = styled(Row)`
  font-weight: bold;
  font-size: 14px;
  margin-left: 0;
`;
