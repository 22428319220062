import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useFieldArray } from 'react-final-form-arrays';
import { useField } from 'react-final-form';
import { get } from 'lodash';
import { StripedTable } from '../../../styled.components';
import { FormField } from '../../../components/form-field/form-field';
import { usePropertyValues } from '../../hooks/use-property-values';
import { SuspenseContainer } from '../../../../suspense-container/suspense-container';
import { ProductField } from '../../types';
import { HelperText } from '../../../../helper-text/helper-text';

type RowProps = {
  name: string;
};

const isEmptyTiers = (fields: ProductField['tierTypeIds']) => !fields.some((item) => item.value);

const Row = ({ name }: RowProps) => {
  const { data: propertyValues } = usePropertyValues();

  const field = useField<ProductField>(name);
  const { fields } = useFieldArray(`${name}.tierTypeIds`);

  const product = propertyValues?.productGroups.find(
    (item) => item.id === field.input.value.productId,
  );

  return (
    <tr>
      <td>
        <FormField<boolean>
          reverse
          name={`${name}.enabled`}
          validateFields={[
            'agreementTuition',
            ...fields.map((item) => `${item}.value`),
          ]}
          validate={(value, allValues) => {
            const { tierTypeIds } = get(allValues, name) as ProductField;
            if (value && isEmptyTiers(tierTypeIds)) {
              return 'Please provide at least one tier';
            }

            return undefined;
          }}
          render={({ input }) => (
            <Form.Check
              label={product?.name}
              checked={input.value}
              onChange={(event: ChangeEvent<HTMLInputElement>) => input.onChange(event.target.checked)}
            />
          )}
        />
      </td>
      {fields.map((item) => (
        <td key={item}>
          <FormField
            key={`${item}-field`}
            name={`${item}.value`}
            render={({ input }) => (
              <Form.Control
                type="text"
                value={input.value}
                onChange={input.onChange}
              />
            )}
          />
        </td>
      ))}
    </tr>
  );
};

const Body = () => {
  const { fields } = useFieldArray<ProductField>('agreementTuitions');

  return (
    <tbody>
      {fields.map((name) => (
        <Row key={name} name={name} />
      ))}
    </tbody>
  );
};

const Header = () => {
  const { data: propertyValues } = usePropertyValues();

  return (
    <thead>
      <tr>
        <th>Product</th>
        {propertyValues?.tierTypes.map((tierType) => (
          <th key={tierType.id}>{tierType.name}</th>
        ))}
      </tr>
    </thead>
  );
};

const AgreementTutionsHelperText = () => {
  const { meta } = useField('agreementTuition', {
    validate: (_, allValues) => {
      const agreementTuitions = get(
        allValues,
        'agreementTuitions',
      ) as ProductField[];
      const enabledAgreementTuitions = agreementTuitions.filter(
        (item) => item.enabled,
      );
      return enabledAgreementTuitions.length > 0
        ? undefined
        : 'At least one product must be enabled to save the agreement.';
    },
  });

  const hasError = meta.error && meta.invalid && (meta.submitFailed || meta.submitSucceeded);

  if (!hasError) {
    return null;
  }

  return (
    <HelperText variant="error">
      At least one product must be enabled to save the agreement.
    </HelperText>
  );
};

export const ProductsTableForm = () => (
  <SuspenseContainer
    loadingText="Loading products..."
    errorText="Failed to load products"
  >
    <div>
      <StripedTable>
        <Header />
        <Body />
      </StripedTable>
      <AgreementTutionsHelperText />
    </div>
  </SuspenseContainer>
);
