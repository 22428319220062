import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { IConfiguration, IConfigurationState } from '../reducers/configuration/configuration.model';

export const getConfigurationState = (
  state: IRootState,
): IConfigurationState => state.configurationState;

export const getConfiguration = createSelector(
  [getConfigurationState],
  (
    configurationState: IConfigurationState,
  ): IConfiguration => configurationState.configuration,
);

export const getMaxDeferralMonths = createSelector(
  [getConfigurationState],
  (
    configurationState: IConfigurationState,
  ): number => configurationState.configuration.maxDeferralMonths,
);

export const getDisableScheduleLoaAfterXDays = createSelector(
  [getConfigurationState],
  (
    configurationState: IConfigurationState,
  ): number => configurationState.configuration.disableScheduleLoaAfterXDays,
);

export const getDisableStartDateAfterXDays = createSelector(
  [getConfigurationState],
  (
    configurationState: IConfigurationState,
  ): number => configurationState.configuration.disableStartDateAfterXDays,
);

export const getDaysBeforeNextPeriod = createSelector(
  [getConfigurationState],
  (
    configurationState: IConfigurationState,
  ): number => configurationState.configuration.daysBeforeNextPeriod,
);
