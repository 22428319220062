import React from 'react';

interface IProps {
  value: string;
}

const AcademicStandingStatusIcon: React.FunctionComponent<IProps> = ({
  value,
}: IProps) => {
  if (value === 'hold') {
    return <>✋</>;
  }

  if (value === 'onprobation') {
    return <>🙅</>;
  }

  if (value === 'warning') {
    return <>👎</>;
  }

  if (value === 'good') {
    return <>👍</>;
  }

  if (value === 'dismiss') {
    return null;
  }

  return <></>;
};

export default AcademicStandingStatusIcon;
