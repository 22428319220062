/* eslint-disable object-curly-newline,react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import {
  Button,
} from 'react-bootstrap';
import {
  Limits,
  MaxCourseNumberContainer,
  MaxCourseNumberLabel,
  ValidationError,
} from './styled-components';
import errorHandling from '../../helper-components/alert-component.component';
import IError from '../../../models/Error';

export interface MaxCoursesModel {
  LearnerId:string,
  MaxSimultaneousCourses:number;
  UnlimitedSimultaneousCourses:number;
  LimitText:string,
  LimitedBy:string[];
}

export interface MaxCoursesPermissionObject {
  canEditLearnerMaxSimultaneousCourses: boolean;
}

interface Props {
  learnerId: string,
  getBearerToken: () => Promise<string>,
}

const MaxCourseNumber: React.FunctionComponent<Props> = (props: Props): JSX.Element => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validationError, setValidationError] = useState(false);
  const [newMaxSimultaneousCourses, setNewMaxSimultaneousCourses] = useState(0);
  const [canEdit, setCanEdit] = useState(true);
  const [model, setModel] = useState<MaxCoursesModel>({
    LearnerId: '',
    MaxSimultaneousCourses: 0,
    UnlimitedSimultaneousCourses: 0,
    LimitText: '',
    LimitedBy: [],
  });

  useEffect(() => {
    async function getMaxCourses(token: string, learnerId: string) {
      try {
        const response = await fetch(`/api/course-load/learners/${learnerId}/max-simultaneous-courses`, {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });

        if (response.ok) {
          const data = await response.json() as MaxCoursesModel;
          setModel(data);
          setLoading(false);
        } else {
          throw await response;
        }
      } catch (error) {
        errorHandling({ error: error as IError });
      }
    }

    async function getPermissions(token: string) {
      try {
        const response = await fetch('/api/course-load/permissions', {
          method: 'GET',
          headers: [['Authorization', `Bearer ${token}`]],
        });

        if (response.ok) {
          const permissionObject = await response.json() as MaxCoursesPermissionObject;
          setCanEdit(permissionObject.canEditLearnerMaxSimultaneousCourses);
        } else {
          throw await response;
        }
      } catch (error) {
        errorHandling({ error: error as IError });
      }
    }

    async function getData(learnerId: string, getBearerToken:() => Promise<string>) {
      const token = await getBearerToken();

      getMaxCourses(token, learnerId);
      getPermissions(token);
    }

    getData(props.learnerId, props.getBearerToken);
  }, [props.learnerId, props.getBearerToken]);

  const edit = () => {
    setNewMaxSimultaneousCourses(model.UnlimitedSimultaneousCourses);
    setEditing(true);
  };

  const cancel = () => {
    setEditing(false);
    setValidationError(false);
  };

  const save = async () => {
    const validation = new RegExp('^[0-9]$');
    if (!validation.test(newMaxSimultaneousCourses.toString())) {
      setValidationError(true);
      return;
    }
    cancel();

    try {
      const { getBearerToken, learnerId } = props;
      const token = await getBearerToken();
      const response = await fetch(`/api/course-load/learners/${learnerId}/max-simultaneous-courses`, {
        method: 'PUT',
        headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
        body: JSON.stringify({ MaxSimultaneousCourses: newMaxSimultaneousCourses }),
      });

      if (response.ok) {
        const data = await response.json() as MaxCoursesModel;
        setModel(data);
      } else {
        throw await response;
      }
    } catch (error) {
      errorHandling({ error: error as IError });
    }
  };

  const onNewMaxSimultaneousCoursesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line radix
    const newValue = parseInt(event.target.value);
    setNewMaxSimultaneousCourses(newValue);
  };

  if (editing) {
    return (
      <MaxCourseNumberContainer>
        <MaxCourseNumberLabel>Max courses:</MaxCourseNumberLabel>
        <input
          type="number"
          required
          min="1"
          max="9"
          value={newMaxSimultaneousCourses}
          onChange={onNewMaxSimultaneousCoursesChange}
        />
        <Button variant="success" onClick={save}>Save</Button>
        <Button variant="warning" onClick={cancel}>Cancel</Button>
        {model.LimitText && <Limits>{model.LimitText}</Limits>}
        {validationError && <ValidationError>Range is 1 to 9</ValidationError>}
      </MaxCourseNumberContainer>
    );
  }

  return (
    <MaxCourseNumberContainer>
      <MaxCourseNumberLabel>{`Max courses: ${loading ? '...' : model.UnlimitedSimultaneousCourses}`}</MaxCourseNumberLabel>
      { !loading && canEdit && <Button variant="info" onClick={edit}> Edit </Button> }
      {model.LimitText && <Limits>{model.LimitText}</Limits>}
    </MaxCourseNumberContainer>
  );
};

export default MaxCourseNumber;
