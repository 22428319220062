import React from 'react';
import styled from 'styled-components';
import { PropContainer, PropName } from './event-prop';

const EventPropComment: React.FunctionComponent<{ value: string }> = (
  props: { value: string },
) => {
  const {
    value,
  } = props;

  if (!value) {
    return <></>;
  }

  return (
    <PropContainer>
      <PropName>Comment:</PropName>
      <PropComment>{value}</PropComment>
    </PropContainer>
  );
};

export default EventPropComment;

export const PropComment = styled.span`
  font-weight: 400;
  font-size: 13px;
  font-color: gray;
`;
