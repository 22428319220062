import React from 'react';
import { Badge, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  SectionLabel,
  Item,
  ColWithoutPadding,
  Col10PxPadding,
  BageRow,
  ListItemButton,
  RetakeLabel,
  NotAvailableLabel,
} from './styled-components';
import {
  ICourse, IWrongElement, StatusID, SubstatusID,
} from '../../steps/courses-summary/courses-summary.model';
import { ISelectOption } from '../../../../models/SelectOptions';
import PrerequisitesTooltip from './prerequisites-tooltip';

interface IListItem {
  course: ICourse;
  order: number;
  elementIdsWithWrongPositions: IWrongElement[];
  retakenCoursesIds: string[];
  setRetakeCourse: (course: ICourse) => void;
  deleteCourse: (course: ICourse) => void;
  handleSelectChange: (
    newValue: ISelectOption,
    course: ICourse,
    courseId: string,
  ) => ISelectOption;
}

interface IDeleteButton {
  course: ICourse;
  deleteCourse: (course: ICourse) => void;
}

interface IRetakeLabel {
  course: ICourse;
}

interface ICourseNameSection {
  course: ICourse;
  handleSelectChange: (
    newValue: ISelectOption,
    course: ICourse,
    courseId: string,
  ) => ISelectOption;
  hasPrerequisites: boolean;
  isSelectDisabled: boolean;
}

const setItemClass = (
  isFixed: boolean,
  inProgress: boolean,
  elementId: string,
  elementIdsWithWrongPositions: IWrongElement[],
) => {
  if (isFixed) {
    return 'locked-elements';
  }

  if (inProgress) {
    return 'inprogress-elements drag-elements';
  }

  if (elementIdsWithWrongPositions.length > 0) {
    const idsList = elementIdsWithWrongPositions.map(
      (element: IWrongElement) => element.courseId,
    );

    return idsList.includes(elementId)
      ? 'wrong-elements drag-elements'
      : 'drag-elements';
  }

  return 'drag-elements';
};
const createOptions = (courses: ICourse[]): ISelectOption[] => courses.map(
  (type: ICourse): ISelectOption => ({
    label: `${type.id} ${type.name}`,
    value: type.id,
  }),
);

const setValueForSelect = (courses: ICourse[]): ISelectOption => {
  const emptyOption = {
    label: '-',
    value: '-',
  };
  const selectedValue = courses.find(
    (course: ICourse) => course.isGroupSelection,
  );
  return selectedValue ? createOptions([selectedValue])[0] : emptyOption;
};

const DeleteButton: React.FC<IDeleteButton> = (props: IDeleteButton) => {
  const { course, deleteCourse } = props;

  const isDeleteButtonVisible = !course.inProgress && course.subStatusId !== SubstatusID.Passed;
  return (
    <>
      {isDeleteButtonVisible
      && (
      <ListItemButton
        variant="danger"
        onClick={() => deleteCourse(course)}
      >
        Delete
      </ListItemButton>
      )}
    </>
  );
};

const RetakeLabelSection: React.FC<IRetakeLabel> = (props: IRetakeLabel) => {
  const { course } = props;
  return (
    <>
      {course.subStatusId === SubstatusID.Passed && course.statusId === StatusID.Completed
        ? <RetakeLabel>Retaken</RetakeLabel>
        : <RetakeLabel>Retake</RetakeLabel>}
    </>
  );
};

const CourseNameSection: React.FC<ICourseNameSection> = (props: ICourseNameSection) => {
  const {
    course, handleSelectChange, hasPrerequisites, isSelectDisabled,
  } = props;
  return (
    <>
      {course.courses ? (
        <ColWithoutPadding xs={7}>
          <Select
            isDisabled={isSelectDisabled}
            options={createOptions(course.courses)}
            value={setValueForSelect(course.courses)}
            onChange={(option: any): ISelectOption => handleSelectChange(option, course, course.id)}
          />
          {hasPrerequisites && (
          <FontAwesomeIcon icon={faInfoCircle} />
          )}
        </ColWithoutPadding>
      ) : (
        <Col10PxPadding xs={7}>
          <span>{`${course.id} ${course.name}`}</span>
          <span>
            {hasPrerequisites && (
            <PrerequisitesTooltip prerequisites={course.prerequisites} />
            )}
          </span>
        </Col10PxPadding>
      )}
    </>
  );
};

const isRetakeButtonVisible = (
  course: ICourse,
  isRetaken: boolean,
): boolean => course.statusId === 'completed' && course.subStatusId === 'passed' && !isRetaken && !course.externalTransferred;

const ListItem: React.FunctionComponent<IListItem> = (props: IListItem) => {
  const {
    course,
    elementIdsWithWrongPositions,
    handleSelectChange,
    retakenCoursesIds,
    setRetakeCourse,
    deleteCourse,
    order,
  } = props;

  const hasPrerequisites = course.prerequisites.length > 0;
  const isRetaken = course.retaken > 0;
  const isUnavailable = !course.isCourseAvailable;
  const isPassed = course.subStatusId === 'passed';
  return (
    <Item
      id={course.id + course.retaken}
      className={
        setItemClass(
          course.fixed,
          course.inProgress,
          course.id,
          elementIdsWithWrongPositions,
        )
      }
    >
      <CourseNameSection
        course={course}
        isSelectDisabled={isPassed || isRetaken}
        hasPrerequisites={hasPrerequisites}
        handleSelectChange={handleSelectChange}
      />
      <Col xs={4} style={{ alignItems: 'center' }}>
        <BageRow>
          {isUnavailable && <NotAvailableLabel>Not Available!</NotAvailableLabel>}
          {isRetaken
          && (
            <>
              <DeleteButton course={course} deleteCourse={deleteCourse} />
              <RetakeLabelSection course={course} />
            </>
          )}
          {isRetakeButtonVisible(course, isRetaken)
            && (
            <ListItemButton
              variant="dark"
              disabled={retakenCoursesIds.includes(course.id)}
              onClick={() => setRetakeCourse(course)}
            >
              Retake
            </ListItemButton>
            )}
          <SectionLabel>{course.section}</SectionLabel>
          <Badge variant="secondary">{order + 1}</Badge>
        </BageRow>
      </Col>
    </Item>
  );
};
export default ListItem;
