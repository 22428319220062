/* eslint-disable max-len */

import React from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import AuthContext from '../../../app/authProvider';
import {
  IAepStatusState,
  IAepStatusStateProps,
  IDispatchProps,
  IProps,
} from './aep-status.model';
import { getAepDocumentsState } from '../../../shared/selectors/aep-documents.selector';
import { IRootState } from '../../../shared/reducers';
import { IUpdateAepDocumentsRequest } from '../../learner-files/learner-files.model';
import { getLearnerAepDocumentsInfo, updateLearnerAepDocumentsInfo } from '../../../shared/actions/aep-documents.action';
import AepEditor from './aep-status-editor-modal/aep-editor-modal';
import { IAEPFormValues } from './aep-status-editor-modal/aep-editor-modal.model';
import { OK } from '../../admissions/admissions-form/admissions-form.constants';
import api from '../../../shared/api/adminUI.api';
import { LongText } from '../styled-components';
import StyledStatus from './styled-components';

class AepStatus extends React.Component<IProps, IAepStatusState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
      aepDocuments: [],
    };
  }

  public componentDidMount(): void {
    const {
      getLearnerAEPDocumentsData,
      canViewAepStatus,
      canEditAepStatus,
    } = this.props;
    const { learnerId, getBearerToken } = this.context;
    if (canViewAepStatus) {
      getLearnerAEPDocumentsData(getBearerToken, learnerId);

      if (canEditAepStatus) {
        this.loadAepDocuments(getBearerToken);
      }
    }
  }

  private handleAepSave = async (values: IAEPFormValues) => {
    const { learnerId, getBearerToken } = this.context;
    const { updateAepDocumentsData, aepDocumentsData } = this.props;
    aepDocumentsData.aepDocuments = values.learnerAepDocuments;
    updateAepDocumentsData(learnerId, getBearerToken, aepDocumentsData);
    this.setState({ showModal: false });
  };


  private handleModal = async (show: boolean) => {
    this.setState({ showModal: show });
  };


  private loadAepDocuments = async (
    getBearerToken: () => Promise<string>,
  ): Promise<void> => {
    const aepDocumentOptionsResponse = await api.aepDocuments.aepDocumentOptions(getBearerToken);
    if (!aepDocumentOptionsResponse.ok) throw await aepDocumentOptionsResponse;
    const aepDocuments = await aepDocumentOptionsResponse.json();
    this.setState({ aepDocuments });
  };

  public render(): JSX.Element {
    const {
      aepDocumentsData,
      canEditAepStatus,
    } = this.props;
    const missedDocuments = aepDocumentsData.aepDocuments.filter((ad) => ad.status !== OK);
    const { showModal, aepDocuments } = this.state;
    const aepStatus = missedDocuments.length > 0;

    return (
      <>
        <Row>
          <Col xs={9}>
            {missedDocuments && missedDocuments.length === 0
              ? <div>Completed</div> : <StyledStatus>Pending</StyledStatus> }
          </Col>
          {canEditAepStatus
          && (
          <Col xs={3}>
            <Row style={{ justifyContent: 'flex-end', margin: 0 }}>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => { this.handleModal(true); }}
              >
                <FiEdit />
              </Button>
            </Row>
          </Col>
          )}
        </Row>

        {missedDocuments && missedDocuments.map((doc) => <LongText key={doc.name}>{doc.name}</LongText>)} {showModal
            && (
            <AepEditor
              aepDocuments={aepDocuments}
              aepStatus={aepStatus}
              learnerAepDocuments={aepDocumentsData.aepDocuments}
              handleSave={this.handleAepSave}
              handleClose={this.handleModal}
              showModal={showModal}
              aepUpdatePending={false}
            />
            )}
      </>
    );
  }
}

AepStatus.contextType = AuthContext;

const mapStateToProps = (state: IRootState): IAepStatusStateProps => ({
  aepDocumentsData: getAepDocumentsState(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, IDispatchProps, AnyAction>,
): IDispatchProps => (
  {
    getLearnerAEPDocumentsData: (token: () => Promise<string>, learnerId: string):
    void => {
      dispatch(getLearnerAepDocumentsInfo(token, learnerId));
    },
    updateAepDocumentsData: (
      learnerId: string,
      token: () => Promise<string>,
      updatedAepDocumentsData: IUpdateAepDocumentsRequest,
    ): void => {
      dispatch(updateLearnerAepDocumentsInfo(learnerId, token, updatedAepDocumentsData));
    },

  });

export default connect(mapStateToProps, mapDispatchToProps)(AepStatus);
