/* eslint-disable max-len */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import {
  IEducationInfo,
  INationalityInfo,
  IPersonalInfo,
  IIdentityInfo,
  IPermissionObject,
  ICommonPropsOrigin,
  IApplicantInfo,
  RouteComponentProps,
  MatchParams,
} from '../admissions.model';
import {
  AdmissionsContainer, StyledLoadingBlock, StyledLoadingText,
  StyledWrapper,
  StyledOriginalInfo,
} from '../styled-components';
import { getApplicantInfo } from '../admissions-functions';
import errorHandling from '../../helper-components/alert-component.component';
import api from '../../../shared/api/adminUI.api';
import ApplicantPersonalDetails from '../admissions-form/applicant-personal-details/applicant-personal-details';
import ApplicantPriorEducation from '../admissions-form/applicant-prior-education/applicant-prior-education';

export interface IAdmissionsOriginalInfoProps extends RouteComponentProps<MatchParams> {
  readonly getBearerToken: () => Promise<string>;
}
export interface IAdmissionsStateOrigin {
  readonly applicantInfo: IApplicantInfo;
  readonly personalInfo: IPersonalInfo | null;
  readonly educationInfo: IEducationInfo | null;
  readonly nationalityInfo: INationalityInfo | null;
  readonly identityInfo: IIdentityInfo | null;
  readonly admissionDataPending: boolean;
}


const Program: React.FC<{productCode: string, productType: string }> = (
  props: {productCode: string, productType: string },
): JSX.Element => {
  const { productCode, productType } = props;

  return (
    <StyledWrapper>
      { productCode !== '-'
      && (
        <>
          <h3>Program</h3>
          <span>{productCode} {productType}</span>
        </>
      )}

    </StyledWrapper>
  );
};

const DisplayPersonalDataBasedOnProgram: React.FC<{personalInfo: IPersonalInfo | null,
  nationalityInfo: INationalityInfo | null, educationInfo: IEducationInfo | null,
  identityInfo: IIdentityInfo | null}> = (
  props: {personalInfo: IPersonalInfo | null, nationalityInfo: INationalityInfo | null,
    educationInfo: IEducationInfo | null, identityInfo: IIdentityInfo | null
  },
): JSX.Element => {
  const {
    personalInfo, nationalityInfo, educationInfo, identityInfo,
  } = props;

  return (
    <StyledOriginalInfo>
      {/* eslint-disable-next-line max-len */}
      <ApplicantPersonalDetails personalInfo={personalInfo} nationalityInfo={nationalityInfo} identityInfo={identityInfo} />
      <ApplicantPriorEducation educationInfo={educationInfo} />
    </StyledOriginalInfo>
  );
};

class AdmissionsOriginalInfo extends React.Component<ICommonPropsOrigin, IAdmissionsStateOrigin> {
  constructor(props: ICommonPropsOrigin) {
    super(props);
    this.state = {
      admissionDataPending: true,
      applicantInfo: {
        ProductType: '',
        ProductCode: '-',
        Status: '',
        AdmissionTicketUrl: '',
        ReviewedBy: '',
        ReviewedTime: null,
        Comments: '',
        AEP: null,
        AEPEnglishProficiencyStatus: '',
        AEPTranscriptStatus: '',
        AcceptanceType: '',
        FoundationCoursesCsv: '',
        PriorEducationDoc1: '',
        PriorEducationDoc2: '',
        EnglishEvidenceOption: '',
        EnglishCertificate: '',
        EnglishIndividualEvaluation: '',
        EfsetScore: null,
        AEPDocuments: [],
        ApplicationSource: null,
      },
      personalInfo: null,
      educationInfo: null,
      nationalityInfo: null,
      identityInfo: null,
    };
  }

  public componentDidMount() {
    const { match, getBearerToken } = this.props;
    const { applicantId } = match.params;
    this.getAdmissionPermission(applicantId, getBearerToken);
  }

  private readonly getAdmissionPermission = async (
    applicantId: string,
    getBearerToken:() => Promise<string>,
  ): Promise<void> => {
    try {
      const response = await api.admissions.getAdmissionPermission(getBearerToken);
      if (!response.ok) throw await response;

      const permissionObject = (await response.json()) as IPermissionObject;

      // this.setState({ permissionObject });
      if (permissionObject.readApplications) {
        this.loadAdmissionData(applicantId, getBearerToken);
      } else {
        this.setState({
          admissionDataPending: false,
        });
      }
    } catch (error) {
      errorHandling({ error });
      this.setState({
        admissionDataPending: false,
      });
    }
  };

  private readonly loadAdmissionData = async (
    applicantId: string,
    getBearerToken:() => Promise<string>,
  ): Promise<void> => {
    try {
      this.setState({
        admissionDataPending: true,
      });

      const applicantInfo = await getApplicantInfo(applicantId, getBearerToken);
      const educationInfoResponse = await api.admissions.getEducationInfo(
        applicantId,
        getBearerToken,
      );
      const educationInfo = await educationInfoResponse.json();

      const nationalityInfoResponsePromise = api.admissions.getNationalityInfo(
        applicantId,
        getBearerToken,
      );

      const personalInfoResponse = await api.admissions.getPersonalInfo(
        applicantId,
        getBearerToken,
      );
      const personalInfo = await personalInfoResponse.json();

      const identityInfoResponse = await api.learnerIdentity.getIdentityInfo(
        applicantId,
        getBearerToken,
      );
      const { status } = identityInfoResponse;
      if (status !== 200 && status !== 404) throw await identityInfoResponse;
      const identityInfo = (status === 200) ? await identityInfoResponse.json() : null;

      const nationalityInfoResponse = await nationalityInfoResponsePromise;
      const nationalityInfo = await nationalityInfoResponse.json();

      this.setState({
        admissionDataPending: false,
        applicantInfo,
        educationInfo,
        nationalityInfo,
        personalInfo,
        identityInfo,
      });
    } catch (error) {
      errorHandling({ error });
      this.setState({
        admissionDataPending: false,
      });
    }
  };


  render(): JSX.Element {
    const {
      applicantInfo,
      personalInfo,
      educationInfo,
      nationalityInfo,
      identityInfo,
      admissionDataPending,
    } = this.state;

    return (
      <AdmissionsContainer>
        {
          (applicantInfo && applicantInfo.ProductCode)
            ? (<Program productCode={applicantInfo.ProductCode} productType={applicantInfo.ProductType} />)
            : (
              <StyledLoadingBlock>
                <Spinner animation="border" size="sm" />
                <StyledLoadingText>Loading applicant information...</StyledLoadingText>
              </StyledLoadingBlock>
            )
        }

        {
          // eslint-disable-next-line max-len
          (personalInfo && nationalityInfo && educationInfo && identityInfo && !admissionDataPending)
            ? (
              <DisplayPersonalDataBasedOnProgram
                personalInfo={personalInfo}
                nationalityInfo={nationalityInfo}
                educationInfo={educationInfo}
                identityInfo={identityInfo}
              />
            )
            : (
              <StyledLoadingBlock>
                <Spinner animation="border" size="sm" />
                <StyledLoadingText>Loading applicant information...</StyledLoadingText>
              </StyledLoadingBlock>
            )
        }

      </AdmissionsContainer>
    );
  }
}

export default AdmissionsOriginalInfo;
