import { InputGroup, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const EditingInput = styled(InputGroup)`
  width: 60%;
`;
export const InputRow = styled(Row)`
  margin: 0;
`;

export const DefferdLabel = styled.span`
  color: red;
  padding: 0.2em;
  margin-left: 1.5em;
  font-weight: 300;
  font-size: 14px;
`;
