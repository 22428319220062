import {
  createStore, applyMiddleware, compose, Middleware,
} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import reducer, { IRootState } from '../shared/reducers';

const defaultMiddlewares = [
  thunkMiddleware,
  promiseMiddleware,
];

const composedMiddlewares = (middlewares: Middleware[]) => compose(
  applyMiddleware(...defaultMiddlewares, ...middlewares),
);
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialize = (initialState?: IRootState, middlewares = []) => createStore(
  reducer, initialState, composeEnhancers(composedMiddlewares(middlewares)),

);

export default initialize;
