import { Row } from 'react-bootstrap';
import styled from 'styled-components';

export const MainRow = styled(Row)`
  padding: 10px;
`;

export const PaddingRow = styled(Row)`
  padding: 10px;
`;

export const CommentField = styled.textarea`
  padding: .3em;
  box-sizing: border-box;
  resize: none;
  border-color: rgb(204, 204, 204);
  borer-radius: 4px;
`;
