import React from 'react';

interface GradePercentageProps {
  readonly percentage: number | null;
}

export default function GradePercentage(props: GradePercentageProps): JSX.Element {
  const { percentage } = props;
  if (!percentage && percentage !== 0) {
    return <>-%</>;
  }
  return <>{`${percentage}%`}</>;
}
