/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import Select, { ValueType } from 'react-select';
import styled from 'styled-components';
import moment from 'moment';
import withLoading from '../helper-components/loading.component';
import EventType from './event-type';
import { IEvent, IEventDetails } from './learner-history-model';
import selectEventElement from './select-event-element';
import EventEditor from './event-editor';
import UniversityDate from '../university-date';

interface ILearnerHistoryProps {
  events: IEvent[];
}

interface SelectOption {
  label: string;
  value: string;
}

interface IState {
  categoryFilter:SelectOption[];
}

class LearnerHistoryTable extends React.Component<ILearnerHistoryProps, IState> {
  constructor(props: ILearnerHistoryProps) {
    super(props);
    this.state = {
      categoryFilter: [],
    };
  }

  handleFilterChange = (value: ValueType<SelectOption>) => {
    const categoryFilter: SelectOption[] = value as SelectOption[] || [];
    this.setState({ categoryFilter });
  };

  public render(): JSX.Element {
    const { events } = this.props;
    const { categoryFilter } = this.state;

    const eventsSelected = (events || [])
      .map((event, i) => {
        const selector = selectEventElement(event.eventKey);
        return selector ? { ...selector(event), eventKey: event.eventKey, order: i } : null;
      })
      .filter((e: any) => !!e && !!e.metadata && !!e.element)
      .map((e) => e as IEventDetails & { eventKey: string, order: number })
      .sort((l, r) => new Date(moment.utc(r!.metadata.date).valueOf() || 0).getTime() - new Date(moment.utc(l!.metadata.date).valueOf() || 0).getTime());

    if (eventsSelected.length === 0) {
      return <>No history records yet</>;
    }

    const categories = eventsSelected.map((e) => e!.metadata.type.category)
      .filter((c) => c)
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort();

    const activeCategories = categoryFilter.map((cf) => cf.value);
    const filteredEvents = (!activeCategories || !activeCategories.length)
      ? eventsSelected
      : eventsSelected.filter((e) => activeCategories.includes(e!.metadata.type.category));

    return (
      <HistoryContainer>
        <Row>
          <Col xs={3} className="my-auto">Category Filter:</Col>
          <Col xs={9}>
            <Select
              placeholder="All"
              value={categoryFilter}
              options={categories.map((x) => ({
                value: x,
                label: x,
              }))}
              onChange={this.handleFilterChange}
              isMulti
            />
          </Col>
        </Row>

        <EventsContainer>
          {filteredEvents.map((e) => (
            <EventRow key={`${e!.metadata.date}:${e!.eventKey}:${e!.order}`}>
              <Col xs={3} className="my-auto">
                <DateRow><UniversityDate value={e!.metadata.date} /></DateRow>
                <EditorRow><EventEditor editor={e!.metadata.editor} /></EditorRow>
              </Col>
              <Col xs={3} className="my-auto"><EventType type={e!.metadata.type} /></Col>
              <Col xs={6} className="my-auto">{e!.element}</Col>
            </EventRow>
          ))}
        </EventsContainer>
      </HistoryContainer>
    );
  }
}

export default withLoading(LearnerHistoryTable);

const DateRow = styled(Row)`
  font-size: 16px;
  padding-left: 10px;
`;

const EventRow = styled(Row)`
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-bottom: 10px;
`;

const EditorRow = styled(Row)`
  font-size: 14px;
  color: gray;
  padding-left: 10px;
`;

const EventsContainer = styled(Container)`
  margin: 10px auto;
  > div:nth-child(even) {
    background-color: rgba(0,0,0,.04);
  };
  > div:nth-child(odd) {
    background-color: rgba(40,40,0,.03);
  };
`;

const HistoryContainer = styled(Container)`
  margin: 20px 0px;
`;
