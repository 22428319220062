import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { AnyObject } from 'react-final-form';
import moment from 'moment/moment';
import { LearnerRegistrationApplicantBlock, TitleBarWithCta } from '../styled.components';

import { IUploadedFile } from '../../../models/UploadedFile';
import api, {
  IUploadData,
  IUploadFormData,
  LEARNER_FILE_TYPE,
} from '../../../shared/api/adminUI.api';
import IError from '../../../models/Error';
import { WithLoading } from '../../helper-components/loading.component';
import { WithErrorHandling } from '../../helper-components/error-handling.component';
import { getLearnerFilesCategoryOptions } from '../../learner-files/learner-files-functions';
import {
  createOptions,
  isCurrentDate,
} from '../../helper-components/form-components/form-filed-components/form-filed.components';
import { ISelectOption } from '../../../models/SelectOptions';
import FileUploadForm from '../../learner-files/file-upload-modal/file-upload-modal';
import fileUploadService from '../../../shared/uploads/upload-service';
import { IFileUploadResponse, ILearnerFilePermissions } from '../../learner-files/learner-files.model';
import UploadedFilesTable from '../../learner-files/uploaded-files-list/uploaded-files-list';

type RequestStateType = 'initial' | 'loading' | 'success' | 'error';

export interface ApplicantFilesProps {
  readonly getBearerToken: () => Promise<string>;
}

const ApplicantFilesComponent = (props: ApplicantFilesProps) => {
  const { getBearerToken } = props;
  const { applicantId } = useParams();

  const [applicantFilesState, setApplicantFilesState] = useState<RequestStateType>('initial');
  const [applicantFiles, setApplicantFiles] = useState<Array<IUploadedFile>>();
  const [applicantFilesError, setApplicantFilesError] = useState<IError | null>(null);

  const [categoryOptionsState, setCategoryOptionsState] = useState<RequestStateType>('initial');
  const [categoryOptions, setCategoryOptions] = useState<Array<ISelectOption>>();
  const [categoryOptionsError, setCategoryOptionsError] = useState<IError | null>(null);

  const [filePermissionState, setFilePermissionState] = useState<RequestStateType>('initial');
  const [filePermission, setFilePermission] = useState<ILearnerFilePermissions>();
  const [filePermissionError, setFilePermissionError] = useState<IError | null>(null);

  const [fileUploadPending, setFileUploadPending] = useState(false);
  const [fileUploadError, setFileUploadError] = useState<IError | null>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleOpen = () => setShowUploadModal(true);
  const handleClose = () => setShowUploadModal(false);

  const fetchApplicantFiles = async (id: string) => {
    try {
      setApplicantFilesState('loading');
      const response = await api.learnerRegistration.getLearnerFiles(id, getBearerToken);
      if (!response.ok) throw await response;
      if (response.status === 204) {
        setApplicantFiles(undefined);
      } else {
        let parsedResponse = (await response.json()) as Array<IUploadedFile>;
        parsedResponse = parsedResponse.filter((item) => item.author !== 'system');
        setApplicantFiles(parsedResponse);
      }
      setApplicantFilesState('success');
    } catch (err) {
      setApplicantFilesState('error');
      setApplicantFilesError(err as IError);
    }
  };

  const fetchCategoryOptions = async () => {
    try {
      setCategoryOptionsState('loading');
      const categoryOptionsRes = await getLearnerFilesCategoryOptions(getBearerToken);
      const categoryOptionsList = createOptions(categoryOptionsRes);
      setCategoryOptions(categoryOptionsList);
      setCategoryOptionsState('success');
    } catch (err) {
      setCategoryOptionsState('error');
      setCategoryOptionsError(err as IError);
    }
  };

  const fetchFilePermissions = async () => {
    try {
      setFilePermissionState('loading');
      const response = await api.documentUpload.getLearnerFilePermission(getBearerToken);
      if (!response.ok) throw await response;
      const parsedResponse = (await response.json()) as ILearnerFilePermissions;
      setFilePermission(parsedResponse);
      setFilePermissionState('success');
    } catch (err) {
      setFilePermissionState('error');
      setFilePermissionError(err as IError);
    }
  };

  useEffect(() => {
    const loadInitialPersonalDetails = async (): Promise<void> => {
      if (applicantId && applicantFilesState === 'initial') await fetchApplicantFiles(applicantId);
    };
    loadInitialPersonalDetails();
  }, [applicantId, applicantFilesState]);

  useEffect(() => {
    const loadInitialCategoryOptions = async (): Promise<void> => {
      if (categoryOptionsState === 'initial') await fetchCategoryOptions();
    };
    loadInitialCategoryOptions();
  }, [categoryOptionsState]);

  useEffect(() => {
    const loadInitialFilePermissions = async (): Promise<void> => {
      if (filePermissionState === 'initial') await fetchFilePermissions();
    };
    loadInitialFilePermissions();
  }, [filePermissionState]);


  const submitUploadForm = async (
    userId: string,
    fileType: string,
    uploadFormData: IUploadData,
  ): Promise<void> => {
    try {
      const response = await api.documentUpload.submitUploadForm(
        userId,
        getBearerToken,
        fileType,
        uploadFormData,
      );
      if (!response.ok) throw response;
      const learnerFile = await response.json();
      const learnerFileWithType = { fileType, ...learnerFile };
      setFileUploadPending(false);
      setShowUploadModal(false);
      const newFiles = applicantFiles?.length ? [learnerFileWithType, ...applicantFiles] : [learnerFileWithType];
      setApplicantFiles(newFiles);
    } catch (err) {
      setFileUploadPending(false);
      setShowUploadModal(false);
      setFileUploadError(err as IError);
    }
  };

  const handleSave = async (values: AnyObject) => {
    if (!applicantId || !getBearerToken) return;

    const {
      uploadFile, fileName, uploadDate, categorySelect, subcategory, comment,
    } = values;
    const uploadedFile = uploadFile[0];
    const category = categorySelect.value;
    const uploadFormData: IUploadFormData = {
      fileName,
      uploadDate,
      category,
      subcategory,
      comment,
    };

    try {
      setFileUploadPending(true);
      const token = await getBearerToken();

      const response = await fileUploadService.uploadFileByFileType(
        token,
        applicantId,
        LEARNER_FILE_TYPE,
        category,
        uploadedFile,
      );
      const fileUploadResponse = (await response) as IFileUploadResponse;
      const uploadedFileName = fileUploadResponse.fileName;
      const currentTime = `${new Date().getHours()}:${new Date().getMinutes().toString().padStart(2, '0')}`;

      const uploadData = {
        uploadedFileName,
        ...uploadFormData,
        uploadDate: `${moment(uploadDate).format('YYYY-MM-DD')} ${isCurrentDate(uploadDate) ? currentTime : ''}`,
      };

      await submitUploadForm(applicantId, LEARNER_FILE_TYPE, uploadData);
    } catch (err) {
      setFileUploadPending(false);
      setShowUploadModal(false);
      setFileUploadError(err as IError);
    }
  };

  const deleteFileHandler = (fileName: string) => {
    if (!applicantFiles?.length) return;
    const newList = applicantFiles.filter((item) => item.fileName !== fileName);
    setApplicantFiles(newList);
  };

  const updateFileHandler = (learnerId: string, file: IUploadedFile) => {
    if (!applicantFiles?.length) return;
    const newList = applicantFiles.map((item) => {
      if (item.fileName === file.fileName) {
        const updatedItem:IUploadedFile = {
          ...item,
          displayFileName: file.displayFileName,
          category: file.category,
          subCategory: file.subCategory,
          comment: file.comment,
        };

        return updatedItem;
      }

      return item;
    });
    setApplicantFiles(newList);
  };

  return (
    <LearnerRegistrationApplicantBlock className="full">
      <TitleBarWithCta>
        <h3>Applicant Files</h3>
        <div>
          <Button onClick={handleOpen}>Add Document</Button>
        </div>
      </TitleBarWithCta>
      <WithLoading loading={applicantFilesState === 'initial' || applicantFilesState === 'loading' || filePermissionState === 'initial' || filePermissionState === 'loading'} loadingText="Loading fiiles for applicant">
        <WithErrorHandling small error={applicantFilesError || filePermissionError || fileUploadError || categoryOptionsError}>
          {!applicantFiles?.length && (<p style={{ margin: '24px 0 48px' }}>No files have been added for this applicant</p>)}
          {!!applicantFiles?.length && filePermission && (
            <UploadedFilesTable
              learnerId={applicantId || ''}
              getBearerToken={getBearerToken}
              categoryOptions={categoryOptions || []}
              uploadedFilesList={applicantFiles}
              fileDeletedEvent={deleteFileHandler}
              fileUpdatedEvent={updateFileHandler}
              learnerFilePermissions={filePermission}
              isLearnerRegistration
            />
          )}
        </WithErrorHandling>
      </WithLoading>
      <FileUploadForm
        handleClose={handleClose}
        handleSave={handleSave}
        showUploadModal={showUploadModal}
        categoryOptions={categoryOptions || []}
        categoryOptionsPending={categoryOptionsState === 'initial' || categoryOptionsState === 'loading'}
        fileUploadPending={fileUploadPending}
      />
    </LearnerRegistrationApplicantBlock>
  );
};

export default ApplicantFilesComponent;
