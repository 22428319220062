import { useQuery } from '@tanstack/react-query';
import { useAuthContext } from '../../../auth/auth-context';
import { usePartnerAgreementsParams } from './use-params';
import api from '../../../../shared/api/adminUI.api';

export const useLatestAgreement = () => {
  const { partnerId } = usePartnerAgreementsParams();
  const { getBearerToken } = useAuthContext();

  return useQuery({
    queryKey: ['partner', partnerId, 'latest-agreement'],
    queryFn: () => api.partners.getLatestAgreement(partnerId, getBearerToken),
    suspense: true,
  });
};
