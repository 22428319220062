import React from 'react';
import {
  Row,
  Col,
} from 'react-bootstrap';
import {
  ProfessorsTable,
  CourseInfoHeader,
  GradesTable,
} from './styled-components';
import CourseCohortGrade from './models/CourseCohortGrade';
import GradePercentageComponent from '../learner-grades/components/grade-percentage';
import UniversityDate from '../university-date';

interface CohortGradesProps {
  readonly courseCohortGrade: CourseCohortGrade;
}

export default class CourseCohortGrades extends React.Component<CohortGradesProps> {
  renderProfessorsTableData() {
    const { courseCohortGrade } = this.props;
    return courseCohortGrade!.Professors.map((professor) => {
      const {
        Name, Email,
      } = professor;
      return (
        <tr key={Email}>
          <td>{Name}</td>
          <td>{Email}</td>
        </tr>
      );
    });
  }

  renderGradesTableData() {
    const { courseCohortGrade } = this.props;

    return courseCohortGrade!.LearnerAssignments.sort((lAs, rAs) => (new Date(lAs.DueDate || 0)).valueOf() - (new Date(rAs.DueDate || 0).valueOf())).map((learnerAssignment) => {
      const {
        Module,
        Name,
        AssignmentId,
        DueDate,
        SubmittedDateTime,
        GradePercentage,
        GradedDateTime,
      } = learnerAssignment;

      return (
        <tr key={AssignmentId}>
          <td>{Module}</td>
          <td>{Name}</td>
          <td><UniversityDate value={DueDate} withTime /></td>
          <td><UniversityDate value={SubmittedDateTime} withTime /></td>
          <td><GradePercentageComponent percentage={GradePercentage} /></td>
          <td><UniversityDate value={GradedDateTime} withTime /></td>
        </tr>
      );
    });
  }

  render(): JSX.Element {
    const { courseCohortGrade } = this.props;

    if (!courseCohortGrade) {
      return <div className="readmission-loader" />;
    }

    return (
      <>
        <Row>
          <Col>
            <CourseInfoHeader>Performance</CourseInfoHeader>
            <GradesTable bordered striped size="sm">
              <thead>
                <tr>
                  <th>Module</th>
                  <th>Assignment</th>
                  <th>Due</th>
                  <th>Submitted</th>
                  <th>Grade</th>
                  <th>Graded</th>
                </tr>
              </thead>
              <tbody>
                {this.renderGradesTableData()}
              </tbody>
            </GradesTable>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <CourseInfoHeader>Professors</CourseInfoHeader>
            <ProfessorsTable size="sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {this.renderProfessorsTableData()}
              </tbody>
            </ProfessorsTable>
          </Col>
        </Row>
      </>
    );
  }
}
