/* eslint-disable max-len */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable object-curly-newline */
import React from 'react';

const ReviewIcon: React.FC<{ status: string, reviewer?: string; }> = (
  props: { status: string, reviewer?: string },
): JSX.Element => {
  const { status, reviewer } = props;
  switch (status) {
    default:
    case 'NotReviewed': return (<span role="img" aria-label="Not reviewed">❓</span>);
    case 'Passed': return (<span title={reviewer} role="img" aria-label="Passed">✔</span>);
    case 'Failed': return (<span title={reviewer} role="img" aria-label="Failed">❌</span>);
  }
};

export const ReviewIconWithLink: React.FC<{ status: string, reviewer?: string, url?: string | null, text: string }> = (
  props: { status: string, reviewer?: string, url?: string | null, text: string },
): JSX.Element => {
  const { status, reviewer, url, text } = props;
  return url
    ? (<div><ReviewIcon status={status} reviewer={reviewer} /> <a href={url} target="_blank" rel="noopener noreferrer">{text}</a></div>)
    : (<div><ReviewIcon status={status} reviewer={reviewer} /> {text}</div>);
};

export default ReviewIcon;
