import React from 'react';
import { Row, Col } from 'react-bootstrap';

interface IRejectionComment {
  comment: string;
}

const RejectionComment: React.FC<IRejectionComment> = (
  props: IRejectionComment,
): JSX.Element => {
  const { comment } = props;
  return (
    <Row>
      <Col xs={2}> <p style={{ color: 'red' }}>Rejection reason:</p> </Col>
      <Col xs={3}> <p style={{ wordBreak: 'break-word', color: 'red' }}>{comment}</p> </Col>
    </Row>
  );
};

export default RejectionComment;
