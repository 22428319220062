import styled from 'styled-components';

export const MaxCourseNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  *{
      margin-right: 20px;
      display: flex;
  }
`;

export const MaxCourseNumberLabel = styled.h1`
  font-weight: 500;
  font-size: 0.9em;
  padding-top: 10px;
  text-align: left;
`;

export const ValidationError = styled.p`
  color: red;  
  height: 100%;
  margin: auto 0;
  font-size: 0.8em;
`;

export const Limits = styled.p`
  height: 100%;
  margin: auto 0;
  font-size: 0.8em;
`;
