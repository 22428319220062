/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import { getEditorLabel } from '../../../utils/format.utils';
import UniversityDate from '../../university-date';
import { IApprovedTransferObject } from '../learner-info.model';
import { ApprovedTransfersWrapper } from '../styled-components';

class ApprovedTransfers extends React.PureComponent<IApprovedTransferObject> {
  render(): JSX.Element {
    const { externalTransfers } = this.props;
    const transfersLength = externalTransfers && externalTransfers.length > 0;

    if (!transfersLength) return <div />;

    return (
      <ApprovedTransfersWrapper>
        { externalTransfers.map((transfer) => {
          if (transfer.status === 'approved') {
            const {
              externalCourses, courseCode, courseName, productCode, status, timestamp, editor,
            } = transfer;

            const formattedEditor = getEditorLabel(editor);
            const coursesLength = externalCourses && externalCourses.length;

            return (
              <div className="single-course-section">
                <div className="course-left">
                  <div className="course-info">
                    <div className="course-name">{courseCode}{' '}{courseName}</div>
                    <div className="product-code">{productCode}</div>
                  </div>
                  <div className="course-status">
                    <div className="status">{status}</div>
                    <div className="date"><UniversityDate value={timestamp} />, {formattedEditor}</div>
                  </div>
                </div>
                <div className="course-right">
                  {coursesLength && externalCourses.map((course) => {
                    const { externalCourseCode, externalCourseName, provider } = course;

                    return (
                      <div className="external-transfers-info">
                        <div className="course-code">
                          {externalCourseCode}
                          <div className="provider-name">{provider}</div>
                        </div>
                        <div className="course-name">{externalCourseName}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
        })}
      </ApprovedTransfersWrapper>
    );
  }
}

export default ApprovedTransfers;
