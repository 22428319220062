import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import errorHandling from '../../components/helper-components/alert-component.component';
import {
  GET_SAP_ERROR,
  GET_SAP_PENDING,
  GET_SAP_SUCCESS,
} from '../reducers/academic-standing/academic-standing.constants';
import api from '../api/adminUI.api';
import IError from '../../models/Error';
import { LearnerAcademicStanding } from '../reducers/academic-standing/academic-standing.model';

export const getAcademicStandingPending = (): AnyAction => action(GET_SAP_PENDING);
export const getAcademicStandingSuccess = (
  optionsObject: LearnerAcademicStanding,
): AnyAction => action(GET_SAP_SUCCESS, optionsObject);
export const getSAPFailure = (
  error: IError,
): AnyAction => action(GET_SAP_ERROR, error);

export const getAcademicStandingObject = (
  getBearerToken: () => Promise<string>,
  learnerId: string,
) => async (dispatch: Dispatch) => {
  dispatch(getAcademicStandingPending());

  try {
    const response = await api.academicStanding.learnerAcademicStanding(learnerId, getBearerToken);

    if (!response.ok) throw await response;

    const academicStanding = (await response.json()) as LearnerAcademicStanding;
    dispatch(getAcademicStandingSuccess(academicStanding));
  } catch (error) {
    dispatch(getSAPFailure(error));
    errorHandling({ error });
  }
};
