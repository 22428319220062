/* eslint-disable import/prefer-default-export */
export enum LearnerProgramTypesStepper {
  MBA = 'MBA',
  BBA = 'BBA',
  CERTIFICATE = 'Certificate',
  INDIVIDUAL_COURSE = 'Individual Course',
}

// export interface ILearningPathProps {
//   readonly canSaveLearningPathChanges: boolean;
//   readonly learnerProgramType: string;
//   readonly learnerProductCode: string;
// }

export interface IUserProgressInfo {
  meetRequirements: boolean;
}
export interface ICourse {
  id: string;
  name: string;
  order: number;
  group: null | string;
  fixed: boolean;
  inProgress: boolean;
  section: string;
  isGroupSelection?: boolean;
  isSelectDropdown?: boolean;
  prerequisites: string[];
  courses?: ICourse[];
}
