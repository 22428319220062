// @ts-ignore
import React, { useState, useEffect } from 'react';
import {
  Button, Row, Col, Form, // Spinner,
} from 'react-bootstrap';
import { FiSave, FiEdit } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import {
  EditingButtonsRow,
  FieldRow,
  EditButton,
} from '../../editing-component/styled-component';
import { EditingCol } from '../styled-components';
import TextField from './text-edit-component';
import {
  INewFieldValue,
} from '../../admissions.model';
import DropdownField from './dropdown-edit-component';
import { APPLICANT_STATUS } from '../../../constants/common-constants';
import { ISelectOption } from '../../../../models/SelectOptions';
import CheckboxField from './checkbox-edit-component';

interface IProps {
  oldValue: string | any;
  // ToDo: canEdit prop does nothing on the component
  canEdit: boolean;
  propertyName: string;
  changedFieldUrl: string;
  fieldType: string;
  selectOptions?: ISelectOption[] | null;
  handleNewValue: (newValue: INewFieldValue, changedFieldUrl: string) => Promise<void>;
  paramsValidation?: string | any;
  applicationStatus: string | any;
  maxSymbols?: number | undefined,
}

const returnFieldBasedOnType = (
  filedType: string,
  fieldValue: string | any,
  originalValue: string | any,
  propertyName: string,
  validationError: string | null,
  setFormError: (error: string | null) => void,
  setNewFieldValue: (newValue: string | boolean) => void,
  paramsValidation?: string | any,
  maxSymbols?: number | undefined,
  selectOptions?: ISelectOption[] | null,
): JSX.Element => {
  switch (filedType) {
    case 'email':
    case 'input':
    case 'number':
    default:
      return (
        <TextField
          paramsValidation={paramsValidation}
          fieldValue={fieldValue}
          originalValue={originalValue}
          fieldType={filedType}
          setFormError={setFormError}
          setNewFieldValue={setNewFieldValue}
          validationError={validationError}
          maxSymbols={maxSymbols}
        />
      );
    case 'checkbox':
      return (
        <CheckboxField
          fieldValue={fieldValue}
          setNewFieldValue={setNewFieldValue}
        />
      );
    case 'select':
      return (
        <DropdownField
          fieldValue={fieldValue}
          setNewFieldValue={setNewFieldValue}
          selectOptions={selectOptions}
          propertyName={propertyName}
        />
      );
  }
};

const FieldEditor: React.FunctionComponent<IProps> = ({
  oldValue, maxSymbols,
  propertyName,
  changedFieldUrl,
  fieldType,
  handleNewValue,
  paramsValidation,
  applicationStatus,
  selectOptions,
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [fieldValue, setNewFieldValue] = useState(oldValue);

  const [originalValue] = useState(oldValue);

  const [validationError, setFormError] = useState<null | string>(null);
  useEffect(() => { setNewFieldValue(oldValue); }, [oldValue]);

  // @ts-ignore
  // eslint-disable-next-line no-shadow
  const renderView = (fieldValue: any, fieldName: any): any => ({
    // eslint-disable-next-line max-len
    Country: selectOptions?.find((country: any) => country.value === fieldValue)
      && selectOptions?.find((country: any) => country.value === fieldValue)!.label,

    // eslint-disable-next-line max-len
    InstitutionCountry: selectOptions?.find((country: any) => country.value === fieldValue)
      && selectOptions?.find((country: any) => country.value === fieldValue)!.label,

    // eslint-disable-next-line max-len
    Nationality: selectOptions?.find((nationality: any) => nationality.value === fieldValue)
      && selectOptions?.find((nationality: any) => nationality.value === fieldValue)!.label,

    // eslint-disable-next-line max-len
    State: selectOptions?.find((state: any) => state.value === fieldValue)
      && selectOptions?.find((state: any) => state.value === fieldValue)!.label,
    // eslint-disable-next-line no-nested-ternary
    Accredited: typeof fieldValue !== 'boolean' ? '' : fieldValue ? 'Yes' : 'No',
    // eslint-disable-next-line no-nested-ternary
    IsBusinessRelated: typeof fieldValue !== 'boolean' ? '' : fieldValue ? 'Yes' : 'No',

  }[fieldName]);

  const edit = () => {
    setEditing(true);
    setNewFieldValue(oldValue);
  };

  const cancel = () => {
    setEditing(false);
    setNewFieldValue(oldValue);
    setFormError(null);
  };

  const save = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget as HTMLInputElement;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      const payload = { [propertyName]: fieldValue };
      await handleNewValue(payload, changedFieldUrl);
      setEditing(false);
    }
  };

  if (editing) {
    return (
      <>
        <Form onSubmit={save} noValidate>
          <Row>
            <EditingCol xs={9}>
              {
                returnFieldBasedOnType(
                  fieldType,
                  fieldValue,
                  originalValue,
                  propertyName,
                  validationError,
                  setFormError,
                  setNewFieldValue,
                  paramsValidation,
                  maxSymbols,
                  selectOptions,
                )
              }
            </EditingCol>
            <Col xs={3}>
              <EditingButtonsRow>
                <Button variant="outline-secondary" size="sm" disabled={!!validationError} type="submit">
                  <FiSave />
                </Button>
                <Button variant="outline-secondary" size="sm" onClick={cancel}>
                  <GiCancel />
                </Button>
              </EditingButtonsRow>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col id={propertyName} xs={9}>
          <span>
            {renderView(fieldValue, propertyName) || fieldValue}
          </span>
        </Col>
        <Col xs={3}>
          <FieldRow>
            {(
              (applicationStatus && applicationStatus === APPLICANT_STATUS.ENROLLING)
                || (applicationStatus && applicationStatus === APPLICANT_STATUS.SUBMITTED)
                || (applicationStatus && applicationStatus === APPLICANT_STATUS.REGISTRATION)
            ) && (
            <EditButton variant="outline-secondary" size="sm" onClick={edit}>
              <FiEdit />
            </EditButton>
            )}
          </FieldRow>
        </Col>
      </Row>
    </>
  );
};

export default FieldEditor;
