import React from 'react';

interface IProps {
  readonly partnerId?: string,
  readonly partnerAccountName?: string,
}

const Partner: React.FC<IProps> = (
  props: IProps,
): JSX.Element => {
  const {
    partnerId,
    partnerAccountName,
  } = props;

  return (
    <>
      {partnerId ? <a href={`/partners/${partnerId}`} target="_blank" rel="noopener noreferrer">{partnerAccountName}</a> : 'Direct Learner'}
    </>
  );
};

export default Partner;
