export const GET_LEARNER_PROFILE_PENDING = 'learnerProfile/GET_LEARNER_PROFILE_PENDING';
export const GET_LEARNER_PROFILE_FULFILLED = 'learnerProfile/GET_LEARNER_PROFILE_FULFILLED';
export const GET_LEARNER_PROFILE_REJECTED = 'learnerProfile/GET_LEARNER_PROFILE_REJECTED';

export const GET_LEARNER_FLAGS_PENDING = 'learnerProfile/GET_LEARNER_FLAGS_PENDING';
export const GET_LEARNER_FLAGS_FULFILLED = 'learnerProfile/GET_LEARNER_FLAGS_FULFILLED';
export const GET_LEARNER_FLAGS_REJECTED = 'learnerProfile/GET_LEARNER_FLAGS_REJECTED';
export const UPDATE_LEARNER_READMISSION_FLAG_VALUE = 'learnerProfile/UPDATE_LEARNER_READMISSION_FLAG_VALUE';

export const GET_LEARNER_PAYMENT_STATUS_PENDING = 'learnerProfile/GET_LEARNER_PAYMENT_STATUS_PENDING';
export const GET_LEARNER_PAYMENT_STATUS_FULFILLED = 'learnerProfile/GET_LEARNER_PAYMENT_STATUS_FULFILLED';
export const GET_LEARNER_PAYMENT_STATUS_REJECTED = 'learnerProfile/GET_LEARNER_PAYMENT_STATUS_REJECTED';

export const GET_LEARNER_INFO_PERMISSIONS_PENDING = 'learnerProfile/GET_LEARNER_INFO_PERMISSIONS_PENDING';
export const GET_LEARNER_INFO_PERMISSIONS_SUCCESS = 'learnerProfile/GET_LEARNER_INFO_PERMISSIONS_SUCCESS';
export const GET_LEARNER_INFO_PERMISSIONS_ERROR = 'learnerProfile/GET_LEARNER_INFO_PERMISSIONS_ERROR';

export const GET_LEARNER_PROFILE_VIEW_PERMISSION_PENDING = 'learnerProfile/GET_LEARNER_PROFILE_VIEW_PERMISSION_PENDING';
export const GET_LEARNER_PROFILE_VIEW_PERMISSION_SUCCESS = 'learnerProfile/GET_LEARNER_PROFILE_VIEW_PERMISSION_SUCCESS';
export const GET_LEARNER_PROFILE_VIEW_PERMISSION_ERROR = 'learnerProfile/GET_LEARNER_PROFILE_VIEW_PERMISSION_ERROR';

export const UPDATE_FIELD_VALUE = 'learnerProfile/UPDATE_FIELD_VALUE';

export const GET_LEARNER_APPLICANT_INFO_PENDING = 'learnerProfile/GET_LEARNER_APPLICANT_INFO_PENDING';
export const GET_LEARNER_APPLICANT_INFO_SUCCESS = 'learnerProfile/GET_LEARNER_APPLICANT_INFO_SUCCESS';
export const GET_LEARNER_APPLICANT_INFO_REJECTED = 'learnerProfile/GET_LEARNER_APPLICANT_INFO_REJECTED';
export const GET_LEARNER_ACHIEVEMENTS_PENDING = 'learnerProfile/GET_LEARNER_ACHIEVEMENTS_PENDING';
export const GET_LEARNER_ACHIEVEMENTS_SUCCESS = 'learnerProfile/GET_LEARNER_ACHIEVEMENTS_SUCCESS';
export const GET_LEARNER_ACHIEVEMENTS_REJECTED = 'learnerProfile/GET_LEARNER_ACHIEVEMENTS_REJECTED';

export const GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_PENDING = 'learnerProfile/GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_PENDING';
export const GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_SUCCESS = 'learnerProfile/GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_SUCCESS';
export const GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_ERROR = 'learnerProfile/GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_ERROR';

export const GET_LPP_PERMISSIONS_PENDING = 'learnerProfile/GET_LPP_PERMISSIONS_PENDING';
export const GET_LPP_PERMISSIONS_SUCCESS = 'learnerProfile/GET_LPP_PERMISSIONS_SUCCESS';
export const GET_LPP_PERMISSIONS_ERROR = 'learnerProfile/GET_LPP_PERMISSIONS_ERROR';

export const UPDATE_LEARNER_ACHIEVEMENTS_STATUS = 'learnerProfile/UPDATE_LEARNER_ACHIEVEMENTS_STATUS';
export const UPDATE_LEARNER_ACHIEVEMENTS_PENDING = 'learnerProfile/UPDATE_LEARNER_ACHIEVEMENTS_PENDING';
export const UPDATE_LEARNER_ACHIEVEMENTS_REJECTED = 'learnerProfile/ UPDATE_LEARNER_ACHIEVEMENTS_REJECTED';

export const GET_LEARNER_PROGRAM_ENROLLMENTS_PENDING = 'learnerProfile/GET_LEARNER_PROGRAM_ENROLLMENTS_PENDING';
export const GET_LEARNER_PROGRAM_ENROLLMENTS_FULFILLED = 'learnerProfile/GET_LEARNER_PROGRAM_ENROLLMENTS_FULFILLED';
export const GET_LEARNER_PROGRAM_ENROLLMENTS_ERROR = 'learnerProfile/GET_LEARNER_PROGRAM_ENROLLMENTS_ERROR';

export const UPDATE_ACTIVE_PROGRAM_ENROLLMENT = 'learnerProfile/UPDATE_ACTIVE_PROGRAM_ENROLLMENT';

export const SET_LEARNER_PROGRAM_ENROLLMENT_START_DATE = 'learnerProfile/SET_LEARNER_PROGRAM_ENROLLMENT_START_DATE';
export const SET_LEARNER_PROGRAM_ENROLLMENT_PROGRAM_STATUS = 'learnerProfile/SET_LEARNER_PROGRAM_ENROLLMENT_PROGRAM_STATUS';
