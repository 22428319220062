export const GET_LOA_SCHEDULER_DATA_PENDING = 'LOA_SHEDULER/GET_LOA_SCHEDULER_DATA_PENDING';
export const GET_LOA_SCHEDULER_DATA_SUCCESS = 'LOA_SHEDULER/GET_LOA_SCHEDULER_DATA_SUCCESS';
export const GET_LOA_SCHEDULER_DATA_ERROR = 'LOA_SHEDULER/GET_LOA_SCHEDULER_DATA_ERROR';

export const SET_LOA_SCHEDULER_DATA_PENDING = 'LOA_SHEDULER/SET_LOA_SCHEDULER_DATA_PENDING';
export const SET_LOA_SCHEDULER_DATA_SUCCESS = 'LOA_SHEDULER/SET_LOA_SCHEDULER_DATA_SUCCESS';
export const SET_LOA_SCHEDULER_DATA_ERROR = 'LOA_SHEDULER/SET_LOA_SCHEDULER_DATA_ERROR';

export const GET_SELECT_OPTIONS_PENDING = 'LOA_SHEDULER/GET_SELECT_OPTIONS_PENDING';
export const GET_SELECT_OPTIONS_SUCCESS = 'LOA_SHEDULER/GET_SELECT_OPTIONS_SUCCESS';
export const GET_SELECT_OPTIONS_ERROR = 'LOA_SHEDULER/GET_SELECT_OPTIONS_ERROR';
