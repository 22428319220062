const ALLOWED_FILE_TYPES = ['jpg', 'png', 'jpeg', 'pdf', 'docx'];
const FILE_TOO_LARGE_ERROR = 'File size is larger than allowed size. The maximum file size is 10MB.';
const WRONG_FILE_FORMAT_ERROR = `You are uploading the wrong file type. The allowed file types are ${ALLOWED_FILE_TYPES.join(', ')}`;
const NO_FILE_ERROR = 'Please, select file for upload';


export const isFileSizeAllowed = (
  fileSize: number,
): boolean => fileSize === undefined || fileSize < 10485760;

export const isFileTypeAllowed = (fileExtension: string): boolean => {
  if (ALLOWED_FILE_TYPES.find((x) => fileExtension === x)) {
    return true;
  }
  return false;
};

const getFileValidationErrors = (file: File | null): string[] => {
  const errors: string[] = [];
  if (!file) {
    errors.push(NO_FILE_ERROR);
    return errors;
  }
  if (!isFileSizeAllowed(file.size)) {
    errors.push(FILE_TOO_LARGE_ERROR);
  }
  const fileExtension = file.name.substr(file.name.lastIndexOf('.') + 1);
  if (!isFileTypeAllowed(fileExtension)) {
    errors.push(WRONG_FILE_FORMAT_ERROR);
  }
  return errors;
};

export default getFileValidationErrors;
