import React from 'react';
import {
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const PrerequisitesTooltip: React.FunctionComponent<{
  prerequisites: string[];
}> = (props: { prerequisites: string[] }) => {
  const { prerequisites } = props;
  return (
    <OverlayTrigger
      overlay={(
        <Tooltip id="tooltip-disabled">
          <span>
            {`Prerequisites: ${prerequisites.join(', ')}`}
          </span>
        </Tooltip>
      )}
    >
      <span className="d-inline-block info-icon">
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    </OverlayTrigger>
  );
};

export default PrerequisitesTooltip;
