import { AnyAction, Dispatch } from 'redux';
import { action } from 'typesafe-actions';
import api from '../api/adminUI.api';
import IError from '../../models/Error';
import errorHandling from '../../components/helper-components/alert-component.component';
import {
  GET_APPLICANT_DATA_PENDING,
  GET_APPLICANT_DATA_SUCCESS,
  GET_APPLICANT_DATA_ERROR,
  UPDATE_APPLICANT_FIELD_VALUE,
} from '../reducers/applicant-editable-info/applicant-editable-info.constants';

import { IApplicantEditableData } from '../reducers/applicant-editable-info/applicant-editable-info.model';

// eslint-disable-next-line max-len
export const getApplicantEditableInfoObjectPending = (): AnyAction => action(GET_APPLICANT_DATA_PENDING);
export const getApplicantEditableInfoObjectSuccess = (
  data: IApplicantEditableData,
): AnyAction => action(GET_APPLICANT_DATA_SUCCESS, data);
export const getApplicantEditableInfoObjectFailure = (
  error: IError,
): AnyAction => action(GET_APPLICANT_DATA_ERROR, error);

export const getApplicantEditableInfo = (
  getBearerToken: () => Promise<string>,
  applicantId: string,
) => async (dispatch: Dispatch) => {
  dispatch(getApplicantEditableInfoObjectPending());
  try {
    const response = await api.admissions.getPersonalInfoEditableApplicant(
      applicantId, getBearerToken,
    );
    if (!response.ok) throw await response;
    const data = (await response.json()) as IApplicantEditableData;
    dispatch(getApplicantEditableInfoObjectSuccess(data));
  } catch (error) {
    dispatch(getApplicantEditableInfoObjectFailure(error));
    errorHandling({ error });
  }
};

export interface INewFieldValue {
  [name: string]: string | null;
}

export const updateApplicantProfileFieldValue = (
  applicantId: string,
  getBearerToken: () => Promise<string>,
  newFieldValue: INewFieldValue,
  changedFieldUrl: string,
) => async (dispatch: Dispatch) => {
  try {
    const url = `/api/admission/applicant/${applicantId}/${changedFieldUrl}`;
    const token = await getBearerToken();

    const response = await fetch(url, {
      method: 'PUT',
      headers: [['Authorization', `Bearer ${token}`], ['content-type', 'application/json']],
      body: JSON.stringify(newFieldValue),
    });
    if (!response.ok) {
      // throw error;
    }
    dispatch({ type: UPDATE_APPLICANT_FIELD_VALUE, payload: newFieldValue });
  } catch (error) {
    errorHandling({ error });
  }
};
