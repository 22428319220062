import { useQuery } from '@tanstack/react-query';
import api from '../../../shared/api/adminUI.api';
import { useAuthContext } from '../../auth/auth-context';

export const PARTNERS_QUERY_KEY = ['partners'];
export const usePartners = () => {
  const { getBearerToken } = useAuthContext();

  return useQuery({
    queryKey: PARTNERS_QUERY_KEY,
    queryFn: async () => api.partners.getPartners(getBearerToken),
  });
};
